
import { Component, Vue } from 'vue-property-decorator'
import API from '@/api'
import { getDate, getUSDate } from '@/utils/date'

@Component
export default class Credit extends Vue {
credits: Object | null = null

async getCredits() {
    const response = await API.GetOrganizationCredits(this.$store.getters.organizationId)
    const credits = response.data.credits
    const creditsStatus = credits && credits.endDate && credits.startDate
        && getDate(credits.endDate) >= getDate(null)
        && getDate(credits.startDate) <= getDate(null) ? 'ACTIVE' : 'INACTIVE'
    this.credits = {
        status: creditsStatus,
        total: creditsStatus === 'ACTIVE' && credits ? credits.activeCredits : '-',
        creditsPerInterval: creditsStatus === 'ACTIVE' && credits ? credits.creditsPerInterval : '-',
        interval: creditsStatus === 'ACTIVE' && credits && credits.interval ? credits.interval : '-',
        renewDate: creditsStatus === 'ACTIVE' && credits && credits.renewable
            ? getUSDate(getDate(credits.renewDate).split('T')[0]) : '-',
    }
}

mounted() {
    this.getCredits()
}
}
