
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import { TranslateResult } from 'vue-i18n'
import User from './User.vue'
import rules from '@/utils/rules'
import API from '@/api/index'
import ConfirmActionModal from '@/components/Organization/Modals/ConfirmActionModal.vue'

interface UserInfo {
    id: number,
    name: string,
    email: string,
    phone: string,
    isEnabled: number,
}

interface SavedUser {
    id: number,
    name: string,
}

@Component({
    components: {
        User,
        ConfirmActionModal,
    },
})
export default class Users extends Vue {
    start: number = 0
    limit: number = 100
    isActionModalShow: boolean = false
    savedAction: string = ''
    selectedEditorIndex: number | null = null
    oldSelectedEditorIndex: number | null = null
    savedUser: SavedUser = {
        id: 0,
        name: '',
    }

    @Prop() readonly usersInfo!: UserInfo
    @Prop() readonly isHidingOptions?: boolean

    async getUsers() {
        this.$emit('getUsers')
    }

    toggleSecondMenu(index) {
        this.selectedEditorIndex = this.oldSelectedEditorIndex === index ? null : index
    }

    @Watch('selectedEditorIndex')
    onPropertyChanged(value: number | null, oldValue: number | null) {
        this.oldSelectedEditorIndex = value
    }

    @Watch('isHidingOptions')
    onParentClick(value) {
        if (value === true) {
            this.hideSecondMenu()
        }
        this.$emit('toggleHidingOptions')
    }

    hideSecondMenu() {
        if (this.selectedEditorIndex !== null) {
            this.toggleSecondMenu(this.selectedEditorIndex)
        }
    }

    openActionModal(savedUser?, action?) {
        this.isActionModalShow = true
        this.savedUser = savedUser
        this.savedAction = action
    }

    closeActionModal() {
        this.isActionModalShow = false
    }

    openModalUser(info) {
        this.$emit('openModalUser', info)
    }

    mounted() {
        this.getUsers()
    }
}
