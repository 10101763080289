
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class UploadLoading extends Vue {
    @Prop() readonly showLoading?: boolean
    @Prop() readonly totalParts?: number
    @Prop() readonly actualPart?: number
    @Prop() readonly fileName?: string
}
