
import { Component, Vue } from 'vue-property-decorator'
import TopBar from '@/components/AdminPortal/TopBar.vue'
import TopMenu from '@/components/Organization/TopMenu.vue'
import LeftMenu from '@/components/AdminPortal/LeftMenu.vue'
import InfoUserModal from '@/components/Organization/Modals/InfoUserModal.vue'
import FileUpload from '@/components/FileUpload.vue'

@Component({
    components: {
        TopBar,
        LeftMenu,
        TopMenu,
        InfoUserModal,
        FileUpload,
    },
})
export default class App extends Vue {
    isLoggedIn: string = ''
    userRole: string = ''
    hasAccessToAdminPortal: boolean = false
    hasAccessToOrganizationPortal: boolean = false

    updated() {
        this.isLoggedIn = this.$store.getters.isLoggedIn
        this.userRole = this.$store.getters.userRole
        this.hasAccessToAdminPortal = this.$store.getters.hasAccessToAdminPortal
        this.hasAccessToOrganizationPortal = this.$store.getters.hasAccessToOrganizationPortal
    }

    uploadDummyFile() {
        const uploader = new FileUpload()
        uploader.dummyUpload()
    }

    mounted() {
        this.uploadDummyFile()
        this.isLoggedIn = this.$store.getters.isLoggedIn
        this.userRole = this.$store.getters.userRole
        this.hasAccessToAdminPortal = this.$store.getters.hasAccessToAdminPortal
        this.hasAccessToOrganizationPortal = this.$store.getters.hasAccessToOrganizationPortal
    }
}
