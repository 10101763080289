async function getMeta(url: string): Promise<HTMLImageElement> {
    const img = new Image()
    img.src = url
    await img.decode()
    return img
}


async function imgDimensions(url: string): Promise<{
    width: number;
    height: number;
}> {
    const img = await getMeta(url)
    return { width: img.naturalWidth, height: img.naturalHeight }
}

// Calculate the dimensions of preview videos, given the dimensions of the
// thumbnail and that the height is 720.
async function calculatePreviewVideoDimensions(thumbnailPath: string) {
    let aspectRatio = 16 / 9
    try {
        const thumbnail = await imgDimensions(thumbnailPath)
        aspectRatio = thumbnail.width / thumbnail.height
    } catch (err) {
        console.error(err)
    }
    const height = 720
    const width = height * aspectRatio
    return { width, height }
}

export default {
    imgDimensions,
    calculatePreviewVideoDimensions,
}
