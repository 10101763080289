import i18n from '../language/en'

const dashboard = require('@/assets/Dashboard/dashboard.svg') as string
const dashboardActive = require('@/assets/Dashboard/dashboard-active.svg') as string
const customers = require('@/assets/Dashboard/customers.svg') as string
const customersActive = require('@/assets/Dashboard/customers-active.svg') as string
const editors = require('@/assets/Dashboard/editors.svg') as string
const editorsActive = require('@/assets/Dashboard/editors-active.svg') as string
const onboarding = require('@/assets/Dashboard/onboarding.svg') as string
const onboardingActive = require('@/assets/Dashboard/onboarding-active.svg') as string

interface MenuOption {
    title: string;
    icon: string;
    activeIcon: string;
    path: string;
}

const first: MenuOption = {
    title: i18n.dashboard.dashboard.title,
    icon: dashboard,
    activeIcon: dashboardActive,
    path: '/dashboard',
}

const second: MenuOption = {
    title: i18n.dashboard.customers.title,
    icon: customers,
    activeIcon: customersActive,
    path: '/customers',
}

const third: MenuOption = {
    title: i18n.dashboard.editors.title,
    icon: editors,
    activeIcon: editorsActive,
    path: '/editors',
}

const fourth: MenuOption = {
    title: i18n.dashboard.onboarding.title,
    icon: onboarding,
    activeIcon: onboardingActive,
    path: '/onboarding',
}

const MenuOptions = [
    first,
    second,
    third,
    fourth,
]

export default MenuOptions
