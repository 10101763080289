
import {
    Component, Vue, Watch, Prop,
} from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'

interface UserInfo {
    id: number,
    email: string,
    phone: string,
    isEnabled: number,
    firstName: string,
    lastName: string,
}

@Component
export default class AddUserModal extends Vue {
    email: string = ''
    passwordError: TranslateResult | string | boolean = ''
    emailError: TranslateResult | string | boolean = ''
    emailRules = rules.isEmail

    firstName: string = ''
    lastName: string = ''
    confirmedEmail: string = ''
    phone: string = ''
    firstNameError: TranslateResult | string | boolean = ''
    lastNameError: TranslateResult | string | boolean = ''
    phoneError: TranslateResult | string | boolean = ''
    confirmedEmailError: TranslateResult | string | boolean = ''
    isEmailAlreadyUsed: boolean = false

    @Prop() readonly isModalUserOpen!: boolean
    @Prop() readonly infoEditUser!: UserInfo
    @Prop() readonly toggleEditModalType!: boolean

    checkEmailInput() {
        this.hideEmailAlreadyUseError()
        if (this.email.length < 1) {
            this.emailError = this.$t('errors.rules.required.email')
        } else if (this.email.length > 99) {
            this.emailError = this.$t('errors.rules.validated.emailMax')
        } else if (this.emailRules(this.email) !== true) {
            this.emailError = this.emailRules(this.email)
        } else {
            this.emailError = ''
        }
    }

    checkFirstNameInput() {
        this.hideEmailAlreadyUseError()
        if (this.firstName.length < 1) {
            this.firstNameError = this.$t('errors.rules.required.firstName')
        } else if (this.firstName.length > 99) {
            this.firstNameError = this.$t('errors.rules.validated.firstNameMax')
        } else {
            this.firstNameError = ''
        }
    }

    checkLastNameInput() {
        this.hideEmailAlreadyUseError()
        if (this.lastName.length < 1) {
            this.lastNameError = this.$t('errors.rules.required.lastName')
        } else if (this.lastName.length > 99) {
            this.lastNameError = this.$t('errors.rules.validated.lastNameMax')
        } else {
            this.lastNameError = ''
        }
    }

    checkConfirmedEmailInput() {
        this.hideEmailAlreadyUseError()
        if (this.confirmedEmail.length < 1) {
            this.confirmedEmailError = this.$t('errors.rules.required.email')
        } else if (this.emailRules(this.email) !== true) {
            this.confirmedEmailError = this.emailRules(this.email)
        } else if (this.email !== this.confirmedEmail) {
            this.confirmedEmailError = this.$t('errors.rules.validated.confirmedEmailAndEmail')
        } else {
            this.confirmedEmailError = ''
        }
    }

    checkPhoneInput() {
        this.hideEmailAlreadyUseError()
        if (this.phone.length < 1) {
            this.phoneError = this.$t('errors.rules.required.phoneNumber')
        } else if (this.phone.length > 14) {
            this.phoneError = this.$t('errors.rules.validated.phoneNumberMax')
        } else {
            this.phoneError = ''
        }
    }

    hideEmailAlreadyUseError() {
        if (this.isEmailAlreadyUsed) {
            this.isEmailAlreadyUsed = false
        }
    }

    async createUser() {
        this.checkEmailInput()
        this.checkFirstNameInput()
        this.checkLastNameInput()
        this.checkPhoneInput()
        this.checkConfirmedEmailInput()

        if (
            this.phoneError === ''
            && this.emailError === ''
            && this.confirmedEmailError === ''
            && this.firstNameError === ''
            && this.lastNameError === ''
        ) {
            if (!this.toggleEditModalType) {
                try {
                    const response = await API.AddUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        phone: this.phone,
                        role: 'User',
                    })
                    if (response) {
                        this.closeModalUser()
                        this.$emit('getUsers')
                    } else {
                        Sentry.captureException(response)
                    }
                } catch (error) {
                    Sentry.captureException(error)
                    this.isEmailAlreadyUsed = true
                }
            } else {
                const info = {
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    organizationId: this.$store.getters.organizationId,
                }
                try {
                    const response = await API.EditUserInfo(this.infoEditUser.id, info)
                    this.closeModalUser()
                    this.$emit('getUsers')
                } catch (error) {
                    Sentry.captureException(error)
                }
            }
        }
    }

    openModalUser() {
        this.$emit('openModalUser')
    }

    resetFields() {
        this.email = ''
        this.phone = ''
        this.lastName = ''
        this.firstName = ''
        this.confirmedEmail = ''
    }

    closeModalUser() {
        this.$emit('closeModalUser', this.toggleEditModalType)
        if (this.toggleEditModalType) {
            this.resetFields()
        }
    }

    @Watch('toggleEditModalType')
    onPropertyChanged(value: boolean) {
        if (value === true) {
            this.email = this.infoEditUser.email
            this.lastName = this.infoEditUser.lastName
            this.firstName = this.infoEditUser.firstName
            this.phone = this.infoEditUser.phone
            this.confirmedEmail = this.infoEditUser.email
        }
    }
}
