
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import EditorDropdown from '@/components/AdminPortal/EditorDropdown.vue'

interface ProjectInfo {
    projectId: number,
    timer: string,
    projectTitle: string,
    organization: string,
    status: string,
    creationDate: string,
    feedbackLink: string,
    randomId: number,
}

@Component({
    components: {
        EditorDropdown,
    },
})
export default class Project extends Vue {
    @Prop() readonly info?: ProjectInfo
    @Prop() readonly isSecondMenuOpen?: boolean
    @Prop() readonly index?: number

    toggleSecondMenu() {
        this.$emit('toggleSecondMenu', this.index)
    }

    async setEditor(info) {
        try {
            const response = await API.SetEditor(info.projectId, info.editorInfo.id)
            this.$emit('getOrganizationInfo')
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    saveInfoTopBar(title) {
        this.$store.commit('saveInfoTopBar', title)
    }
}

