
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'
import PasswordInput from '@/components/Access/Password.vue'

@Component({
    components: {
        PasswordInput,
    },
})
export default class UserLogin extends Vue {
    email: string = ''
    password: string = ''
    authorName: string = ''
    isLoginError: boolean = false
    passwordError: TranslateResult | string | boolean = ''
    emailError: TranslateResult | string | boolean = ''
    emailRules = rules.isEmail
    passwordRules = rules.isPassword
    isFormGrown: boolean = false
    isFormShown: boolean = false
    isLoggingIn: boolean = false

    checkEmailInput() {
        if (this.email.length < 1) {
            this.emailError = this.$t('errors.rules.required.email')
        } else if (this.email.length > 99) {
            this.emailError = this.$t('errors.rules.validated.emailMax')
        } else if (this.emailRules(this.email) !== true) {
            this.emailError = this.emailRules(this.email)
        } else {
            this.emailError = ''
        }
    }

    checkPasswordInput() {
        if (this.password.length < 1) {
            this.passwordError = this.$t('errors.rules.required.password')
        } else if (this.passwordRules(this.password) !== true) {
            this.passwordError = this.passwordRules(this.password)
        } else {
            this.passwordError = ''
        }
    }

    async login() {
        this.checkEmailInput()
        this.checkPasswordInput()
        if (this.emailError || this.passwordError) {
            return
        }

        try {
            this.isLoggingIn = true
            const response = await API.Login(this.email.trim(), this.password)
            if (response.status === 200) {
                this.$store.commit('accessToken', response.data.token)
                this.$store.commit('organizationId', response.data.organizationId)
                try {
                    const responseMe = await API.PersonalInfo()
                    const info = {
                        role: responseMe.data.role.name,
                        name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                        orgName: responseMe.data.organizationName,
                        id: responseMe.data.id,
                    }
                    this.$store.commit('setUserInfo', info)
                    if (this.$store.getters.hasAccessToOrganizationPortal) {
                        this.$router.push('/user/projects')
                    } else {
                        this.$router.push('/dashboard')
                    }
                } catch (error) {
                    this.isLoggingIn = false
                    Sentry.captureException(error)
                }
            }
        } catch (error) {
            this.isLoggingIn = false
            Sentry.captureException(error)
            this.isLoginError = true
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        setTimeout(() => {
            this.isFormGrown = true
        }, 500)
        setTimeout(() => {
            this.isFormShown = true
        }, 1000)
    }
}
