
import { Component, Vue } from 'vue-property-decorator'
import Register from './Register.vue'
import Register2 from './Register2.vue'

@Component({
    components: {
        Register,
        Register2,
    },
})
export default class RegisterBox extends Vue {
    registerStep: number = 0

    changeRegisterStep() {
        this.registerStep = 1
    }
}
