import i18n from '../language/en'

interface MenuOption {
    title: string;
    path: string;
}

const first: MenuOption = {
    title: i18n.owner.project.title,
    path: '/user/projects',
}

const second: MenuOption = {
    title: i18n.owner.contentLibrary.title,
    path: '/user/content-library',
}

const third: MenuOption = {
    title: i18n.owner.help.title,
    path: '/help',
}

const fourth: MenuOption = {
    title: i18n.owner.adminSettings.title,
    path: '/user/admin-settings',
}

const UserMenuOptions = [
    first,
    second,
    third,
    fourth,
]

export default UserMenuOptions
