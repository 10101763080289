export default {
    title: 'VidLoft',
    dashboard: {
        dashboard: {
            title: 'Dashboard',
        },
        customers: {
            title: 'Customers',
        },
        editors: {
            title: 'Editors',
        },
        onboarding: {
            title: 'Onboarding',
        },
    },
    owner: {
        project: {
            title: 'Project',
        },
        contentLibrary: {
            title: 'Content Library',
        },
        help: {
            title: 'Help',
        },
        adminSettings: {
            title: 'Account Details',
        },
    },
    errors: {
        rules: {
            required: {
                firstName: 'First Name is required',
                lastName: 'Last Name is required',
                email: 'E-mail is required',
                password: 'Password is required',
                link: 'Link is required',
                phoneNumber: 'Phone number is required',
                passwordAgain: 'Re-Enter Password is required',
                firstNameLastName: 'First / Last name are required',
                company: 'Company name is required',
                projectName: 'Project Name is required',
                audience: 'Audience is required',
                goal: 'Goal is required',
                videoLength: 'Video length is required',
                projectBrief: 'Project brief is required',
            },
            validated: {
                organizationNameChars: 'Allowed characters are A-Z, a-z, 0-9, spaces, and the symbols <code>.\'-_*!</code>',
                organizationNameFirstChar: 'The first character cannot be a symbol.',
                organizationNameConsecutiveSpaces: 'The name cannot contain consecutive spaces.',
                email: 'This email address seems incorrect, please provide a valid email address.',
                password: 'Please make sure your password is at least 6 characters long.',
                link: 'Not a valid URL',
                phoneNumber: 'The phone number must be valid',
                passwordMatch: 'Password and Re-Entered password doesn\'t match',
                confirmedEmail: 'Confirmed Email is required',
                confirmedEmailAndEmail: 'Confirmed Email and Email are not the same',
                confirmPassword: 'Confirm password does not match password',
                audienceMax: 'Audience must have maximum of 50 characters',
                projectNameMax: 'Project Name must have maximum of 43 characters',
                emailMax: 'E-mail must have maximum 100 characters',
                firstNameMax: 'First Name must have maximum of 100 characters',
                lastNameMax: 'Last Name must have maximum of 100 characters',
                phoneNumberMax: 'Phone number must have maximum of 15 characters',
                organizationAssetMax: 'Organization Asset Link must have maximum of 255 characters',
                editorAssetMax: 'Editor Assets Link must have maximum of 255 characters',
                feedbackLinkMax: 'Feedback Link must have maximum of 200 characters',
                trackingIdMax: 'Tracking Id must have maximum of 100 characters',
                firstNameLastNameMax: 'First Name / Last Name must have maximum of 100 characters',
                trackingId: 'Maximum 100 characters',
                companyNameMax: 'Company Name must have maximum of 100 characters.',
                invalidPasswordRecoveryCode: 'The recovery code is not valid or has expired.',
            },
        },
    },
    general: {
        inputs: {
            email: 'Email',
            password: 'Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            firstName: 'First Name',
            lastName: 'Last Name',
            whatsapp: 'WhatsApp Number',
            organizationAssets: 'Organization Asset Link',
            passwordAgain: 'Re-Enter Password',
            companyName: 'Company or Organization Name',
            phoneNo: 'Phone No.',
            required: 'Required field',
            confirmedEmail: 'Confirm Email',
            projectAudience: 'Who is your primary audience?',
            projectGoal: 'What is your project\'s goal?',
            projectLength: 'How long should your video be?',
            projectDescription: 'Describe your project and what you\'re looking for.',
            projectName: 'Project Name*',
            audience: 'Audience*',
            goal: 'Goal*',
            length: 'Length*',
            brief: 'Brief*',
            credits: 'Credits',
            noCredits: 'Subscription inactive',
            requestLink: 'Insert URL with request changes',
        },
        buttons: {
            next: 'Next',
            logout: 'Logout',
            adminLogout: 'Go Back',
            complete: 'Complete',
            hide: 'Delete',
            restore: 'Restore',
            archive: 'Archive',
            yes: 'Yes',
            no: 'No',
            addEditor: 'Add Editor',
            disable: 'Disable',
            delete: 'Delete',
            editInfo: 'Edit Info',
            createEditorProfile: 'Create Editor Profile',
            uploadProfilePicture: 'Upload Profile Picture',
            sendToReview: 'Send to Review',
            restoreToReview: 'Send back to Review',
            upload: 'Upload',
            downloadBrief: 'Download Brief',
            downloadProxy: 'Download Proxy',
            downloadRow: 'Download Row',
            viewFeedback: 'View Feedback',
            viewDrive: 'View Drive',
            finishEditorProfile: 'Finish Edit Profile',
            login: 'Log In',
            clickHere: 'Click here',
            registerHere: 'Register here',
            alreadyAccount: 'Already have an account?',
            loginHere: 'Login here',
            addUser: 'Add User',
            newProject: 'New Project',
            cancel: 'Cancel',
            confirm: 'Confirm',
            downloadFull: 'Download Full Size',
            downloadReduced: 'Download Reduced Size',
            saveChanges: 'Save Changes',
            deleteAllInterview: 'Delete All Interview Clips',
            deleteAllClips: 'Delete All B-Roll Clips',
            deleteAllAssets: 'Delete All Assets',
            sendToEditing: 'Send To Editing',
            sendToProduction: 'Send to Production',
            looksGreat: 'LOOK\'S GREAT, LET\'S GO',
            requestChanges: 'Request Changes',
            submitRevision: 'Submit revision',
            copyToClipboard: 'Copy link',
            copiedToClipboard: 'Link copied to clipboard',
            downloadFinalVideo: 'Download Final Video',
            saveRawFiles: 'Save Raw Files',
            deleteRawFiles: 'Delete Raw Files',
            getMoreProjects: 'Get More Projects',
            getMoreCustomers: 'Get More Customers',
            getMoreEditors: 'Get More Editors',
            getMoreOrganizations: 'Get More Organizations',
            getMoreTrialUsers: 'Get More Trial Users',
        },
        warnings: {
            sure: 'Are you sure you want to {action}?',
            duplicate: 'Do you want to {action} this project?',
            noAssetsWarning: 'You are submitting a project without any assets.',
        },
        errors: {
            login: 'Email or password are incorrect. If you encounter technical issues, contact us  <a href="https://vidloft.com/contact/" target="_blank">here</a>',
            server: 'An error occurred, please try again. If the error still occurs, please contact support.',
            deleteEditorWithProject: 'You can not delete an editor who has assigned projects.',
            loginServerError: 'Please make sure the information provided is correct. Make sure you reach us if you encounter technical issues. Contact us here.',
            incompleteClips: 'Please wait while the assets finish uploading.',
            organizationAlreadyExists: 'An organization with this name already exists.',
            alreadyUsedEmail: 'There is already an account using this email',
            unapprovedProject: 'Project is missing final video or it is not approved by client.',
            noMoreMemory: 'There is no more memory.',
        },
        card: {
            success: 'CONGRATULATIONS!',
            successInfo: 'You are ready to create wonderful stories.',
            successButton: 'GO TO PROJECTS',
            period: 'Per month',
            title: 'You have no active subscriptions. Select a plan from below.',
            other: 'Other Plans',
            reduction: 'Discount available until:',
            existingTitle: 'Your Subscription',
            existingCredits: 'Credits left this month',
            existingBillingNext: 'Next billing on',
            existingBillingEnd: 'Subscription renews on',
            existingBillingCanceledEnd: 'Subscription expires on',
            existingStatus: 'Subscription status',
            existingCancel: 'Cancel Plan',
        },
    },
    pages: {
        login: {
            firstTitle: 'Sign In',
            secondTitle: 'To continue, first verify it\'s you',
            forgotPassword: 'Forgot password?',
            welcomeBack: 'Welcome Back!',
            forgotYourPassword: 'Forgot your password?',
            dontHaveAccount: 'Don\'t have an account?',
            recoverPassword: 'Let\'s Recover Your Password',
            changePassword: 'Type in your new Password',
            checkEmail: 'Check Your Email!',
        },
        register: {
            title1: 'Let\'s create your team\'s account',
            title2: 'Just a little more info',
            title0: 'Hi There!',
        },
        onboarding: {
            tabHead: [
                'Customers Onboarding', 'Completed Customers Onboarding', 'Trial Users',
            ],
            tableHead: [
                'Organization', 'Global Organization Assets', 'Subscription', 'VidLoft Kit', 'Kit Type', 'Order Date', 'Tracking ID',
            ],
            trialUsersTable: [
                'Organization', 'Email', 'Name', 'Expiration', 'Registration URL',
            ],
            confirmation: 'Are you sure about completing this Customer Onboarding?',
        },
        editors: {
            title: 'Editors',
            tableHead: [
                'Name', 'Editor Drive', 'Email', 'Created', 'Whatsapp Number', 'Change',
            ],
        },
        users: {
            title: 'Users',
            tableHead: [
                'Name', 'Email', 'Phone', 'Status', 'Action',
            ],
            addUser: 'Add User',
        },
        billing: {
            title: 'Purchase Subscription',
            billingInfo: 'Billing Info',
            shipInfo: 'Ship To',
            cardInfo: 'Credit Card Info',
            form: {
                billingName: 'FULL NAME',
                shipName: 'NAME/DEPT',
                billingCompany: 'COMPANY NAME',
                billingPhone: 'PHONE',
                billingPhone2: 'PHONE 2',
                billingEmail: 'EMAIL',
                billingAddress: 'ADDRESS',
                billingCity: 'CITY',
                billingCode: 'ZIP CODE',
                billingCountry: 'COUNTRY',
                cardNumber: 'CARD NUMBER',
                cardName: 'CARDHOLDER NAME',
                cardDate: 'EXPIRY DATE',
                cardCvc: 'CVV',
                coupon: 'DISCOUNT CODE',
                purchase: 'PURCHASE',
            },
            errors: {
                billingName: 'Please enter a full name',
                billingCompany: 'Please enter company name',
                billingPhone: 'Please enter phone',
                billingEmail: 'Please enter email',
                billingAddress: 'Please provide a billing address',
                billingCity: 'Please provide a billing city',
                billingCode: 'Please provide a zip code',
                billingCountry: 'Please select a country',
                shipName: 'Please enter a shipping name',
                shipCompany: 'Please enter shipping company name',
                shipPhone: 'Please enter shipping phone details',
                shipAddress: 'Please provide a shipping address',
                shipCity: 'Please provide a shipping city',
                shipCode: 'Please provide a shipping zip code',
                shipCountry: 'Please select a shipping country',
                cardNumber: 'Please enter card number',
                cardName: 'Please provide a card name',
                cardDate: 'Please enter card expiry date',
                cardCvc: 'Please enter card CVV',
                coupon: 'The discount code you provided is not correct or has already been used. Please try again or contact us if the problem persists.',
                card: 'Please provide valid card information',
                default: 'Error while creating subscription. Try again or check if you do not already have a subscription.',
                delete: 'Error while deleting subscription. Please try again!',
            },
            canceled: 'You will be contacted shortly regarding the termination of your subscription.',
            termsAndCond: 'You are subscribing to a 12 months plan. <br> Please read the <a href="http://vidloft.com/privacy" target="_blank">Terms & Conditions</a> before accepting.',
        },
        project: {
            title: 'Project',
            timeLeft: 'Time Left',
            versionNo: 'Version No.:',
            reviewDaysLeft: '1 day left to review | {count} days left to review',
            noReviewDeadline: 'No review deadline',
            incomplete: 'Upload Incomplete <br> Please reupload the file',
            noVideo: 'The file is being processed, it may take a couple of minutes for the preview to be available.',

            projectBrief: {
                title: 'Project Brief',
                createdBy: 'Created By',
                name: 'Project Name',
                audience: 'Audience',
                goal: 'Goal',
                length: 'Length',
                brief: 'Brief',
            },
            links: {
                organizationAsset: 'Global Organization Assets:',
                feedbackLink: 'Feedback Link:',
                collaborationLink: 'Collaboration Link',
                editorFiles: 'Editor Project Files',
            },
            tabs: {
                interviewClips: 'Interview Clips',
                bRollClips: 'B-Roll Clips',
                assets: 'Assets',
            },
            status: {
                opened: 'NOT PUBLISHED',
                waitingForEditor: 'NO EDITOR',
                inEditing: 'IN PROGRESS',
                inReview: 'PENDING REVIEW',
                paused: 'PAUSED',
                deleted: 'DELETED',
                approved: 'FINISHED',
                archived: 'FINISHED',
                hidden: 'DELETED',
            },
            assets: {
                header: {
                    interviewClips: 'Interview Clips',
                    bRollClips: 'B-Roll Clips',
                    assets: 'Assets',
                    downloadProxy: 'Download Proxy',
                    downloadRaw: 'Download Raw',
                },
                downloadLinksNotReady: 'You can\'t download right now. Please wait for a few minutes and check again',
                downloadLinkSomeReady: 'Some archives are ready to download',
                downloadLinkReady: 'You can download now',
            },
        },
        dashboard: {
            tabHead: [
                'Active Projects', 'Completed Projects', 'Deleted Projects',
            ],
            tableHead: [
                'Timer', 'Project Title', 'Organization', 'Status', 'Creation Date', 'Version', 'Feedback Link', 'Project ID', 'Editor',
            ],
            complete: 'Are you sure about completing this project?',
            hide: 'Are you sure about deleting this project?',
            restore: 'Are you sure about restoring this project?',
            select: 'Pick an editor',
        },
        customers: {
            title: 'Customers List',
            tableHead: [
                'Name', 'Organization', 'Credits Expire On', 'Email', 'Creation Date', 'Phone Number', 'Action',
            ],
            subscription: 'Subscription',
            subscriptionType: 'Subscription Type',
            billingInformation: 'Billing Information',
            brandAssets: 'Brand Assets',
            projectAdded: 'Project Added',
            vidloftKit: 'VidLoft Kit',
            expires: 'Subscription expires in less than {days} days',
            credits: 'Credits',
            creditsPerInterval: 'Credits per period',
            creditsEnd: 'Credits end date',
            creditsRenew: 'Credits renew date',
            delete: 'Are you sure about deleting this organization?',
        },
        organization: {
            title: 'Organization',
            organizationAssets: 'Global Organization Assets',
            projects: {
                title: 'Projects Report',
                projectsCompleted: 'Projects Completed',
                projectsProgress: 'Projects in Progress',
                remainingStorage: 'Remaining Storage',
                assets: 'No. of Assets',
                defaultReviewPeriod: 'Project Review Period',
                days: 'Days',
                reviewDays: 'No review deadline | 1 day | {count} days',
            },
            credits: {
                title: 'Subscription Status',
                start: 'Subscription Start',
                end: 'Subscription End',
                creditsPerInterval: 'Credits Per Renew',
                renewable: 'Renewable',
                interval: 'Renews Every',
                total: 'Credits Available',
                storage: 'Storage Available',
                action: 'Update',
                renewDate: 'Next Renew Date',
            },
            billing: {
                title: 'Billing Details',
                billTo: 'Bill To',
                contactName: 'Contact Name',
                companyName: 'Company Name',
                address: 'Address',
                phone: 'Phone',
                phone2: 'Phone 2',
                email: 'Email',
                shipTo: 'Ship To',
                nameDept: 'Name / Dept',
            },
            subscription: {
                title: 'Subscription Status',
                renewalDate: 'Renewal Date',
                usersTitle: 'Users',
                subscriptionStarted: 'Subscription Started',
                projectsCompleted: 'Projects Completed',
                projectsProgress: 'Projects in Progress',
                remainingStorage: 'Remaining Storage',
                assets: 'No. of Assets',
                credits: 'Credits Available',
            },
            tableHead: [
                'Timer', 'Project Title', 'Organization', 'Status', 'Creation Date', 'Feedback Link', 'Editor',
            ],
        },
        adminSettings: {
            modal: {
                title: 'You are adding a new user to your organization',
                editTitle: 'You are now editing a user',
                info: 'The user will receive a login link on the registered email, where he can set a password.',
            },
            stepper: [
                'Credits', 'Users',
            ],
            actions: {
                disable: 'Disable User',
                resendLink: 'Resend Invite Link',
                edit: 'Edit Details',
                delete: 'Delete',
            },
            credits: {
                title: 'Subscription Status',
                total: 'Credits',
                perInterval: 'Credits/Period',
                period: 'Period Length',
                renew: 'Next Renewal',
            },
            userAlready: 'There is already an user with this email. Please try another email or contact support.',
        },
        createProject: {
            title1: 'Give an awesome name to your project',
            title2: 'Tell us more about your project',
            title3: 'We are almost there...',
        },
        contentLibrary: {
            title: 'Your Completed Videos',
            description: 'Click any project to download the video or copy it to a new project.',
            duplicateMessage: 'Duplicated project successfully.',
            buttons: {
                done: 'Done',
                duplicate: 'Duplicate',
                downloadHD: 'Download HD',
                downloadLowRes: 'Download Low Res',
            },
        },
        projects: {
            needsReview: 'Needs Review',
            postProduction: 'Post Production',
            preProduction: 'Pre Production',
            projectsEdited: 'Your project is now being edited, color graded, and audio mastered.',
            projectsAutocomplete: 'Projects will autocomplete when the review window closes.',
            projectsPreProduction: 'Plan out upcoming projects and submit them to our editing team.',
            buttons: {
                done: 'Done',
                duplicate: 'Duplicate',
                downloadHD: 'Download HD',
                downloadLowRes: 'Download Low Res',
                delete: 'Delete',
            },
            create: 'Click here to create a new project',
        },
        projectOrganization: {
            subtitle: {
                timerWillStart: 'Timer will start once your project is submitted for editing',
                waitReview: 'Your project is awaiting to be reviewed',
            },
            projectBrief: 'Project Brief',
            expand: 'Expand',
            collapse: 'Collapse',
            assets: {
                title1: 'Interview Clips',
                title2: 'B-Roll Clips',
                title3: 'Assets',
            },
            dropInterview: 'or drop interview clips here',
            dropClips: 'or drop b-roll clips here',
            dropAssets: 'or drop assets here',
            hours: 'Hours',
            minutes: 'Minutes',
            seconds: 'Seconds',
            areYouSure: 'Are you sure you want to <br> send the project to Production?',
            checkApprove: 'Are you sure you want to accept this video?<br>This action is irreversible.',
            daysLeft: '1 day left | {count} days left',
            review: 'Review',
            beingUploaded: 'is being uploaded',
            completed: 'Completed',
            waitingDownload: 'Waiting for the full version to be uploaded by the editor',
            completeAnd: 'Complete and...',
            revisionInfo: 'Request any changes in the link below, and then submit a new revision.',
            collabLink: 'Share a presentation of this video:',
            pending: {
                uploadFile: 'Uploading one file',
                uploadFiles: 'Uploading {numberOfFiles} files',
                remainingFiles: '{remainingFiles} of {numberOfFiles} uploaded',
                waitingForUpload: 'Waiting for upload',
                uploadFinished: 'Upload finished',
                status: {
                    uploading: 'Uploading',
                    done: 'Done',
                    queued: 'Queued',
                },
            },
        },
        page404: {
            title: 'Aaaandd... CUT!',
            subtitle: 'While you’re here, you can put your filmmaker hat on and change the emotion by switching the clapboard color.',
            goBack: 'Go back to {url}',
        },
        footer: {
            projects: 'Projects',
            pricing: 'Pricing',
            help: 'Help',
            privacy: 'Privacy Policy',
        },
        info: {
            title: 'Welcome to VidLoft',
            create: 'You can now start creating your projects by either clicking:',
            infoCreate: [
                'The “New Project” button in the upper right corner.',
                'The “Click here to create a new project” button in Pre-Production.',
            ],
            steps: 'In order to submit a project to editing, you first need to purchase Credits. Your credits will be renewed automatically each month according to your Subscription Plan.<br>Your project will be edited once you click the button “Send to Editing” found inside the project. Once the project is in “Post-Production” the project cannot be edited by you anymore. So make sure you include all the required files before sending the project to Editing.<br>“Needs Review” is the most important status for you! That means the project has been edited and requires your approval to be considered “Done”. There are two options once the project reaches this stage:',
            infoSteps: [
                '“Looks great, let’s go” - Which means you approve the project version.The project will then be found in the “Content Library” section of your dashboard together with any other completed project.',
                '“Request Changes” - Which will allow you to request some changes from the Editor. The project will then be moved back to “Post-Production”',
            ],
            final: 'Don’t forget that as an Admin for your organization, you can also add other users from your organization by clicking “Account Details” and pressing “Add User”. <br>Don’t hesitate to contact us by clicking the chat bubble on the lower right corner of the screen if you have any additional questions.',
        },
        notMobile: {
            title: 'VidLoft is not yet mobile friendly. <i class="far fa-sad-tear"></i> Please use a desktop device for optimal experience.',
        },
        modalInfoUser: {
            title: 'Your organization is still running a Free plan. Please contact the Organization Owner in order to purchase one of our Subscription Plans.',
            details: 'For more details:   <a href="https://vidloft.com/pricing/" target="_blank" >https://vidloft.com/pricing/</a>',
        },
    },
}
