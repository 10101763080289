
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Password extends Vue {
    showPassword: boolean = false

    toggleShowPassword() {
        this.showPassword = !this.showPassword
    }

    handleKeypress() {
        if (this.onKeypress) this.onKeypress()
    }

    handleEnter() {
        if (this.onEnterKey) this.onEnterKey()
    }

    @Prop() readonly value: string = ''
    @Prop() readonly inputClass?: string
    @Prop() readonly onKeypress?: () => void
    @Prop() readonly onEnterKey?: () => void
}
