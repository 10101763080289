
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import Users from './Users.vue'
import AddUser from '@/components/Organization/Modals/AddUser.vue'
import Credit from '@/components/Organization/Credit.vue'
import TopMenu from '@/components/Organization/TopMenu.vue'
import Footer from '@/components/Organization/Footer.vue'

interface UserInfo {
    id: number,
    email: string,
    phone: string,
    isEnabled: number,
    firstName: string,
    lastName: string,
}

@Component({
    components: {
        Users,
        AddUser,
        Credit,
        TopMenu,
        Footer,
    },
})
export default class AdminSettings extends Vue {
    usersInfo: Array<UserInfo> = []
    stepper: any = ''
    stepperIndex: number = 0
    isModalUserOpen: boolean = false
    toggleEditModalType: boolean = false
    start: number = 0
    limit: number = 100
    isHidingOptions: boolean = false
    infoEditUser: UserInfo = {
        id: 0,
        email: '',
        phone: '',
        isEnabled: 0,
        firstName: '',
        lastName: '',
    }

    changeStepper(index) {
        this.stepperIndex = index
    }

    openModalUser(info?) {
        if (info) {
            this.toggleEditModalType = true
            this.infoEditUser = info
        }
        this.isModalUserOpen = true
    }

    closeModalUser(isEditModal?) {
        this.isModalUserOpen = false
        if (isEditModal) {
            this.toggleEditModalType = false
        }
    }

    hideOptions() {
        this.isHidingOptions = true
    }

    toggleHidingOptions() {
        this.isHidingOptions = false
    }

    async getUsers() {
        try {
            const response = await API.GetOrganizationUsers(
                this.$store.getters.organizationId,
                this.start,
                this.limit,
            )
            this.usersInfo = response.data.filter(info => info.deletedAt === null)
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    mounted() {
        if (this.$store.getters.userRole === 'OWNER') {
            this.stepper = this.$t('pages.adminSettings.stepper')
        } else {
            this.stepper = [this.$t('pages.adminSettings.stepper')[0]]
        }
    }
}
