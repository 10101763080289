
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

interface ProjectsInfo {
    timer: string,
    name: string,
    status: string,
    hdUrl: string,
    lowResUrl: string,
    id: number,
    deletedAt: string | null,
    version: string,
    finalVideoThumbnail: string | null,
}

@Component
export default class Projects extends Vue {
    @Prop() readonly projectInfo!: ProjectsInfo
    @Prop() readonly isMenuShow!: boolean
    @Prop() readonly index!: number
    isDuplicating: boolean = false

    async deleteProject() {
        if (this.projectInfo.status === 'Assembly') {
            try {
                this.$store.commit('setProjectLoadingId', this.projectInfo.id)
                this.toggleProjectOptions()
                const response = await API.DeleteProject(this.projectInfo.id)
                if (response) {
                    this.$store.commit('removeProjectLoadingId')
                    this.$emit('getProjects')
                }
            } catch (error) {
                Sentry.captureException(error)
            }
        }
    }

    openActionModal(actionModal) {
        const savedProject = {
            id: this.projectInfo.id,
            name: this.projectInfo.name,
        }
        this.toggleProjectOptions()
        this.$emit('openActionModal', savedProject, actionModal)
    }

    toggleProjectOptions() {
        this.$emit('toggleProjectOptions', this.index)
    }
}
