const state = {
    LOGIN_AUTHOR_NAME: '2019 Nicolas Smith',
    EDITOR_DEFAULT_IMAGE: '../../assets/portrait.jpeg',
    LIMIT_DAYS: 91,
    CONTACT_PAGE: process.env.VUE_APP_CONTACT_PAGE,
    FINAL_VIDEO_LENGTHS: ['0-60 seconds', '1-2 minutes', '2-5 minutes', '5+ minutes'],
}

export default {
    state,
}
