
import { Component, Vue, Prop } from 'vue-property-decorator'
import API from '@/api'
import * as Sentry from '@sentry/browser'

@Component
export default class CustomerInfo extends Vue {
    isExpanded: Boolean = false

    @Prop() readonly info!: any
    @Prop() readonly onDelete?: (id: number) => void

    toggleInfo() {
        this.isExpanded = !this.isExpanded
    }

    async orgLogin() {
        if (this.info) {
            const resp = await API.LoginAsOrg(this.info.organizationId)
            if (resp.status === 200) {
                this.$store.commit('adminAccessToken', resp.data.token)
                this.$store.commit('organizationId', resp.data.organizationId)
                try {
                    const responseMe = await API.PersonalInfo()
                    const info = {
                        role: responseMe.data.role.name,
                        name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                        orgName: responseMe.data.organizationName,
                        id: responseMe.data.id,
                    }
                    this.$store.commit('setUserInfo', info)
                    this.$router.push('/user/projects')
                } catch (error) {
                    Sentry.captureException(error)
                }
            }
        }
    }

    saveInfoTopBar(title) {
        this.$store.commit('saveInfoTopBar', title)
    }
}
