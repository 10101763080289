
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import checkIfFileExists from '@/utils/aws'
import API from '@/api/index'

interface AssetInfo {
    id: number,
    name: string,
    state: string,
    type: string,
    preview: string,
    previewVideoUrl: string,
}

@Component
export default class AssetCard extends Vue {
    @Prop() readonly status?: string
    @Prop() readonly assetInfo!: AssetInfo
    @Prop() readonly isMenuShow!: boolean
    @Prop() readonly index?: number
    @Prop() readonly selectedMediaType?: string

    toggleMediaOptions() {
        if (this.status !== 'in_editing' && this.status !== 'waiting_for_editor' && this.status !== 'in_review' && this.status !== 'archived' && this.status !== 'approved') {
            this.$emit('toggleMediaOptions', {
                index: this.index,
                type: this.assetInfo.type,
            })
        }
    }

    get isCardDisabled() {
        let isDisabled: boolean = false
        if (this.status === 'in_editing' || this.status === 'waiting_for_editor' || this.status === 'in_review' || this.status === 'archived' || this.status === 'approved') {
            isDisabled = true
        } else {
            isDisabled = false
        }
        if (this.assetInfo.id === -1) {
            isDisabled = true
        }
        return isDisabled
    }

    async deleteAsset() {
        try {
            const response = await API.DeleteAsset(
                parseInt(this.$route.params.projectId, 10),
                this.assetInfo.id,
            )
            if (response.status === 204) {
                this.toggleMediaOptions()
                if (this.assetInfo.type === 'ASSET') {
                    this.$emit('getAssets')
                } else if (this.assetInfo.type === 'INTERVIEW_CLIP') {
                    this.$emit('getInterviewClips')
                } else {
                    this.$emit('getRollClips')
                }
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    removeQueueAsset() {
        const storeTypes = {
            ASSET: 'Asset',
            BROLL_CLIP: 'BRollClip',
            INTERVIEW_CLIP: 'InterviewClip',
        }
        this.$store.commit('removeProject', {
            name: this.assetInfo.name,
            type: storeTypes[this.assetInfo.type],
            projectId: parseInt(this.$route.params.projectId, 10),
        })
        if (this.assetInfo.type === 'ASSET') {
            this.$emit('getAssets')
        } else if (this.assetInfo.type === 'INTERVIEW_CLIP') {
            this.$emit('getInterviewClips')
        } else {
            this.$emit('getRollClips')
        }
    }

    async openMediaPreviewModal(info) {
        if (this.assetInfo.id !== -1) {
            if (this.assetInfo.type === 'ASSET') {
                this.$store.commit('setAssetInfo', this.assetInfo.preview)
            } else {
                const isAvailable = await checkIfFileExists(this.assetInfo.previewVideoUrl)
                if (isAvailable) {
                    this.$store.commit('setVideoInfo', this.assetInfo)
                } else {
                    this.$emit('noMedia')
                }
            }
        }
    }
}
