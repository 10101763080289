
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import TopMenu from '@/components/Organization/TopMenu.vue'
import { remainingTime, reviewDaysLeft } from '@/utils/project'
import ProjectCard from './ProjectCard.vue'
import Footer from '@/components/Organization/Footer.vue'
import DuplicateProjectModal from '@/components/Organization/Modals/DuplicateProjectModal.vue'

interface ProjectsInfo {
    timer: string,
    name: string,
    state: string,
    hdUrl: string,
    lowResUrl: string,
    id: number,
    deletedAt: string | null,
    version: string,
    finalVideoThumbnail: string | null,
}

interface SavedProject {
    id: number,
    name: string
}

@Component({
    components: {
        TopMenu,
        ProjectCard,
        Footer,
        DuplicateProjectModal,
    },
})
export default class Projects extends Vue {
    projects: Array<ProjectsInfo> = []
    projectsInReview: number = 0
    projectsInPostProduction: number = 0
    projectsInPreProduction: number = 0
    isActionModalShow: boolean = false
    savedAction: string = ''
    selectedProjectIndex: number | null = null
    oldSelectedProjectIndex: number | null = null
    savedProject: SavedProject = {
        id: 0,
        name: '',
    }

    toggleProjectOptions(index) {
        this.selectedProjectIndex = this.oldSelectedProjectIndex === index ? null : index
    }

    @Watch('selectedProjectIndex')
    onPropertyChanged(value: number | null, oldValue: number | null) {
        this.oldSelectedProjectIndex = value
    }

    openActionModal(savedProject?, action?) {
        this.isActionModalShow = true
        this.savedProject = savedProject
        this.savedAction = action
    }

    hideOptions() {
        if (this.selectedProjectIndex !== null) {
            this.toggleProjectOptions(this.selectedProjectIndex)
        }
    }

    closeActionModal() {
        this.isActionModalShow = false
    }

    async getProjects() {
        try {
            const response = await API.GetOrganizationProjects(this.$store.getters.organizationId)
            this.projects = response.data.projects.map(info => ({
                id: info.id,
                name: info.name,
                status: this.saveProjectStatus(info.status),
                timer: info.status !== 'IN_REVIEW' ? remainingTime({
                    status: info.status,
                    pausedAt: info.pausedAt,
                    latestDate: info.latestSubmittedAt || info.createdAt,
                    pausedMinutes: info.totalPausedMinutes,
                }) : this.reviewDeadline(info.pausedAt, info.reviewPeriod),
                hdUrl: info.hdUrl,
                lowResUrl: info.lowResUrl,
                deletedAt: info.deletedAt,
                finalVideoThumbnail: info.finalVideoThumbnail || null,
                version: `${info.noReviews ? info.noReviews : info.noReviews + 1}`
                    .padStart(2, '0'),
            }))
            response.data.projects.forEach((item, index) => {
                if (item.status !== 'IN_REVIEW') {
                    this.calculateTime(index, item.status, item.pausedAt,
                        item.latestSubmittedAt || item.createdAt, item.totalPausedMinutes)
                }
            })
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    createNewProject() {
        this.$router.push('/user/create-project')
    }

    calculateTime(index: number, status: string, pausedAt: string, latestDate: string,
        pausedMinutes: number) {
        this.projects[index].timer = remainingTime({
            status,
            pausedAt,
            latestDate,
            pausedMinutes,
        })
        if (status === 'WAITING_FOR_EDITOR' || status === 'IN_EDITING') {
            window.setTimeout(() => {
                console.log('calculate time memory leak', this.projects[index].timer)
                this.calculateTime(index, status, pausedAt, latestDate, pausedMinutes)
            }, 60000)
        }
    }

    reviewDeadline(pausedAt: string, period: number): string {
        if (period === 0) {
            return ''
        }
        const daysLeft = reviewDaysLeft(pausedAt, period)
        return this.$tc('pages.projectOrganization.daysLeft', daysLeft)
    }

    saveProjectStatus(status: string) {
        let projectStatus = 'REVIEW' || 'Editing' || 'Assembly' || 'ARCHIVED' || 'APPROVED'
        if (status === 'IN_REVIEW') {
            projectStatus = 'REVIEW'
        } else if (status === 'WAITING_FOR_EDITOR' || status === 'IN_EDITING' || status === 'PAUSED') {
            projectStatus = 'Editing'
        } else if (status === 'OPENED') {
            projectStatus = 'Assembly'
        } else if (status === 'ARCHIVED') {
            projectStatus = 'ARCHIVED'
        } else if (status === 'APPROVED') {
            projectStatus = 'APPROVED'
        }

        this.projectsInReview = projectStatus === 'REVIEW' ? this.projectsInReview + 1 : this.projectsInReview
        this.projectsInPostProduction = projectStatus === 'Editing' ? this.projectsInPostProduction + 1 : this.projectsInPostProduction
        this.projectsInPreProduction = projectStatus === 'Assembly' ? this.projectsInPreProduction + 1 : this.projectsInPreProduction

        return projectStatus
    }


    mounted() {
        this.getProjects()
    }
}

