import moment from 'moment-business-days'
import momentTZ from 'moment-timezone'
import momentTime from 'moment'
import Lang from '@/language'

const TZ = 'America/New_York'

const statusesList = {
    OPENED: Lang.t('pages.project.status.opened'),
    WAITING_FOR_EDITOR: Lang.t('pages.project.status.waitingForEditor'),
    IN_EDITING: Lang.t('pages.project.status.inEditing'),
    IN_REVIEW: Lang.t('pages.project.status.inReview'),
    PAUSED: Lang.t('pages.project.status.paused'),
    DELETED: Lang.t('pages.project.status.deleted'),
    APPROVED: Lang.t('pages.project.status.approved'),
    ARCHIVED: Lang.t('pages.project.status.archived'),
}

function prettifyData(status: string, countDown: number, areSecondsShown: boolean): string {
    if (status === 'NOT PUBLISHED') {
        return '48:00'
    }
    const hours = Math.floor(countDown / (1000 * 60 * 60))
    const diff = countDown - hours * 1000 * 60 * 60
    const minutes = Math.floor(diff / (1000 * 60))
    const seconds = momentTime.utc(diff).seconds()
    return areSecondsShown
        ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        : `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

interface RemainingTimeParams {
    status: string;
    pausedAt: string;
    latestDate: string;
    pausedMinutes: number;
    areSecondsShown?: boolean;
}

// Calculates the remaining time until the production deadline of 48 hours.
export function remainingTime(params: RemainingTimeParams): string {
    let status = params.status
    let areSecondsShown = false
    if (params.areSecondsShown) {
        areSecondsShown = true
    }
    if (status in statusesList) {
        status = statusesList[status]
    }

    const currentTime = status === 'PAUSED'
        || status === 'PENDING REVIEW'
        || status === 'DELETED'
        || status === 'FINISHED'
        ? momentTime.utc(params.pausedAt).valueOf() : moment.utc().valueOf()
    const latestTime = momentTime.utc(params.latestDate).valueOf()

    let pausedTime = params.pausedMinutes * 60 * 1000
    const interval = 48 * 60 * 60 * 1000
    // add weekends to pausedTime
    let weekendDays = 0
    let loopDay = momentTZ(currentTime).tz(TZ).startOf('day')
    while (loopDay > momentTZ(params.latestDate).tz(TZ).startOf('day')) {
        loopDay = loopDay.subtract(1, 'day')

        if (loopDay.isBusinessDay() === false) {
            weekendDays++
        }
    }

    let totalWeekendMinutes = weekendDays * 24 * 60

    // checking if latestDate is on a weekend
    if (momentTZ(params.latestDate).tz(TZ).isBusinessDay() === false) {
        totalWeekendMinutes -= momentTZ(params.latestDate).tz(TZ).diff(
            momentTZ(params.latestDate).tz(TZ).startOf('day'), 'minutes',
        )
    }

    // checking if today is on an weekend
    if (momentTZ(currentTime).tz(TZ).isBusinessDay() === false) {
        totalWeekendMinutes += momentTZ(currentTime).tz(TZ).diff(
            momentTZ(currentTime).tz(TZ).startOf('day'), 'minutes',
        )
    }

    pausedTime += totalWeekendMinutes * 60 * 1000
    return prettifyData(status, (currentTime - latestTime - pausedTime) > interval ? 0
        : (interval - (currentTime - latestTime - pausedTime)), areSecondsShown)
}


export function workDaysSince(date: string): number {
    const now = moment.utc()
    const lastSubmit = moment.utc(date)
    return now.businessDiff(lastSubmit)
}

// Calculates the number of days until automatic approval of a project.
// The `period` is the number of days allowed for review, as assigned in the
// project.
export function reviewDaysLeft(pausedAt: string, period: number): number {
    const elapsed = workDaysSince(pausedAt)

    let daysLeft = period - elapsed
    if (daysLeft < 0) {
        daysLeft = 0
    }

    return daysLeft
}
