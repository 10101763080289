
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

@Component({
    directives: {
        focus: {
            inserted: (el) => {
                el.focus()
            },
        },
    },
})
export default class TopBar extends Vue {
    username: string = ''
    profilePicture: string = ''
    hasProfilePicture: boolean = false
    isCustomTopbar: boolean = false
    isEditable: boolean = false
    editing: boolean = false
    value: string = '' // input value when editing
    editError: string = ''
    customTitle: any

    async Me() {
        try {
            const response = await API.PersonalInfo()
            const info = response.data
            if (info.name) {
                this.username = info.name
                this.$store.commit('setName', info.name)
            } else if (info.firstName && info.lastName) {
                this.username = `${info.firstName} ${info.lastName}`
                this.$store.commit('setName', this.username)
            }
            if (info.organizationName) {
                this.$store.commit('setOrgName', info.organizationName)
            }
            if (info.profilePictureUrl) {
                this.profilePicture = info.profilePictureUrl
                this.hasProfilePicture = true
                this.$store.commit('setProfilePicture', info.profilePictureUrl)
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    setTitle(route: string) {
        this.cancelEditing()
        if (route === 'project') {
            this.isCustomTopbar = true
            this.isEditable = true
            this.customTitle = this.$t('pages.project.title')
        } else if (route === 'organization') {
            this.isCustomTopbar = true
            this.isEditable = false
            this.customTitle = this.$t('pages.organization.title')
        } else {
            this.isCustomTopbar = false
            this.isEditable = false
            this.customTitle = ''
        }
    }

    @Watch('$route.name')
    onPropertyChanged(route: string) {
        this.setTitle(route)
    }

    mounted() {
        this.Me()
        this.setTitle(this.$route.name || '')
    }

    handleTitleClick() {
        if (this.isEditable) {
            this.value = this.$store.state.topBarInfo
            this.editing = true
        }
    }

    async submitTitle() {
        if (this.$store.state.topBarEditCallback) {
            const err = await this.$store.state.topBarEditCallback(this.value)
            if (err !== null) {
                this.editError = err
                return
            }
        } else {
            console.error('\'$store.state.topBarEditCallback\' is undefined')
        }
        this.cancelEditing()
    }

    cancelEditing() {
        this.editing = false
        this.editError = ''
    }
}
