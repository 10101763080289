
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import store from '@/store'

interface ProjectInfo {
    id: number,
    name: string,
}

@Component
export default class DuplicateProjectModal extends Vue {
    @Prop() readonly savedProject!: ProjectInfo
    @Prop() readonly savedAction!: string
    @Prop() readonly isActionModalShow!: boolean

    async duplicateProject() {
        try {
            this.closeActionModal()
            this.$store.commit('setProjectLoadingId', this.savedProject.id)
            const response = await API.DuplicateProject(this.savedProject.id)
            if (response) {
                this.$emit('getProjects')
                this.$store.commit('removeProjectLoadingId')
                if (this.$route.path !== '/user/projects') this.$router.push('/user/projects')
            } else {
                this.$store.commit('removeProjectLoadingId')
            }
        } catch (error) {
            this.$store.commit('removeProjectLoadingId')
            Sentry.captureException(error)
        }
    }

    closeActionModal() {
        this.$emit('closeActionModal')
    }
}
