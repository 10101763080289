'use strict'

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const dm = decimals
    const terabyteInBytes = 1099511627776

    return `${parseFloat((bytes / terabyteInBytes).toFixed(dm))} TB`
}

export default formatBytes
