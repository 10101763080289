
import { Component, Vue, Watch } from 'vue-property-decorator'
import menu from '@/utils/menuOptions'

interface MenuOption {
    title: string,
    icon: string,
    activeIcon: string,
    path: string
}

@Component
export default class LeftMenu extends Vue {
    menuOptions: Array<MenuOption> = []
    path: string = ''
    isEditor: boolean = false

    logout() {
        this.$store.commit('logOut')
        this.$router.push('/login')
    }

    @Watch('$route.name')
    onPropertyChanged(value: string, oldValue: string) {
        this.path = this.$route.name || ''
    }

    mounted() {
        this.menuOptions = menu
        this.isEditor = this.$store.getters.userRole === 'EDITOR'
        this.path = this.$route.name || ''
    }
}
