
import { Component, Vue } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import ProjectName from './ProjectName.vue'
import ProjectInfo from './ProjectInfo.vue'
import ProjectDescription from './ProjectDescription.vue'
import API from '@/api/index'

@Component({
    components: {
        ProjectName,
        ProjectInfo,
        ProjectDescription,
    },
})
export default class ProjectBox extends Vue {
    createProjectStep: number = 0
    projectName: string = ''
    audience: string = ''
    goal: string = ''
    videoLength: string = ''
    projectDescription: string = ''

    increaseStep() {
        if (this.createProjectStep < 2) {
            this.createProjectStep++
        } else {
            this.createNewProject()
        }
    }

    async createNewProject() {
        try {
            const response = await API.CreateProject(
                this.$store.getters.organizationId,
                {
                    name: this.projectName,
                    brief: this.projectDescription,
                    audience: this.audience,
                    goal: this.goal,
                    finalVideoLength: this.videoLength,
                },
            )
            this.$router.push(`/user/project/${response.data.id}`)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    saveProjectName(projectName: string) {
        this.projectName = projectName
        this.increaseStep()
    }

    saveProjectProfile(info: { audience: string; goal: string, videoLength: string}) {
        this.audience = info.audience
        this.goal = info.goal
        this.videoLength = info.videoLength
        this.increaseStep()
    }

    saveProjectDescription(projectDescription: string) {
        this.projectDescription = projectDescription
        this.increaseStep()
    }

    cancel() {
        this.$router.push('/user/projects')
    }

    get projectStep() {
        return ['ProjectName', 'ProjectInfo', 'ProjectDescription'][this.createProjectStep]
    }
}
