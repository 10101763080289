
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'

interface EditorInfo {
    name: String,
    profilePicture: String,
    id: number,
}

@Component
export default class EditorDropdown extends Vue {
    editors: Array<any> | undefined = []
    savedEditorInfo: EditorInfo = {
        name: '',
        profilePicture: '',
        id: 0,
    }

    active: EditorInfo | undefined = {
        name: '',
        profilePicture: '',
        id: 0,
    }

    @Prop() readonly hasEditor?: false
    @Prop() readonly info?: Array<EditorInfo>
    @Prop() readonly projectId?: number
    @Prop() readonly projectStatus?: string
    @Prop() readonly isSecondMenuOpen?: boolean
    @Prop() readonly index?: number

    toggleActions() {
        if (!this.projectStatus
            || (this.projectStatus !== 'FINISHED'
                && this.projectStatus !== 'NOT PUBLISHED'
                && this.projectStatus !== 'DELETED')) {
            this.$emit('toggleSecondMenu', this.index)
        }
    }

    setEditor(editorInfo) {
        this.savedEditorInfo = editorInfo
        this.$emit('setEditor', { projectId: this.projectId, editorInfo })
        this.toggleActions()
    }

    @Watch('hasEditor')
    onChangeEditorStatus(value: boolean, oldValue: boolean) {
        if (oldValue === false && value === true) {
            this.active = this.savedEditorInfo
        }
    }

    mounted() {
        if (this.hasEditor) {
            this.active = this.info && this.info[0]
            this.editors = this.info && this.info.splice(1, this.info.length)
        } else {
            this.editors = this.info
        }
    }
}
