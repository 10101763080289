
import { Component, Vue } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import menu from '@/utils/userMenuOptions'
import API from '@/api/index'
import FormatBytes from '@/utils/formatBytes'
import { getDate } from '@/utils/date'

interface MenuOption {
    title: string,
    path: string
}

@Component
export default class TopMenu extends Vue {
    menuOptions: Array<MenuOption> = []
    path: string = ''
    totalProjects: string = '0'
    usedProjects: string = '0'
    totalMemory: number = 0
    usedMemory: number = 0

    logout() {
        this.$store.commit('logOut')
        this.$router.push('/login/user')
    }

    async adminLogout() {
        this.$store.commit('adminReset')
        try {
            const responseMe = await API.PersonalInfo()
            const info = {
                role: responseMe.data.role.name,
                name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                orgName: responseMe.data.organizationName,
                id: responseMe.data.id,
            }
            this.$store.commit('setUserInfo', info)
            this.$router.push('/dashboard')
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    updated() {
        this.getOrganizationInfo()
        this.path = this.$route.path
    }

    goToCreateProject() {
        this.$router.push('/user/create-project')
    }

    goToBilling() {
        if (this.$route.name !== 'AdminSettings') {
            this.$router.push('/user/admin-settings')
        }
    }

    async getOrganizationProjects() {
        try {
            const response = await API.GetOrganizationProjects(this.$store.getters.organizationId)
            this.$store.commit('setMoreThanOneProject', response.data.projects.length >= 1)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async getOrganizationInfo() {
        try {
            const response = await API.GetOrganization(this.$store.getters.organizationId)
            const creditsInfo = response.data.credits
            if (creditsInfo) {
                if (creditsInfo.startDate && creditsInfo.endDate
                    && (creditsInfo.startDate) <= getDate(null)
                    && getDate(creditsInfo.endDate) >= getDate(null)) {
                    this.$store.commit('setCredits', true)
                } else {
                    this.$store.commit('setCredits', false)
                }
            } else {
                this.$store.commit('setCredits', false)
            }
            this.totalProjects = creditsInfo ? creditsInfo.creditsPerInterval : 0
            this.usedProjects = creditsInfo ? creditsInfo.activeCredits : 0
            this.totalMemory = response.data.storage ? response.data.storage : 0
            this.usedMemory = Number(FormatBytes(response.data.usedSpace, 6).split(' ')[0])
            this.$store.commit('setRemainingMemory', this.totalMemory - this.usedMemory)
            if (response.data.status.id === 1) {
                this.getOrganizationProjects()
            } else {
                this.$store.commit('setMoreThanOneProject', true)
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    mounted() {
        this.menuOptions = menu
        this.getOrganizationInfo()
    }
}
