export const getDate = (date) => {
    return new Date((date ? new Date(date).getTime() - new Date().getTimezoneOffset() * 60000
        : new Date().getTime()) - new Date().getTimezoneOffset() * 60000).toISOString()
}

export const getUSDate = (date: string) => {
    const tmp = date.split('-')
    return `${tmp[1]}-${tmp[2]}-${tmp[0]}`
}

export const getRenewDate = (interval: number, startDate: string | null) => {
    let newRenewDate = startDate ? new Date(startDate) : new Date()
    const totalMonth = Number(newRenewDate.getMonth()) + Number((interval || 12))
    newRenewDate.setMonth(totalMonth)
    if (newRenewDate < new Date()) {
        newRenewDate = new Date()
        newRenewDate.setDate(newRenewDate.getDate() + 1)
        newRenewDate.setMonth(Number(newRenewDate.getMonth()) + Number((interval || 12)))
    }
    return newRenewDate
}
