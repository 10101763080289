const localState = {
    videoSource: null,
    videoSourceWidth: null,
    videoSourceHeight: null,
    posterSource: null,
    assetSource: null,
    isModalPreviewMediaShow: false,
}

const getters = {
    videoSource: state => state.videoSource,
    videoSourceWidth: state => state.videoSourceWidth,
    videoSourceHeight: state => state.videoSourceHeight,
    posterSource: state => state.posterSource,
    assetSource: state => state.assetSource,
    isModalPreviewMediaShow: state => state.isModalPreviewMediaShow,
}

const actions = {}

const mutations = {
    setVideoInfo (state, info) {
        state.videoSource = info.previewVideoUrl
        state.videoSourceWidth = info.previewVideoWidth
        state.videoSourceHeight = info.previewVideoHeight
        state.posterSource = info.preview
        state.isModalPreviewMediaShow = true
    },
    setAssetInfo(state, assetSource) {
        state.assetSource = assetSource
        state.isModalPreviewMediaShow = true
    },
    closeModalPreview(state) {
        state.isModalPreviewMediaShow = false
        setTimeout(() => {
            state.videoSource = null
            state.videoSourceWidth = null
            state.videoSourceHeight = null
            state.posterSource = null
            state.assetSource = null
        }, 500)
    },
}

export default {
    state: localState,
    getters,
    actions,
    mutations,
}
