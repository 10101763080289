
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'

interface UserInfo {
    id: number,
    email: string,
    phone: string,
    isEnabled: number,
    firstName: string,
    lastName: string,
}

@Component
export default class Editor extends Vue {
    name: string = ''
    isMenuShow: boolean = false
    userStatus: 'disable' | 'enable' = 'disable'

    @Prop() readonly info!: UserInfo
    @Prop() readonly isSecondMenuOpen!: boolean
    @Prop() readonly index!: number

    toggleUserOptions() {
        this.$emit('toggleSecondMenu', this.index)
    }

    openActionModal(actionModal) {
        const savedUser = {
            name: this.name,
            id: this.info.id,
        }
        this.toggleUserOptions()
        this.$emit('openActionModal', savedUser, actionModal)
    }

    openModalUser(info) {
        this.toggleUserOptions()
        this.$emit('openModalUser', info)
    }

    @Watch('info.isEnabled')
    onPropertyChanged(value: number) {
        this.userStatus = value === 1 ? 'disable' : 'enable'
    }

    updated() {
        this.name = `${this.info.firstName} ${this.info.lastName}`
        this.userStatus = this.info.isEnabled ? 'disable' : 'enable'
    }

    mounted() {
        this.name = `${this.info.firstName} ${this.info.lastName}`
        this.userStatus = this.info.isEnabled ? 'disable' : 'enable'
    }
}
