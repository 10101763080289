import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Access/Login.vue'
import RegisterUser from '@/components/Access/RegisterUser/RegisterBox.vue'
import UserLogin from '@/components/Access/UserLogin.vue'
import AdminDashboard from './adminDashboard'
import OrganizationDashboard from './organizationDashboard'
import RecoverPassword from '@/components/Access/Password/RecoverPassword.vue'
import ChangePassword from '@/components/Access/Password/ChangePassword.vue'
import store from '@/store'
import Page404 from '@/components/Access/404.vue'
import NotMobile from '@/components/NotMobile.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,

    routes: [
        {
            path: '/',
            name: 'principal',
            beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
                if (window.innerWidth > 768) {
                    if (store.getters.userRole === 'SUPERADMIN' || store.getters.userRole === 'ADMIN' || store.getters.userRole === 'EDITOR') {
                        next('/dashboard')
                    } else if (store.getters.userRole === 'OWNER' || store.getters.userRole === 'USER') {
                        next('/user/projects')
                    } else {
                        next('/login/user')
                    }
                    next()
                } else {
                    next('/not-mobile')
                }
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
                if (window.innerWidth > 768) {
                    if (store.getters.userRole === 'SUPERADMIN' || store.getters.userRole === 'ADMIN' || store.getters.userRole === 'EDITOR') {
                        next('/dashboard')
                    } else if (store.getters.userRole === 'OWNER' || store.getters.userRole === 'USER') {
                        next('/user/projects')
                    }
                    next()
                } else {
                    next('/not-mobile')
                }
            },
        },
        {
            path: '/login/user',
            name: 'UserLogin',
            component: UserLogin,
            beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
                if (window.innerWidth > 768) {
                    if (store.getters.userRole === 'OWNER' || store.getters.userRole === 'USER') {
                        next('/user/projects')
                    } else if (store.getters.userRole === 'SUPERADMIN' || store.getters.userRole === 'ADMIN' || store.getters.userRole === 'EDITOR') {
                        next('/dashboard')
                    }
                    next()
                } else {
                    next('/not-mobile')
                }
            },
        },
        {
            path: '/recover-password',
            name: 'RecoverPassword',
            component: RecoverPassword,
        },
        {
            path: '/change-password',
            name: 'ChangePassword',
            component: ChangePassword,
        },
        {
            path: '/not-mobile',
            name: 'NotMobile',
            component: NotMobile,
        },
        {
            path: '/register',
            name: 'RegisterUser',
            component: RegisterUser,
            beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
                if (window.innerWidth > 768) {
                    if (store.getters.isLoggedIn) {
                        store.commit('logOut')
                        next()
                        return
                    }
                    next()
                } else {
                    next('/not-mobile')
                }
            },
        },
        AdminDashboard,
        OrganizationDashboard,
        {
            path: '*',
            name: 'Page404',
            component: Page404,
        },
    ],
})

export default router
