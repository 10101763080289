import Vue from 'vue'
import store from '../store'

const authHeader = () => ({
    Authorization: `Bearer ${store.state.access_token}`,
})

export const Login = async function (email: string, password: string) {
    return Vue.axios.post('login', { email, password })
}

export const LoginAsOrg = async function (organizationId: number) {
    return Vue.axios.get(`organizations/${organizationId}/admin/login`,
        { headers: authHeader() })
}

export const RecoverPassword = async function (email: string) {
    return Vue.axios.put('/password-recover', { email })
}

export const CompletePasswordRecovery = async function (email: string,
    recoveryCode: string, newPassword: string) {
    return Vue.axios.put(
        '/password-recover-complete',
        { email, recoveryCode, newPassword },
    )
}

export const CreateSubscription = async function(organizationId: number,
    paymentPlanId: number, paymentMethodId: string, billing: any, ship: any,
    coupon: string | null) {
    const renewableAt = new Date()
    renewableAt.setFullYear(renewableAt.getFullYear() + 1)
    const info = {
        renewableAt: renewableAt.toISOString().split('T')[0],
        paymentPlanId,
        paymentMethodId,
        coupon,
    }
    Object.entries(billing).forEach(([key, value]) => {
        const infoKey = `billing${key.charAt(0).toUpperCase() + key.slice(1)}`
        info[infoKey] = value
    })
    Object.entries(ship).forEach(([key, value]) => {
        if (key !== 'phone2' && key !== 'email') {
            const infoKey = `ship${key.charAt(0).toUpperCase() + key.slice(1)}`
            info[infoKey] = value
        }
    })
    return Vue.axios.post(`organizations/${organizationId}/subscriptions`, info, { headers: authHeader() })
}

export const GetOrganizations = async function(start?: number, limit?: number) {
    return Vue.axios.get('organizations', {
        headers: authHeader(),
        params: { start, limit },
    })
}

export const GetProjects = async function(start: number, limit: number,
    active?: boolean, archived?: boolean, hidden?: boolean) {
    return Vue.axios.get('projects', {
        headers: authHeader(),
        params: {
            start, limit, active, archived, hidden,
        },
    })
}

type UserRole = 'editor'

export const GetSpecifiedUsers = async function(role: UserRole, start?: number, limit?: number) {
    let url = `users?role=${role}`
    if (start && limit) {
        url += `&start=${start}&limit=${limit}`
    }
    return Vue.axios.get(url, { headers: authHeader() })
}

export const GetUsers = async function() {
    return Vue.axios.get('users', { headers: authHeader() })
}

interface TrialUser {
    id: number;
    email: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    link: string;
    codeExpiration: string;
    organizationId: number;
    organizationName: string;
}

export const GetTrialUsers = async function(
    start?: number, limit?: number,
): Promise<{data: TrialUser[]}> {
    return Vue.axios.get('users/trial', {
        headers: authHeader(),
        params: { start, limit },
    })
}

export const GetPaymentPlans = async function() {
    return Vue.axios.get('payment-plans')
}

export const ArchiveProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/archive`, {}, { headers: authHeader() })
}

export const HideProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/hide`, {}, { headers: authHeader() })
}

export const RestoreProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/restore`, {}, { headers: authHeader() })
}

export const SetEditor = async function(projectId: number, editorId: number) {
    return Vue.axios.post(`projects/${projectId}/assign-editor`, { editorId }, { headers: authHeader() })
}

export const GetSubscription = async function(organizationId: number) {
    return Vue.axios.get(`organizations/${organizationId}/subscriptions`, { headers: authHeader() })
}

export const DeleteSubscription = async function(organizationId: number) {
    return Vue.axios.delete(`organizations/${organizationId}/subscriptions`, { headers: authHeader() })
}

export const AddOrganizationInfo = async function(organizationId: number,
    infoType: string, info: unknown) {
    return Vue.axios.patch(`organizations/${organizationId}`,
        { [infoType]: info },
        { headers: authHeader() })
}

export const AddAssetToProject = async function(info: {
    name: string;
    path: string;
    previewPath: string;
    type: string;
}, projectId: number) {
    return Vue.axios.post(`projects/${projectId}/assets`, info, { headers: authHeader() })
}

export const GetAssetDownloadUrl = async function(info: {
    name: string;
    size: number;
    type: string;
    uploadType: string;
    operationName: string;
}, projectId: number) {
    return Vue.axios.post(`projects/${projectId}/links`, info, { headers: authHeader() })
}

export const EditUserInfo = async function(userId: number, info) {
    return Vue.axios.patch(`users/${userId}`, info, { headers: authHeader() })
}

export const UpdateEditorAssetPath = async function(userId: number, assetPath: string) {
    return Vue.axios.patch(`users/${userId}/asset-path`, { path: assetPath }, { headers: authHeader() })
}

export const DeleteUser = async function(userId: number) {
    return Vue.axios.delete(`users/${userId}/editor`, { headers: authHeader() })
}

export const AddUser = async function(info) {
    return Vue.axios.post('users', info, { headers: authHeader() })
}

export const GetOrganization = async function(organizationId: number) {
    return Vue.axios.get(`organizations/${organizationId}`, { headers: authHeader() })
}

export const GetOrganizationSubscription = async function(organizationId: number) {
    return Vue.axios.get(`organizations/${organizationId}/subscriptions`, { headers: authHeader() })
}

export const GetOrganizationProjects = async function(organizationId: number, type?: string) {
    return Vue.axios.get(`organizations/${organizationId}/projects`, {
        headers: authHeader(),
        params: { type },
    })
}

export const GetOrganizationUsers = async function(
    organizationId: number,
    start?: number, limit?: number,
) {
    let url = `organizations/${organizationId}/users`
    if (limit) {
        url += `?&start=${start}&limit=${limit}`
    }
    return Vue.axios.get(url, { headers: authHeader() })
}

export const DeleteOrganization = async function(id: number) {
    return Vue.axios.delete(`organizations/${id}`, { headers: authHeader() })
}

export const GetProject = async function (projectId: number) {
    return Vue.axios.get(`projects/${projectId}`, { headers: authHeader() })
}

export const PauseProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/pause`, {}, { headers: authHeader() })
}

export const ResumeProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/resume`, {}, { headers: authHeader() })
}

export const UpdateProject = async function(projectId: number, info: Record<string, any>) {
    return Vue.axios.patch(`projects/${projectId}`, info, { headers: authHeader() })
}

export const UpdateProjectReviewPeriod = function(projectId: number, period: number) {
    return Vue.axios.put(`projects/${projectId}/review_period`, {
        reviewPeriod: period,
    }, { headers: authHeader() })
}

export const SubmitProject = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/submit`, {}, { headers: authHeader() })
}

export const ApproveProject = async function (projectId: number) {
    return Vue.axios.post(`projects/${projectId}/approve`, {}, { headers: authHeader() })
}

export const RestoreToReview = async function(projectId: number) {
    return Vue.axios.post(`projects/${projectId}/restore_to_review`, {}, { headers: authHeader() })
}

export const GetProjectAssets = async function(projectId: number, type?: string) {
    let url = `projects/${projectId}/assets`
    if (type) {
        url += `?type=${type}`
    }
    return Vue.axios.get(url, { headers: authHeader() })
}

export const GetFileName = async function(projectId: number, type: string, name: string) {
    return Vue.axios.get(`projects/${projectId}/assets/check-name?type=${type}&name=${name}`, { headers: authHeader() })
}

export const Register = async function (info: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    phone: string;
    code?: string;
}) {
    return Vue.axios.post('register', info)
}

export const CreateOrganization = async function (name: string) {
    return Vue.axios.post('organizations', { name }, { headers: authHeader() })
}

export const PersonalInfo = async function () {
    return Vue.axios.get('me', { headers: authHeader() })
}

interface CreateProjectRequest {
    name: string;
    brief: string;
    audience: string;
    goal: string;
    finalVideoLength: string;
}

export const CreateProject = async function (organizationId: number, info: CreateProjectRequest) {
    return Vue.axios.post(`organizations/${organizationId}/projects`, info, { headers: authHeader() })
}

export const ResendInviteLink = async function (userId: number) {
    return Vue.axios.patch(`users/${userId}/invite-link`, {}, { headers: authHeader() })
}

export const DeleteUserById = async function (userId: number) {
    return Vue.axios.delete(`users/${userId}`, { headers: authHeader() })
}

export const DuplicateProject = async function (projectId: number) {
    return Vue.axios.post(`projects/${projectId}/duplicate`, {}, { headers: authHeader() })
}

export const AddFinalVideo = async function(projectId: number, path: string, name: string) {
    return Vue.axios.post(`projects/${projectId}/deliverable`, { path, name }, { headers: authHeader() })
}

export const DeleteAsset = async function (projectId: number, assetId: number) {
    return Vue.axios.delete(`projects/${projectId}/assets/${assetId}`, { headers: authHeader() })
}

export const DeleteAssets = async function (projectId: number, type?: string) {
    return Vue.axios.delete(`projects/${projectId}/assets`, {
        headers: authHeader(),
        params: { type },
    })
}

export const DeleteProject = async function (projectId: number) {
    return Vue.axios.delete(`projects/${projectId}`, { headers: authHeader() })
}

export const Recaptcha = async function (token: string) {
    return Vue.axios.get(`score?token=${token}`)
}

export const RefreshToken = async function() {
    return Vue.axios.put('me/refresh-token', null, { headers: authHeader() })
}

export const CheckFile = async function(url: string) {
    return Vue.axios.get(url, {
        headers: {
            Range: 'bytes=0-0',
        },
    })
}

export const GetOrganizationCredits = async function(organizationId: number) {
    return Vue.axios.get(`organizations/${organizationId}/credits`, { headers: authHeader() })
}

export const UpdateOrganizationCredits = async function(organizationId: number, info) {
    return Vue.axios.put(`organizations/${organizationId}/credits`, info, { headers: authHeader() })
}

export const API = {
    AddUser,
    AddOrganizationInfo,
    ArchiveProject,
    HideProject,
    RestoreProject,
    Login,
    RecoverPassword,
    CompletePasswordRecovery,
    CreateSubscription,
    GetOrganizations,
    DeleteUser,
    EditUserInfo,
    GetSpecifiedUsers,
    GetOrganization,
    GetOrganizationUsers,
    GetOrganizationProjects,
    GetOrganizationSubscription,
    DeleteOrganization,
    GetProject,
    GetProjects,
    GetUsers,
    GetTrialUsers,
    SetEditor,
    AddAssetToProject,
    GetAssetDownloadUrl,
    UpdateEditorAssetPath,
    GetPaymentPlans,
    GetSubscription,
    DeleteSubscription,
    PauseProject,
    ResumeProject,
    UpdateProject,
    UpdateProjectReviewPeriod,
    SubmitProject,
    GetProjectAssets,
    GetFileName,
    Register,
    CreateOrganization,
    PersonalInfo,
    CreateProject,
    DuplicateProject,
    ResendInviteLink,
    DeleteUserById,
    DeleteAsset,
    DeleteAssets,
    ApproveProject,
    RestoreToReview,
    DeleteProject,
    AddFinalVideo,
    Recaptcha,
    RefreshToken,
    CheckFile,
    GetOrganizationCredits,
    UpdateOrganizationCredits,
    LoginAsOrg,
}

export default API
