
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'
import PasswordInput from '@/components/Access/Password.vue'

@Component({
    components: {
        PasswordInput,
    },
})
export default class RegisterUser extends Vue {
    email: string = ''
    password: string = ''
    firstName: string = ''
    lastName: string = ''
    passwordAgain: string = ''
    code: string | undefined
    authorName: string = ''
    isRegisterError: TranslateResult = ''
    passwordError: TranslateResult | string | boolean = ''
    emailError: TranslateResult | string | boolean = ''
    nameError: TranslateResult | string | boolean = ''
    passwordAgainError: TranslateResult | string | boolean = ''
    phone: string = ''
    phoneError: TranslateResult | string = ''
    emailRules = rules.isEmail
    passwordRules = rules.isPassword
    isFirstTitleHidden: boolean = false
    isFirstTitleSmaller: boolean = false
    isSecondTitleShown: boolean = false
    isSecondTitleBigger: boolean = false
    isFormGrown: boolean = false
    isFormShown: boolean = false
    areInputsBigger: boolean = false
    isButtonDisabled: boolean = false

    async recaptcha(): Promise<boolean> {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')
        if (token) {
            try {
                const response = await API.Recaptcha(token)
                return response.data.score > 0
            } catch (error) {
                console.error(`recaptcha error: ${error}`)
                Sentry.captureException(error)
            }
        }
        return false
    }

    checkEmailInput() {
        const emailCheck = this.emailRules(this.email)
        if (this.email.length < 1) {
            this.emailError = this.$t('errors.rules.required.email')
        } else if (this.email.length > 99) {
            this.emailError = this.$t('errors.rules.validated.emailMax')
        } else if (emailCheck !== true) {
            this.emailError = emailCheck
        } else {
            this.emailError = ''
        }
    }

    checkPasswordInput() {
        const checkPassword = this.passwordRules(this.password)
        if (this.password.length < 1) {
            this.passwordError = this.$t('errors.rules.required.password')
        } else if (checkPassword !== true) {
            this.passwordError = checkPassword
        } else {
            this.passwordError = ''
        }
    }

    checkFirstLastNameInput() {
        if (this.firstName.length < 1 || this.lastName.length < 1) {
            this.nameError = this.$t('errors.rules.required.firstNameLastName')
        } else if (this.firstName.length > 49 || this.lastName.length > 49) {
            this.nameError = this.$t('errors.rules.validated.firstNameLastNameMax')
        } else {
            this.nameError = ''
        }
    }

    checkReEnteredPasswordInput() {
        const checkPassword = this.passwordRules(this.password)
        if (this.passwordAgain.length < 1) {
            this.passwordAgainError = this.$t('errors.rules.required.passwordAgain')
        } else if (checkPassword !== true) {
            this.passwordAgainError = checkPassword
        } else if (this.password !== this.passwordAgain) {
            this.passwordAgainError = this.$t('errors.rules.validated.passwordMatch')
        } else {
            this.passwordAgainError = ''
        }
    }

    checkPhoneInput() {
        if (this.phone.length < 1) {
            this.phoneError = this.$t('errors.rules.required.phoneNumber')
        } else {
            this.phoneError = ''
        }
    }

    async register() {
        this.checkEmailInput()
        this.checkPasswordInput()
        this.checkFirstLastNameInput()
        this.checkReEnteredPasswordInput()
        this.checkPhoneInput()

        if (!this.emailError
            && !this.passwordAgainError
            && !this.nameError
            && !this.passwordError
            && !this.phoneError
            && (await this.recaptcha())
        ) {
            this.isButtonDisabled = true
            try {
                const response = await API.Register({
                    email: this.email.trim(),
                    password: this.password,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    role: 'Owner',
                    phone: this.phone,
                    code: this.code,
                })
                if (response.status === 201) {
                    this.$store.commit('accessToken', response.data.token)
                    this.areInputsBigger = false
                    setTimeout(() => {
                        this.isFormShown = false
                    }, 500)
                    setTimeout(() => {
                        this.isFormGrown = false
                    }, 1000)
                    setTimeout(() => {
                        this.isSecondTitleBigger = false
                    }, 1500)
                    setTimeout(() => {
                        this.$emit('changeRegisterStep')
                    }, 2000)
                    this.isButtonDisabled = false
                }
            } catch (error) {
                console.error(`registration error: ${error}`)
                Sentry.captureException(error)
                this.emailError = this.$t('general.errors.alreadyUsedEmail')
            } finally {
                this.isButtonDisabled = false
            }
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        setTimeout(() => {
            this.isFirstTitleSmaller = true
        }, 1200)

        setTimeout(() => {
            this.isFirstTitleHidden = true
        }, 1400)

        setTimeout(() => {
            this.isSecondTitleShown = true
        }, 1800)

        setTimeout(() => {
            this.isSecondTitleBigger = true
        }, 2000)

        setTimeout(() => {
            this.isFormGrown = true
        }, 2600)

        setTimeout(() => {
            this.isFormShown = true
        }, 2800)

        setTimeout(() => {
            this.areInputsBigger = true
        }, 3400)
    }
}
