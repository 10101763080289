
import _ from 'lodash'
import { Component, Watch, Vue } from 'vue-property-decorator'
import VideoPlayer from '@/components/VideoPlayer.vue'

@Component({
    components: {
        VideoPlayer,
    },
})
export default class PreviewMedia extends Vue {
    playerWidth: number = 0
    playerHeight: number = 0

    // Calculates the size of the player, responsively adjusting for window size
    // while maintaining the aspect ratio of the video source.
    adjustPlayerDimensions(windowWidth: number, windowHeight: number) {
        const srcWidth = this.$store.getters.videoSourceWidth || 884
        const srcHeight = this.$store.getters.videoSourceHeight || 494
        const ar = srcWidth / srcHeight
        let targetWidth = 0.45 * windowWidth
        let targetHeight = 0.8 * windowHeight
        const maxHeight = 720
        const maxWidth = maxHeight * ar
        const minHeight = 360
        const minWidth = minHeight * ar

        if (srcHeight > srcWidth) {
            if (targetHeight > maxHeight) targetHeight = maxHeight
            else if (targetHeight < minHeight) targetHeight = minHeight
            targetWidth = targetHeight * ar
        } else {
            if (targetWidth > maxWidth) targetWidth = maxWidth
            else if (targetWidth < minWidth) targetWidth = minWidth
            targetHeight = targetWidth / ar
        }

        this.playerWidth = targetWidth
        this.playerHeight = targetHeight
    }

    @Watch('$store.getters.videoSource')
    isSourceChanged() {
        this.adjustPlayerDimensions(window.innerWidth, window.innerHeight)
    }

    mounted() {
        this.adjustPlayerDimensions(window.innerWidth, window.innerHeight)
    }

    resizeHandler() {
        this.adjustPlayerDimensions(window.innerWidth, window.innerHeight)
    }

    dbResizeHandler = _.debounce(this.resizeHandler, 100)

    created() {
        window.addEventListener('resize', this.dbResizeHandler)
    }

    destroyed() {
        window.removeEventListener('resize', this.dbResizeHandler)
    }
}
