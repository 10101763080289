import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import decode from 'jwt-decode'

const PersistentStore = async (store) => {
    const token = localStorage.getItem('token')
    const adminToken = localStorage.getItem('adminToken')
    const organizationId = localStorage.getItem('orgId')
    const projects = localStorage.getItem('projects')
        && JSON.parse(localStorage.getItem('projects') || '')
    const location = window.location
    let forgotPassword = false

    if (location.pathname === '/change-password' && location.search.includes('email=')) {
        forgotPassword = true
    }

    const getPersonalInfo = async () => {
        try {
            const response = await API.PersonalInfo()
            const info = {
                role: response.data.role.name,
                name: `${response.data.firstName} ${response.data.lastName}`,
                orgName: response.data.organizationName,
                id: response.data.id,
            }
            store.commit('setUserInfo', info)
        } catch (error) {
            store.commit('removeAccessToken')
            Sentry.captureException(error)
        }
    }

    const topBarInfo = localStorage.getItem('topBarInfo')

    if (token && !forgotPassword) {
        store.commit('accessToken', token)
        store.commit('adminToken', adminToken)
        try {
            const decodedToken = decode(token)
            if (decodedToken && decodedToken.exp) {
                const expDate = new Date(decodedToken.exp * 1000)
                const currentDate = new Date()
                if (expDate > currentDate) {
                    const days = Math.ceil((expDate.getTime() - currentDate.getTime())
                        / (1000 * 60 * 60 * 24))
                    if (days <= 2) {
                        const response = await API.RefreshToken()
                        store.commit('accessToken', response.data.token)
                    }
                }
            }
        } catch (error) {
            Sentry.captureException(error)
        }
        await getPersonalInfo()
    }
    if (projects) {
        store.commit('setProjects', projects)
    }
    if (organizationId) {
        store.commit('organizationId', organizationId)
    }
    if (topBarInfo) {
        store.commit('saveInfoTopBar', topBarInfo)
    }
}

export default PersistentStore
