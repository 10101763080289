import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import constants from './modules/constants'
import files from './modules/files'
import media from './modules/media'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        constants,
        files,
        media,
    },
    state: {
        access_token: null,
        admin_token: null,
        topBarInfo: null,
        organizationId: null,
        topBarEditCallback: null,
    },
    mutations: {
        accessToken(state, token) {
            state.access_token = token
            localStorage.setItem('token', token)
        },
        adminToken(state, token) {
            state.admin_token = token
            localStorage.setItem('adminToken', token)
        },
        adminAccessToken(state, token) {
            state.admin_token = state.access_token
            state.access_token = token
            const tmp = state.admin_token
            localStorage.setItem('token', token)
            if (tmp) {
                localStorage.setItem('adminToken', tmp)
            }
        },
        adminReset(state) {
            const token = state.admin_token
            state.access_token = token
            state.admin_token = null
            if (token) {
                localStorage.setItem('token', token)
            } else {
                localStorage.removeItem('token')
            }
            localStorage.removeItem('adminToken')
        },
        removeAccessToken(state) {
            state.access_token = null
            state.admin_token = null
            localStorage.removeItem('token')
            localStorage.removeItem('adminToken')
        },
        organizationId(state, id) {
            state.organizationId = id
            localStorage.setItem('orgId', id)
        },
        logOut(state) {
            localStorage.removeItem('token')
            localStorage.removeItem('adminToken')
            localStorage.removeItem('orgId')
            localStorage.removeItem('topBarInfo')
            localStorage.removeItem('userName')
            localStorage.removeItem('profilePictureUrl')
            state.access_token = null
            state.admin_token = null
            state.organizationId = null
        },
        saveInfoTopBar(state, info) {
            state.topBarInfo = info
            localStorage.setItem('topBarInfo', info)
        },
        setTopBarEditCallback(state, cb) {
            state.topBarEditCallback = cb
        },
    },
    getters: {
        isLoggedIn: state => state.access_token,
        organizationId: state => state.organizationId,
    },
    actions: {},
})
