
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api'

interface InfoOnboarding {
    statusId: number,
    organizationId: number,
    organizationName: string,
    assetsLink: string,
    subscriptionStatus: string,
    kit: string,
    type: string,
    orderDate: string,
    trackingCode: string,
}

interface TrialUser {
    id: number;
    organizationId: number;
    organizationName: string;
    email: string;
    name: string;
    expiration: string;
    link: string;
}

@Component
export default class onboarding extends Vue {
    isModalShow = false
    tab = ''
    customersInfo: InfoOnboarding[] = []
    error: TranslateResult = ''
    savedOrganizationId = 0
    limit = 50
    offset = 0
    canGetOrganizations = false
    linkRules = rules.linkRules
    trackingCodeRules = rules.trackingCodeRules
    isGettingOrganizations = false

    trialUsers: TrialUser[] = []
    trialUsersLimit = 20
    trialUsersOffset = 0
    canGetTrialUsers = false
    trialUsersLoading = false

    getMoreOrganizations() {
        this.offset += this.limit
        this.getOrganizations()
    }

    async getOrganizations() {
        try {
            this.isGettingOrganizations = true
            const response = await API.GetOrganizations(
                this.offset, this.limit,
            )
            const customers = response.data.map(info => ({
                statusId: info.statusId,
                organizationId: info.id,
                organizationName: info.name,
                assetsLink: info.assetsLink,
                subscriptionStatus: this.subscriptionStatus(info),
                kit:
                info.renewableAt === null ? 'not ordered' : 'ordered',
                type:
                info.renewableAt === null ? 'N/A' : 'standard',
                orderDate: info.renewableAt === null ? 'N/A' : info.createdAt.split('T')[0],
                trackingCode: info.trackingCode,
            }))
            this.customersInfo = [...this.customersInfo, ...customers]
            this.canGetOrganizations = customers.length === this.limit
        } catch (error) {
            console.error('failed to retrieve organizations', String(error))
            Sentry.captureException(error)
        } finally {
            this.isGettingOrganizations = false
        }
    }

    getMoreTrialUsers() {
        this.trialUsersOffset += this.trialUsersLimit
        this.getTrialUsers()
    }

    async getTrialUsers() {
        try {
            this.trialUsersLoading = true
            const res = await API.GetTrialUsers(this.trialUsersOffset, this.trialUsersLimit)
            const users = res.data.map(info => ({
                id: info.id,
                organizationId: info.organizationId,
                organizationName: info.organizationName,
                email: info.email,
                name: `${info.firstName} ${info.lastName}`,
                expiration: new Date(info.codeExpiration).toLocaleString(),
                link: info.link,
            }))
            this.trialUsers = [...this.trialUsers, ...users]
            this.canGetTrialUsers = users.length === this.trialUsersLimit
        } catch (error) {
            console.error('failed to retrieve trial users', String(error))
            Sentry.captureException(error)
        } finally {
            this.trialUsersLoading = false
        }
    }

    async completeOnboarding() {
        try {
            await API.AddOrganizationInfo(
                this.savedOrganizationId, 'state', 'APPROVED',
            )
            this.customersInfo.forEach((info) => {
                if (info.organizationId === this.savedOrganizationId) {
                    info.statusId = 2
                }
            })
            this.hideModal()
        } catch (error) {
            console.error('failed to update organization state', String(error))
            Sentry.captureException(error)
        }
    }

    async saveOrganizationAsset(organizationId: number, assetsLink: string) {
        try {
            await API.AddOrganizationInfo(organizationId, 'assetsLink', assetsLink)
        } catch (error) {
            console.error('failed to update organization assets link', String(error))
            Sentry.captureException(error)
        }
    }

    async saveOrganizationTrackingId(organizationId: number, trackingCode: string) {
        try {
            await API.AddOrganizationInfo(organizationId, 'trackingCode', trackingCode)
        } catch (error) {
            console.error('failed to update organization tracking code', String(error))
            Sentry.captureException(error)
        }
    }

    showCompleteModal(organizationId: number) {
        this.$root.$emit('bv::show::modal', 'confirm-modal', '#btnShow')
        this.error = ''
        this.savedOrganizationId = organizationId
    }

    hideModal() {
        this.$root.$emit('bv::hide::modal', 'confirm-modal', '#btnHide')
    }

    subscriptionStatus(info) {
        if (info.renewableAt === null) {
            return 'inactive'
        }
        if (info.renewableAt !== null && info.isCancelled === true) {
            return 'canceled'
        }
        if (info.renewableAt !== null && info.isCancelled !== true) {
            return 'active'
        }
        return 'inactive'
    }

    saveInfoTopBar(title: string) {
        this.$store.commit('saveInfoTopBar', title)
    }

    mounted() {
        this.getOrganizations()
        this.getTrialUsers()
    }
}
