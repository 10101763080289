import store from '@/store'
import AdminSettings from '@/components/Organization/Admin/AdminSettings.vue'
import ProjectBox from '@/components/Organization/CreateProject/ProjectBox.vue'
import ContentLibrary from '@/components/Organization/ContentLibrary/ContentLibrary.vue'
import Projects from '@/components/Organization/Projects/Projects.vue'
import Project from '@/components/Organization/Project/Project.vue'

export default {
    path: '/user',
    component: {
        template: '<router-view />',
    },
    beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
        if (store.getters.userRole !== 'OWNER' && store.getters.userRole !== 'USER') {
            next('/404')
            return
        }
        if (window.innerWidth > 768) {
            next()
        } else {
            next('/not-mobile')
        }
    },
    children: [
        {
            path: 'admin-settings',
            name: 'AdminSettings',
            component: AdminSettings,
        },
        {
            path: 'create-project',
            name: 'CreateProject',
            component: ProjectBox,
        },
        {
            path: 'content-library',
            name: 'ContentLibrary',
            component: ContentLibrary,
        },
        {
            path: 'projects',
            name: 'Projects',
            component: Projects,
        },
        {
            path: 'project/:projectId',
            name: 'Project',
            component: Project,
        },
    ],
}
