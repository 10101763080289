
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ProjectName extends Vue {
    name: string = ''
    authorName: string = ''
    nameError: TranslateResult | string | boolean = ''
    isTitleShown: boolean = false
    isFormShown: boolean = false
    areInputsGrown: boolean = false

    checkNameInput() {
        if (this.name.length < 1) {
            this.nameError = this.$t('errors.rules.required.projectName')
        } else if (this.name.length > 43) {
            this.nameError = this.$t('errors.rules.validated.projectNameMax')
        } else {
            this.nameError = ''
        }
    }

    checkInput(evt) {
        this.nameError = ''
        const charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)
            || (charCode >= 48 && charCode <= 57)) {
            return true
        }
        evt.preventDefault()
        this.nameError = this.$t('errors.rules.validated.organizationName')
        return false
    }

    cancel() {
        this.$emit('cancel')
    }

    goNextStep() {
        this.checkNameInput()
        if (!this.nameError) {
            setTimeout(() => {
                this.areInputsGrown = false
            }, 400)
            setTimeout(() => {
                this.isFormShown = false
            }, 800)
            setTimeout(() => {
                this.isTitleShown = false
                this.$emit('saveProjectName', this.name)
            }, 1200)
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        setTimeout(() => {
            this.isTitleShown = true
        }, 500)
        setTimeout(() => {
            this.isFormShown = true
        }, 1000)
        setTimeout(() => {
            this.areInputsGrown = true
        }, 1500)
    }
}
