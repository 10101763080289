
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NoMedia extends Vue {
    @Prop() readonly showModal!: boolean

    closeModal() {
        this.$emit('closeModal')
    }
}
