import i18n from '@/language/en'

type Rule = ((value: string) => (boolean | string))
| ((val: { value: string; type: string }) => (boolean | string))

const isEmail: Rule = (value: string) => /.+@.+\..+/.test(value)
    || i18n.errors.rules.validated.email

const isPassword: Rule = (value: string) => (value && value.length >= 6)
    || i18n.errors.rules.validated.password

const isLink: Rule = (value: string) => (value && value.length < 199
    && /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value)
) || i18n.errors.rules.validated.link

const isNumber: Rule = (value: string) => /^[0-9]*$/.test(value)
    || i18n.errors.rules.validated.phoneNumber

const isPhoneNumber: Rule = (value: string) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value)
    || i18n.errors.rules.validated.phoneNumber

const notEmpty: Rule = ({ value, type }: { value: string; type: string }) => value.length > 1
    || i18n.pages.billing.errors[type]

const isTrackingCode: Rule = (value: string) => (value && value.length < 99)
    || i18n.errors.rules.validated.trackingId

const isFirstNameLength: Rule = (value: string) => (value && value.length < 99)
    || i18n.errors.rules.validated.firstNameMax

const isLastNameLength: Rule = (value: string) => (value && value.length < 99)
    || i18n.errors.rules.validated.lastNameMax

const isEmailLength: Rule = (value: string) => (value && value.length < 99)
    || i18n.errors.rules.validated.emailMax

const isPhoneNumberLength: Rule = (value: string) => (value && value.length < 14)
    || i18n.errors.rules.validated.phoneNumberMax

// Organization Name rules are copied from the backend, at `src/util/regex.ts`.
//
// These rules are meant to prevent issues with S3 object keys, since the name
// of the organization is part of the key used to identify assets.

const organizationNameChars: Rule = (value: string) => /^[\w.'\-* !]+$/.test(value)
    || i18n.errors.rules.validated.organizationNameChars

const organizationNameFirstChar: Rule = (value: string) => /^\w+.*$/.test(value)
    || i18n.errors.rules.validated.organizationNameFirstChar

const organizationNameConsecutiveSpaces: Rule = (value: string) => /^(?!.*[ ]{2}).*$/.test(value)
    || i18n.errors.rules.validated.organizationNameConsecutiveSpaces

const emailRules: Rule[] = [
    (value: string) => !!value || i18n.errors.rules.required.email,
    isEmail,
    isEmailLength,
]

const passwordRules: Rule[] = [
    (value: string) => !!value || i18n.errors.rules.required.password,
    isPassword,
]

const linkRules: Rule[] = [
    (value: string) => !!value || i18n.errors.rules.required.link,
    isLink,
]

const firstNameRules: Rule[] = [
    (value: any) => !!value || i18n.errors.rules.required.firstName,
    isFirstNameLength,
]

const lastNameRules: Rule[] = [
    (value: any) => !!value || i18n.errors.rules.required.lastName,
    isLastNameLength,
]

const phoneRules: Rule[] = [
    (value: any) => !!value || i18n.errors.rules.required.phoneNumber,
    isNumber,
    isPhoneNumberLength,
]

const trackingCodeRules: any[] = [
    isTrackingCode,
]

const organizationNameRules: Rule[] = [
    organizationNameChars,
    organizationNameFirstChar,
    organizationNameConsecutiveSpaces,
]

export const rules = {
    emailRules,
    passwordRules,
    organizationNameRules,
    linkRules,
    firstNameRules,
    lastNameRules,
    phoneRules,
    isEmail,
    isPassword,
    isLink,
    isNumber,
    isPhoneNumber,
    notEmpty,
    trackingCodeRules,
}

export default rules
