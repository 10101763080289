'use strict'

const formatBytesAsset = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    let sizeString = ''

    if (bytes === undefined || bytes === null || bytes === 0) {
        sizeString = '0 bytes'
    } else {
        const i = Math.floor(Math.log(bytes) / Math.log(1024))
        sizeString = parseFloat((bytes / (1024 ** i)).toFixed(2).toString()) + sizes[i]
    }

    return sizeString
}

export default formatBytesAsset
