import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#464A4F',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                success: '#87C232',
                danger: '#EA7824',
            },
        },
    },
})
