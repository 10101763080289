const localState = {
    userRole: null,
    name: null,
    orgName: null,
    id: null,
    profilePictureUrl: null,
    paymentPlanInfo: null,
    hasMoreThanOneProject: null,
    isUserInfoModalShown: null,
    hasCredits: null,
}

const getters = {
    userRole: state => state.userRole,
    hasAccessToAdminPortal: state => state.userRole === 'ADMIN' || state.userRole === 'SUPERADMIN' || state.userRole === 'EDITOR',
    hasAccessToOrganizationPortal: state => state.userRole === 'OWNER' || state.userRole === 'USER',
    paymentPlanInfo: state => state.paymentPlanInfo,
    hasMoreThanOneProject: state => state.hasMoreThanOneProject,
    isUserInfoModalShown: state => state.isUserInfoModalShown,
    hasCredits: state => state.hasCredits,
    userInfo: state => state,
}

const actions = {}

const mutations = {
    setUserInfo (state, info) {
        state.userRole = info.role
        state.orgName = info.orgName
        state.name = info.name
        state.id = info.id
    },
    logOut (state) {
        state.userRole = null
        state.name = null
        state.id = null
    },
    setName(state, name) {
        state.name = name
        localStorage.setItem('userName', name)
    },
    setCredits(state, status) {
        state.hasCredits = status
    },
    setProfilePicture(state, url) {
        state.profilePictureUrl = url
        localStorage.setItem('profilePictureUrl', url)
    },
    setActivePaymentPlan(state, paymentPlanInfo) {
        state.paymentPlanInfo = paymentPlanInfo
    },
    setMoreThanOneProject(state, hasMoreThanOneProject) {
        state.hasMoreThanOneProject = hasMoreThanOneProject
    },
    toggleInfoModalUser(state, isUserInfoModalShown) {
        state.isUserInfoModalShown = isUserInfoModalShown
    },
}

export default {
    state: localState,
    getters,
    actions,
    mutations,
}
