
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import FileUpload from '@/components/FileUpload.vue'
import FormatBytesAsset from '@/utils/formatBytesAsset'
import img from '@/utils/imgDimensions'
import AssetCard from './AssetCard.vue'
import UploadLoading from './UploadLoading.vue'
import PreviewMedia from '@/components/Organization/Modals/PreviewMedia.vue'
import ConfirmActionModal from '@/components/Organization/Modals/ConfirmActionModal.vue'

interface MediaInfo {
    name: string,
    size: string,
    isDeleted: string,
    type: string,
    id: number,
    preview: string,
    previewVideoUrl: string,
    previewVideoWidth: number;
    previewVideoHeight: number;
}

@Component({
    components: {
        FileUpload,
        AssetCard,
        UploadLoading,
        PreviewMedia,
        ConfirmActionModal,
    },
})
export default class Assets extends Vue {
    isInterviewShow = true
    isClipsShow = true
    isAssetsShow = true
    showLoading = false
    actualPart = 0
    totalParts = 0
    actualProjectInfo: any = ''
    interviewClips: MediaInfo[] = []
    bRollClips: MediaInfo[] = []
    assets: MediaInfo[] = []
    fileName = ''
    fileType: 'InterviewClip' | 'Asset' | 'BRollClip' | '' = ''

    interviewClipsError = ''
    bRollClipsError = ''
    hasError = false

    selectedMediaIndex: number | null = null
    oldSelectedMediaIndex: number | null = null
    selectedMediaType: string | null = null
    oldSelectedMediaType: string | null = null
    isModalPreviewShow = false
    hasNoMemory = false

    isActionModalShow = false
    confirmActionModalWarning = ''

    savedAction = ''
    savedDeleteType = ''
    uploadingFileUid = 0
    incompleteProjects: {
        Asset: {
            items: MediaInfo[],
            isfetched: boolean,
        },
        InterviewClip:{
            items: MediaInfo[],
            isfetched: boolean,
        },
        BRollClip: {
            items: MediaInfo[],
            isfetched: boolean,
        },
    } = {
        Asset: {
            items: [],
            isfetched: false,
        },
        InterviewClip: {
            items: [],
            isfetched: false,
        },
        BRollClip: {
            items: [],
            isfetched: false,
        },
    }

    @Prop() readonly status?: string
    @Prop() readonly isCheckingFields?: boolean

    openActionModal(action) {
        if (!this.isCardDisabled) {
            this.isActionModalShow = true
            this.savedAction = action
            switch (action) {
            case 'delete all Interview Clips': this.savedDeleteType = 'InterviewClip'
                break
            case 'delete all Assets': this.savedDeleteType = 'Asset'
                break
            case 'delete all B Roll Clips': this.savedDeleteType = 'BRollClip'
                break
            default: return true
            }
        }
        return true
    }

    noMedia() {
        this.$emit('noMedia')
    }

    closeActionModal() {
        this.isActionModalShow = false
    }

    togglePreviewModal() {
        this.isModalPreviewShow = !this.isModalPreviewShow
    }

    toggleMediaOptions(info) {
        this.selectedMediaIndex = (
            this.oldSelectedMediaIndex === info.index)
            && (this.oldSelectedMediaType === info.type)
            ? null : info.index
        this.selectedMediaType = info.type
    }

    @Watch('selectedMediaIndex')
    onSideMenuChange(value: number | null, oldValue: number | null) {
        this.oldSelectedMediaIndex = value
    }

    @Watch('selectedMediaType')
    onSideMenuTypeChange(value: string | null, oldValue: string | null) {
        this.oldSelectedMediaType = value
    }

    get isCardDisabled() {
        let isDisabled: boolean = false
        if (this.status === 'in_editing' || this.status === 'waiting_for_editor' || this.status === 'in_review' || this.status === 'approved' || this.status === 'archived') {
            isDisabled = true
        }
        return isDisabled
    }

    get isApprovedNorOpened() {
        let status: boolean = false
        if (this.status !== 'approved' && this.status !== 'opened' && this.status !== 'archived') {
            status = true
        }
        return status
    }

    toggleInterview() {
        this.isInterviewShow = !this.isInterviewShow
    }

    toggleClips() {
        this.isClipsShow = !this.isClipsShow
    }

    toggleAssets() {
        this.isAssetsShow = !this.isAssetsShow
    }

    async getMedia(mediaType: 'InterviewClip' | 'Asset' | 'BRollClip') {
        try {
            const response = await API.GetProjectAssets(
                Number(this.$route.params.projectId), mediaType,
            )

            const dimensions: {width: number; height: number}[] = await Promise.all(
                response.data.map(({ previewPath }) => img.calculatePreviewVideoDimensions(
                    previewPath,
                )),
            )

            const infoMedia = response.data.map((info, idx) => ({
                name: info.name,
                size: FormatBytesAsset(Number(info.size)),
                isDeleted: info.deletedAt,
                type: info.type.name,
                id: info.id,
                preview: info.previewPath,
                previewVideoUrl: info.path,
                previewVideoWidth: dimensions[idx].width,
                previewVideoHeight: dimensions[idx].height,
            }))
            const projectType = mediaType.replace(/([a-z])([A-Z])/g, '$1_$2')
            const projectsInCategory = this.$store.getters.projectsInCategory(mediaType,
                Number(this.$route.params.projectId))
            if (!this.incompleteProjects[mediaType].isfetched) {
                this.incompleteProjects[mediaType].items = projectsInCategory
                this.incompleteProjects[mediaType].isfetched = true
            } else {
                this.incompleteProjects[mediaType].items = this.incompleteProjects[mediaType].items
                    .filter((elem) => {
                        return projectsInCategory.findIndex(item => item.name === elem.name
                            && item.size === elem.size) !== -1
                    })
            }
            this.incompleteProjects[mediaType].items.forEach((info) => {
                infoMedia.push({
                    name: info.name,
                    size: FormatBytesAsset(Number(info.size)),
                    isDeleted: null,
                    type: projectType.toUpperCase(),
                    id: -1,
                    preview: null,
                    previewVideoUrl: null,
                })
            })
            switch (mediaType) {
            case 'Asset': this.assets = infoMedia
                break
            case 'InterviewClip': this.interviewClips = infoMedia
                break
            case 'BRollClip': this.bRollClips = infoMedia
                break
            default: return
            }
        } catch (error) {
            console.error(error)
            Sentry.captureException(error)
        }
    }

    async deleteMedia(type) {
        if (!this.isApprovedNorOpened) {
            try {
                await API.DeleteAssets(parseInt(this.$route.params.projectId, 10), type)
                switch (type) {
                case 'Asset': this.assets = []
                    break
                case 'InterviewClip': this.interviewClips = []
                    break
                case 'BRollClip': this.bRollClips = []
                    break
                default: return
                }
                this.closeActionModal()
            } catch (error) {
                Sentry.captureException(error)
            }
        }
    }

    checkMedia() {
        this.hasError = false

        const incompleteInterviewClips = this.interviewClips.filter(asset => asset.id === -1)
        if (incompleteInterviewClips.length > 0) {
            this.hasError = true
            this.interviewClipsError = this.$t('general.errors.incompleteClips').toString()
        }

        const incompleteBRollClips = this.bRollClips.filter(asset => asset.id === -1)
        if (incompleteBRollClips.length > 0) {
            this.hasError = true
            this.bRollClipsError = this.$t('general.errors.incompleteClips').toString()
        }

        if (this.hasError) return

        const hasInterviews = this.interviewClips.length > 0
        const hasBRolls = this.bRollClips.length > 0
        const hasAssets = this.assets.length > 0

        if (!hasInterviews && !hasBRolls && !hasAssets) {
            this.confirmActionModalWarning = this.$t('general.warnings.noAssetsWarning').toString()
            this.hasError = false
        } else {
            this.hasError = false
            this.interviewClipsError = ''
            this.bRollClipsError = ''
            this.confirmActionModalWarning = ''
        }
    }

    async uploadAsset(event, fileType) {
        event.preventDefault()
        this.interviewClipsError = ''
        this.bRollClipsError = ''
        this.confirmActionModalWarning = ''

        let files = event.target.files
        if (files) {
            if (event.dataTransfer) {
                files.concat(event.dataTransfer.files)
            }
        } else {
            files = event.dataTransfer.files
        }
        let totalSize = 0;
        [...files].forEach((file) => {
            totalSize += file.size
        })
        totalSize /= (1024 * 1024 * 1024 * 1024)
        if (totalSize > this.$store.getters.remainingMemory + 0.0002) {
            this.hasNoMemory = true
            setTimeout(() => {
                this.hasNoMemory = false
            }, 3000)
        } else {
            this.$store.commit('setRemainingMemory',
                this.$store.getters.remainingMemory - totalSize)
            const startUpload = this.$store.getters
                .getProjectQueue(Number(this.$route.params.projectId)).length === 0
                && !this.$store.getters.isUploading;
            [...files].forEach((file) => {
                this.uploadingFileUid += 1
                file.uid = this.uploadingFileUid
                this.$store.commit('addProjectToQueue', {
                    file,
                    type: fileType,
                    projectId: Number(this.$route.params.projectId),
                })
            })
            await new Promise(r => setTimeout(r, 1000))
            if (startUpload) {
                const info = this.$store.getters.getFromQueue
                this.$store.commit('removeProjectFromQueue')
                this.singleUpload(info.file, info.type, info.file.uid)
            }
        }
    }

    async singleUpload(file: File, fileType, fileUid) {
        this.$store.commit('removeProjectPartsInfo')
        const uploader = new FileUpload()
        this.fileName = file.name
        this.fileType = fileType
        await uploader.uploadFile(
            file,
            this.$store,
            fileType,
            Number(this.$route.params.projectId),
            Number(this.$store.getters.organizationId),
            fileUid,
        )
    }

    dragHandler(event: Event) {
        event.preventDefault()
    }

    @Watch('showLoading')
    onLoadingChange(value: boolean) {
        const type = this.$store.getters.uploadingFileType
        if (!value) {
            if (type === 'InterviewClip') {
                this.interviewClipsError = ''
                this.confirmActionModalWarning = ''
                this.getMedia(type)
            } else if (type === 'BRollClip') {
                this.bRollClipsError = ''
                this.confirmActionModalWarning = ''
                this.getMedia(type)
            } else {
                this.getMedia('Asset')
            }
        }
    }

    @Watch('$store.getters.remainingParts')
    onPropertyChange(value: number) {
        this.actualPart = this.$store.getters.totalParts - value
    }

    @Watch('$store.getters.totalParts')
    onPropertyChanged(value: number) {
        this.totalParts = value
    }

    @Watch('$store.getters.isUploading')
    onValueChanged(value: boolean) {
        this.showLoading = value
        if (!value) {
            const info = this.$store.getters.getFromQueue
            this.$store.commit('removeProjectFromQueue')
            if (info) {
                this.singleUpload(info.file, info.type, info.file.uid)
            }
        }
    }

    @Watch('isCheckingFields')
    onCheckingFields(value: boolean) {
        if (value) {
            this.checkMedia()
            if (this.hasError) {
                const assetsElement = this.$refs.assets as HTMLDivElement
                assetsElement.scrollIntoView()
            }
            this.$emit('sendToEditing', this.hasError, this.confirmActionModalWarning)
        }
    }

    @Watch('status')
    onCheckStatus(value: string) {
        if (value === 'approved') {
            this.isInterviewShow = false
            this.isClipsShow = false
            this.isAssetsShow = false
        }
    }

    mounted() {
        this.getMedia('InterviewClip')
        this.getMedia('BRollClip')
        this.getMedia('Asset')
        if (this.status === 'approved') {
            this.isInterviewShow = false
            this.isClipsShow = false
            this.isAssetsShow = false
        }
    }
}
