
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'
import { AxiosError } from 'axios'
import PasswordInput from '@/components/Access/Password.vue'

@Component({
    components: {
        PasswordInput,
    },
})
export default class ChangePassword extends Vue {
    password: string = ''
    confirmPassword: string = ''
    recoveryCode: string = ''
    authorName: string = ''
    email: string = ''
    isUpdateError: TranslateResult = ''
    passwordError: TranslateResult | string | boolean = ''
    confirmPasswordError: TranslateResult | string | boolean = ''
    passwordRules = rules.isPassword

    checkPasswordInput() {
        if (this.password.length < 1) {
            this.passwordError = this.$t('errors.rules.required.password')
        } else if (this.passwordRules(this.password) !== true) {
            this.passwordError = this.passwordRules(this.password)
        } else {
            this.passwordError = ''
        }
    }

    checkConfirmPasswordInput() {
        this.checkPasswordInput()
        if (this.password.length < 1) {
            this.confirmPasswordError = ''
        } else if (this.password !== this.confirmPassword) {
            this.confirmPasswordError = this.$t('errors.rules.validated.confirmPassword')
        } else {
            this.confirmPasswordError = ''
        }
    }

    async updatePassword() {
        this.checkPasswordInput()
        this.checkConfirmPasswordInput()
        if (this.passwordError || this.confirmPasswordError) {
            return
        }

        try {
            const response = await API.CompletePasswordRecovery(
                this.email, this.recoveryCode, this.password,
            )
            if (response.status === 200) {
                this.$store.commit('accessToken', response.data.token)
                this.$store.commit('organizationId', response.data.organizationId)
                const responseMe = await API.PersonalInfo()
                const info = {
                    role: responseMe.data.role.name,
                    name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                    orgName: responseMe.data.organizationName,
                    id: responseMe.data.id,
                }
                this.$store.commit('setUserInfo', info)
                if (this.$store.getters.hasAccessToAdminPortal) {
                    this.$router.push('/dashboard')
                } else {
                    this.$router.push('/user/projects')
                }
            }
        } catch (error) {
            const axiosErr = error as AxiosError
            if (axiosErr.response && axiosErr.response.data
                && axiosErr.response.data.code === 100) {
                this.isUpdateError = this.$t('errors.rules.validated.invalidPasswordRecoveryCode')
            } else {
                Sentry.captureException(error)
                this.isUpdateError = this.$t('general.errors.server')
            }
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        this.recoveryCode = decodeURIComponent(String(this.$route.query.code))
        this.email = decodeURIComponent(String(this.$route.query.email))
    }
}
