
import { Component, Vue, Watch } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import _ from 'lodash'
import { AxiosError } from 'axios'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import { remainingTime } from '@/utils/project'
import TableHeads from '@/utils/tableHeads'
import EditorDropdown from '@/components/AdminPortal/EditorDropdown.vue'

interface ProjectInfo {
    projectId: number,
    timer: string,
    projectTitle: string,
    organization: string,
    status: string | TranslateResult,
    creationDate: string,
    version: string,
    feedbackLink: string,
    editor: any,
    hasEditor: boolean,
    randomId: number,
    random: number,
    pausedAt: string,
    latestSubmittedAt: string,
    createdAt: string,
    totalPausedMinutes: number,
}

interface EditorInfo {
    name: String,
    profilePicture: String,
    id: number,
}

type ModalAction = 'complete' | 'hide' | 'restore'

type ProjectType = 'active' | 'archived' | 'hidden'
export const TabTypes: Record<number, ProjectType> = {
    0: 'active',
    1: 'archived',
    2: 'hidden',
}

@Component({
    components: {
        EditorDropdown,
    },
    data() {
        return { TabTypes }
    },
})
export default class Dashboard extends Vue {
    tab: number = 0
    items: Array<ProjectInfo> = []
    options: Array<EditorInfo> = []
    savedProjectId: number = 0
    modalAction: ModalAction = 'complete'
    error: TranslateResult = ''
    statusesList: Object = {}
    dashboardTableHead = TableHeads.dashboard

    limit: number = 50

    projectOffsets: Record<ProjectType, number> = {
        active: 0,
        archived: 0,
        hidden: 0,
    }

    canGetProjects: Record<ProjectType, boolean> = {
        active: true,
        archived: true,
        hidden: true,
    }

    loading: Record<ProjectType, boolean> = {
        active: false,
        archived: false,
        hidden: false,
    }

    selectedEditorIndex: number | null = null
    oldSelectedEditorIndex: number | null = null

    toggleSecondMenu(index: number) {
        this.selectedEditorIndex = this.oldSelectedEditorIndex === index ? null : index
    }

    @Watch('selectedEditorIndex')
    onPropertyChanged(value: number | null) {
        this.oldSelectedEditorIndex = value
    }

    async getProjects(params: {
        offset: number,
        limit: number,
        active?: boolean,
        archived?: boolean,
        hidden?: boolean,
    }): Promise<ProjectInfo[]> {
        await this.getEditors()
        try {
            const response = await API.GetProjects(
                params.offset,
                params.limit,
                params.active,
                params.archived,
                params.hidden,
            )
            return response.data.map(info => ({
                projectId: info.id,
                timer: remainingTime({
                    status: info.status.toString(),
                    pausedAt: info.pausedAt,
                    latestDate: info.latestSubmittedAt || info.createdAt,
                    pausedMinutes: info.totalPausedMinutes,
                }),
                projectTitle: info.name,
                organization: info.organization.name,
                organizationId: info.organization.id,
                status: info.status,
                creationDate: info.createdAt.slice(0, 10).replace(/-/g, '.'),
                version: info.noReviews === null ? 0 : info.noReviews,
                feedbackLink: info.reviewLink === null ? '' : info.reviewLink,
                editor: info.editor !== null ? [{
                    name: `${info.editor.firstName} ${info.editor.lastName && info.editor.lastName[0]}`,
                    profilePicture: info.editor.profilePictureUrl === null
                        ? null
                        : info.editor.profilePictureUrl,
                    id: info.editor.id,
                }, ...this.createEditorList(info.editor.id)] : this.options,
                hasEditor: info.editor !== null,
                randomId: Math.random(),
                random: Math.random(),
                pausedAt: info.pausedAt,
                latestSubmittedAt: info.latestSubmittedAt,
                createdAt: info.createdAt,
                totalPausedMinutes: info.totalPausedMinutes,
            }))
        } catch (error) {
            console.error(error)
            Sentry.captureException(error)
            return []
        }
    }

    async getProjectsOfType(type: ProjectType) {
        this.loading[type] = true
        const projects = await this.getProjects({
            [type]: true,
            offset: this.projectOffsets[type],
            limit: this.limit,
        })

        this.canGetProjects[type] = projects.length === this.limit
        this.items = _.uniqBy([...this.items, ...projects], 'projectId')
        this.items.forEach((item, index) => {
            this.calculateTime(index, item.status.toString(), item.pausedAt,
                item.latestSubmittedAt || item.createdAt, item.totalPausedMinutes)
        })

        this.loading[type] = false
    }

    getActiveProjects() { this.getProjectsOfType('active') }
    getArchivedProjects() { this.getProjectsOfType('archived') }
    getHiddenProjects() { this.getProjectsOfType('hidden') }

    async getEditors() {
        try {
            const response = await API.GetSpecifiedUsers('editor')
            this.options = response.data.map(info => ({
                name: `${info.firstName} ${info.lastName[0]}.`,
                profilePicture: info.profilePictureUrl === null
                    ? null
                    : info.profilePictureUrl,
                id: info.id,
            }))
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    calculateTime(index: number, status: string, pausedAt: string, latestDate: string,
        pausedMinutes: number) {
        this.items[index].timer = remainingTime({
            status,
            pausedAt,
            latestDate,
            pausedMinutes,
        })
        if (status === 'WAITING_FOR_EDITOR' || status === 'IN_EDITING') {
            window.setTimeout(() => {
                this.calculateTime(index, status, pausedAt, latestDate, pausedMinutes)
            }, 60000)
        }
    }

    async archiveProject() {
        try {
            const response = await API.ArchiveProject(this.savedProjectId)
            if (response.status === 200) {
                const project = this.items
                    .find(element => element.projectId === this.savedProjectId)
                if (project) {
                    project.status = 'ARCHIVED'
                }
            }
        } catch (error) {
            const axiosErr = error as AxiosError
            if (axiosErr.response && axiosErr.response.status === 403) {
                this.error = this.$t('general.errors.unapprovedProject')
            } else {
                Sentry.captureException(error)
                this.error = this.$t('general.errors.server')
            }
        }
    }

    async hideProject() {
        try {
            const response = await API.HideProject(this.savedProjectId)
            if (response.status === 200) {
                const project = this.items
                    .find(element => element.projectId === this.savedProjectId)
                if (project) {
                    project.status = 'HIDDEN'
                }
            }
        } catch (error) {
            Sentry.captureException(error)
            this.error = this.$t('general.errors.server')
        }
    }

    async restoreProject() {
        try {
            const response = await API.RestoreProject(this.savedProjectId)
            if (response.status === 200) {
                const project = this.items
                    .find(element => element.projectId === this.savedProjectId)
                if (project) {
                    project.status = 'ARCHIVED'
                }
            }
        } catch (error) {
            Sentry.captureException(error)
            this.error = this.$t('general.errors.server')
        }
    }

    async handleModalConfirmation() {
        switch (this.modalAction) {
        case 'complete':
            await this.archiveProject()
            break
        case 'hide':
            await this.hideProject()
            break
        case 'restore':
            await this.restoreProject()
            break
        default:
            throw new Error(`invalid modalAction: ${this.modalAction}`)
        }
        this.hideModal()
    }

    showChangeStatusModal(projectId: number, to: ModalAction) {
        this.$root.$emit('bv::show::modal', 'confirm-modal', '#btnShow')
        this.error = ''
        this.savedProjectId = projectId
        this.modalAction = to
    }

    hideModal() {
        this.$root.$emit('bv::hide::modal', 'confirm-modal', '#btnHide')
    }

    createEditorList(editorId: number) {
        const modifiedEditorList: Array<EditorInfo> = []
        this.options.forEach((info) => {
            if (editorId !== info.id) {
                modifiedEditorList.push({
                    name: info.name,
                    profilePicture: info.profilePicture,
                    id: info.id,
                })
            }
        })
        return modifiedEditorList
    }

    async setEditor(info) {
        try {
            await API.SetEditor(info.projectId, info.editorInfo.id)
            const item = this.items.find(elem => elem.projectId === info.projectId)
            if (item) {
                const index = this.items.indexOf(item)
                if (item.status === 'WAITING_FOR_EDITOR') {
                    item.status = 'IN_EDITING'
                }
                item.hasEditor = true
                item.editor = [{
                    name: info.editorInfo.name,
                    profilePicture: info.editorInfo.profilePicture === null
                        ? null
                        : info.editorInfo.profilePicture,
                    id: info.editorInfo.id,
                }, ...this.createEditorList(info.editorInfo.id)]
                item.random = Math.random()
                this.$set(this.items, index, item)
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    getMoreActiveProjects() {
        this.projectOffsets.active += this.limit
        this.getActiveProjects()
    }

    getMoreArchivedProjects() {
        this.projectOffsets.archived += this.limit
        this.getArchivedProjects()
    }

    getMoreHiddenProjects() {
        this.projectOffsets.hidden += this.limit
        this.getHiddenProjects()
    }

    scrollEventListener() {
        if (this.$refs.scrollTarget !== undefined) {
            const rect = (this.$refs.scrollTarget as HTMLDivElement).getBoundingClientRect()
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight

            if (inView) {
                if (this.tab === 0) {
                    if (this.canGetProjects.active) this.getMoreActiveProjects()
                } else if (this.tab === 1) {
                    if (this.canGetProjects.archived) this.getMoreArchivedProjects()
                } else if (this.canGetProjects.hidden) {
                    this.getMoreHiddenProjects()
                }
            }
        }
    }

    debounceScrollEventListener = _.debounce(this.scrollEventListener, 500)

    mounted() {
        this.getActiveProjects()
        this.getArchivedProjects()
        this.getHiddenProjects()
        this.statusesList = {
            OPENED: this.$t('pages.project.status.opened'),
            WAITING_FOR_EDITOR: this.$t('pages.project.status.waitingForEditor'),
            IN_EDITING: this.$t('pages.project.status.inEditing'),
            IN_REVIEW: this.$t('pages.project.status.inReview'),
            PAUSED: this.$t('pages.project.status.paused'),
            DELETED: this.$t('pages.project.status.deleted'),
            APPROVED: this.$t('pages.project.status.approved'),
            ARCHIVED: this.$t('pages.project.status.archived'),
            HIDDEN: this.$t('pages.project.status.hidden'),
        }
        window.addEventListener('scroll', this.debounceScrollEventListener)
    }

    destroyed() {
        window.removeEventListener('scroll', this.debounceScrollEventListener)
    }

    sortTable(object: Array<ProjectInfo>, element: string, index: number) {
        this.$nextTick(() => {
            if (this.dashboardTableHead[index].sortingDirection === 'up') {
                this.items = object.sort((first: any, second: any) => (
                    first[element].toString().toLowerCase()
                    > second[element].toString().toLowerCase() ? 1 : -1))
                this.dashboardTableHead[index].sortingDirection = 'down'
            } else {
                this.items = object.sort((first: any, second: any) => (
                    first[element].toString().toLowerCase()
                    < second[element].toString().toLowerCase() ? 1 : -1))
                this.dashboardTableHead[index].sortingDirection = 'up'
            }
        })
    }

    saveInfoTopBar(title: string) {
        this.$store.commit('saveInfoTopBar', title)
    }
}
