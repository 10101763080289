
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ProjectDescription extends Vue {
    description: string = ''
    authorName: string = ''
    descriptionError: TranslateResult | string | boolean = ''
    isTitleShown: boolean = false
    isFormShown: boolean = false
    isButtonDisabled: boolean = false

    checkDescriptionInput() {
        if (this.description.length < 1) {
            this.descriptionError = this.$t('errors.rules.required.projectBrief')
        } else {
            this.descriptionError = ''
        }
    }

    cancel() {
        this.$emit('cancel')
    }

    goNextStep() {
        this.checkDescriptionInput()
        if (!this.descriptionError) {
            this.isButtonDisabled = true
            this.$emit('saveProjectDescription', this.description)
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        setTimeout(() => {
            this.isTitleShown = true
        }, 500)
        setTimeout(() => {
            this.isFormShown = true
        }, 1000)
    }
}
