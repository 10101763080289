
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import { TranslateResult } from 'vue-i18n'
import Editor from './Editor.vue'
import rules from '@/utils/rules'
import API from '@/api/index'

interface EditorInfo {
    id: number,
    profilePicture: string,
    name: string,
    editorDrive: string,
    email: string,
    createdAt: string,
    phone: string,
    isEnabled: 0 | 1,
    deletedAt: string | null,
}

@Component({
    components: {
        Editor,
    },
})
export default class Editors extends Vue {
    firstNameRules = rules.firstNameRules
    lastNameRules = rules.lastNameRules
    emailRules = rules.emailRules
    email: string = ''
    firstName: string = ''
    lastName: string = ''
    phoneNumber: string = ''
    isModalShow: boolean = false
    isActionModalShow: boolean = false
    uploadedImage: any = null
    editorsInfo: Array<EditorInfo> = []
    editorAction: 'delete' | 'disable' | 'enable' = 'delete'
    error: TranslateResult = ''
    isEditingInfo: boolean = false
    isButtonDisabled: boolean = false
    selectedEditorIndex: number | null = null
    oldSelectedEditorIndex: number | null = null
    limitNumberOfUsers: number = 50
    startNumberOfUsers: number = 0
    canGetUsers: boolean = false
    isGetUsersDisabled: boolean = false
    isEmailUsed: boolean = false
    savedEditor: EditorInfo = {
        id: 0,
        profilePicture: '',
        name: '',
        editorDrive: '',
        email: '',
        createdAt: '',
        phone: '',
        isEnabled: 1,
        deletedAt: null,
    }

    getMoreEditors() {
        this.isGetUsersDisabled = true
        this.startNumberOfUsers = this.startNumberOfUsers + this.limitNumberOfUsers
        this.getEditors()
        this.isGetUsersDisabled = false
    }

    async getEditors() {
        try {
            const response = await API.GetSpecifiedUsers(
                'editor', this.startNumberOfUsers, this.limitNumberOfUsers,
            )
            const editors = [
                ...this.filterEditors(response, 1),
                ...this.filterEditors(response, 0),
            ]
            this.editorsInfo = [...this.editorsInfo, ...editors]
            this.canGetUsers = editors.length === this.limitNumberOfUsers
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    toggleEditorModal(info?) {
        this.isModalShow = !this.isModalShow
        if (info) {
            this.savedEditor = info.info
            this.isEditingInfo = true
            this.email = this.savedEditor.email
            this.firstName = this.savedEditor.name.split(' ')[0]
            this.lastName = this.savedEditor.name.split(' ')[1]
            this.uploadedImage = this.savedEditor.profilePicture
            this.phoneNumber = this.savedEditor.phone
        } else {
            this.resetEditorInfo()
        }
    }

    filterEditors(response, editorState) {
        return response.data
            .filter(filteredEditors => filteredEditors.isEnabled === editorState)
            .map(info => ({
                id: info.id,
                profilePicture: info.profilePictureUrl,
                name: `${info.firstName} ${info.lastName}`,
                editorDrive: info.assetsPath,
                email: info.email,
                createdAt: info.role.createdAt.split('T')[0],
                phone: info.phone,
                isEnabled: info.isEnabled,
                deletedAt: info.deletedAt,
            }))
    }

    onChange(picture) {
        const tmp = this
        const resizeWidth = 200
        const file = picture.target.files || picture.dataTransfer.files
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.onload = (event) => {
            const img = new Image()
            // @ts-ignore: Unreachable code error
            img.src = event.target.result
            img.onload = function(el) {
                const elem = document.createElement('canvas')
                // @ts-ignore: Unreachable code error
                const scaleFactor = resizeWidth / el.target.width
                elem.width = resizeWidth
                // @ts-ignore: Unreachable code error
                elem.height = el.target.height * scaleFactor
                const ctx = elem.getContext('2d')
                // @ts-ignore: Unreachable code error
                ctx.drawImage(el.target, 0, 0, elem.width, elem.height)
                // @ts-ignore: Unreachable code error
                tmp.uploadedImage = ctx.canvas.toDataURL(el.target, 'image/jpeg')
            }
        }
    }

    toggleActionModal() {
        this.isActionModalShow = !this.isActionModalShow
    }

    openActionModal(info) {
        this.error = ''
        this.savedEditor = info.info
        this.editorAction = info.action
        this.toggleActionModal()
    }

    async editorModalAction() {
        if (this.editorAction === 'disable' || this.editorAction === 'enable') {
            let info: {
                isEnabled: 0 | 1
            }
            if (this.editorAction === 'disable') {
                info = { isEnabled: 0 }
            } else {
                info = { isEnabled: 1 }
            }
            try {
                const response = await API.EditUserInfo(this.savedEditor.id, info)
                if (response.status === 200) {
                    this.toggleActionModal()
                    this.editorsInfo.forEach((editorInfo) => {
                        if (this.savedEditor.id === editorInfo.id) {
                            editorInfo.isEnabled = info.isEnabled
                        }
                        return true
                    })
                }
            } catch (error) {
                Sentry.captureException(error)
                this.error = this.$t('general.errors.server')
            }
        } else {
            try {
                const response = await API.DeleteUser(this.savedEditor.id)
                if (response.status === 204) {
                    this.toggleActionModal()
                    this.editorsInfo.forEach((info) => {
                        if (info.id === this.savedEditor.id) {
                            info.deletedAt = 'deleted'
                        }
                        return true
                    })
                }
            } catch (error) {
                Sentry.captureException(error)
                if (JSON.stringify(error).includes('403')) {
                    this.error = this.$t('general.errors.deleteEditorWithProject')
                } else {
                    this.error = this.$t('general.errors.server')
                }
            }
        }
    }

    async addEditor() {
        if ((this.$refs.addEditor as Vue & { validate: () => boolean }).validate()) {
            this.isButtonDisabled = true
            const info = {
                email: this.email.trim(),
                firstName: this.firstName,
                lastName: this.lastName,
                profilePictureUrl: this.uploadedImage,
                phone: this.phoneNumber,
            }

            try {
                if (!this.isEditingInfo) {
                    const res = await API.AddUser({
                        ...info,
                        role: 'Editor',
                    })
                    if (res) {
                        this.editorsInfo = []
                        this.startNumberOfUsers = 0
                        this.getEditors()
                    }
                } else {
                    await API.EditUserInfo(this.savedEditor.id, info)
                    this.editorsInfo.forEach((editorInfo) => {
                        if (this.savedEditor.id === editorInfo.id) {
                            editorInfo.name = `${info.firstName} ${info.lastName}`
                            editorInfo.email = info.email
                            editorInfo.phone = info.phone
                            editorInfo.profilePicture = info.profilePictureUrl
                        }
                        return true
                    })
                }
                this.toggleEditorModal()
                this.resetEditorInfo()
                this.isButtonDisabled = false
            } catch (error) {
                Sentry.captureException(error)
                this.isEmailUsed = true
                this.isButtonDisabled = false
            }
        }
    }

    resetEditorInfo() {
        this.email = ''
        this.firstName = ''
        this.lastName = ''
        this.uploadedImage = ''
        this.phoneNumber = ''
    }

    toggleSecondMenu(index) {
        this.selectedEditorIndex = this.oldSelectedEditorIndex === index ? null : index
    }

    @Watch('selectedEditorIndex')
    onPropertyChanged(value: number | null, oldValue: number | null) {
        this.oldSelectedEditorIndex = value
    }

    hideOptions() {
        if (this.selectedEditorIndex !== null) {
            this.toggleSecondMenu(this.selectedEditorIndex)
        }
    }

    mounted() {
        this.getEditors()
    }
}
