
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import _ from 'lodash'

interface PlayerOptions {
    muted: boolean,
    language: string,
    sources: Array<VideoSettings>,
    poster: string,
    playbackRates: Array<number>,
    width?: number;
    height?: number;
}

interface VideoSettings {
    type: string,
    src: string,
}

@Component({
    components: {
        videoPlayer,
    },
})
export default class VideoPlayer extends Vue {
    static DEFAULT_WIDTH = 884
    static DEFAULT_HEIGHT = 494

    fullscreen: boolean = false
    hasAutoplay: boolean = false
    playerOptions: PlayerOptions = {
        muted: true,
        language: 'en',
        sources: [{
            type: 'video/mp4',
            src: '',
        }],
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        poster: '',
        width: 884,
        height: 494,
    }

    @Watch('posterSource')
    isPosterSourceChanged(value?: string, oldValue?: string) {
        if (value !== oldValue) {
            this.playerOptions.poster = this.posterSource || ''
            this.playerOptions.muted = this.mutedState || false
            this.playerOptions.sources[0].src = this.videoSource || ''
            this.hasAutoplay = this.autoplayState || false
            this.playerOptions.width = this.width || VideoPlayer.DEFAULT_WIDTH
            this.playerOptions.height = this.height || VideoPlayer.DEFAULT_HEIGHT
        }
    }

    @Watch('width')
    isWidthChanged(val: number) {
        if (!this.fullscreen) {
            this.playerOptions.width = val || VideoPlayer.DEFAULT_WIDTH
        }
    }

    @Watch('height')
    isHeightChanged(val: number) {
        if (!this.fullscreen) {
            this.playerOptions.height = val || VideoPlayer.DEFAULT_HEIGHT
        }
    }

    @Prop() readonly posterSource?: string
    @Prop() readonly videoSource?: string
    @Prop() readonly width?: number
    @Prop() readonly height?: number
    @Prop() readonly mutedState?: boolean
    @Prop() readonly autoplayState?: boolean

    onFullscreenChange() {
        this.fullscreen = document.fullscreenElement !== null
    }

    mounted() {
        this.playerOptions.poster = this.posterSource || ''
        this.playerOptions.muted = this.mutedState || false
        this.playerOptions.sources[0].src = this.videoSource || ''
        this.hasAutoplay = this.autoplayState || false
        this.playerOptions.width = this.width || VideoPlayer.DEFAULT_WIDTH
        this.playerOptions.height = this.height || VideoPlayer.DEFAULT_HEIGHT
        window.addEventListener('fullscreenchange', this.onFullscreenChange)
    }

    destroyed() {
        window.removeEventListener('fullscreenchange', this.onFullscreenChange)
    }
}
