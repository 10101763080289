
import { Component, Vue, Watch } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'
import PasswordInput from '@/components/Access/Password.vue'

@Component({
    components: {
        PasswordInput,
    },
})
export default class Login extends Vue {
    email: string = ''
    password: string = ''
    authorName: string = ''
    emailState: string = 'unverified' || 'verified' || 'saved'
    isLoginError: boolean = false
    inputError: TranslateResult | string | boolean = ''
    emailRules = rules.isEmail
    passwordRules = rules.isPassword
    isLogginIn: boolean = false

    async checkEmail() {
        if (this.emailState === 'saved') {
            this.checkPasswordInput()
            if (this.inputError === '') {
                try {
                    this.isLogginIn = true
                    const response = await API.Login(this.email.trim(), this.password)
                    if (response.status === 200) {
                        this.$store.commit('accessToken', response.data.token)
                        this.$store.commit('organizationId', response.data.organizationId)
                        try {
                            const responseMe = await API.PersonalInfo()
                            const info = {
                                role: responseMe.data.role.name,
                                name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                                orgName: responseMe.data.organizationName,
                                id: responseMe.data.id,
                            }
                            this.$store.commit('setUserInfo', info)
                            if (this.$store.getters.hasAccessToAdminPortal) {
                                this.$router.push('/dashboard')
                            } else {
                                this.$router.push('/user/projects')
                            }
                        } catch (error) {
                            this.isLogginIn = false
                            Sentry.captureException(error)
                        }
                    }
                } catch (error) {
                    Sentry.captureException(error)
                    this.isLogginIn = false
                    this.isLoginError = true
                }
            }
        } else {
            this.checkEmailInput()
            if (this.inputError === '') {
                this.emailState = 'verified'
                setTimeout(() => {
                    (this.$refs.password as any).$refs.input.focus()
                }, 1100)
            }
        }
    }

    checkEmailInput() {
        const checkEmailInput = this.emailRules(this.email)
        if (this.email.length < 1) {
            this.inputError = this.$t('errors.rules.required.email')
        } else if (this.email.length > 99) {
            this.inputError = this.$t('errors.rules.validated.emailMax')
        } else if (checkEmailInput !== true) {
            this.inputError = checkEmailInput
        } else {
            this.inputError = ''
        }
    }

    checkPasswordInput() {
        const checkPasswordInput = this.passwordRules(this.password)
        if (this.password.length < 1) {
            this.inputError = this.$t('errors.rules.required.password')
        } else if (checkPasswordInput !== true) {
            this.inputError = checkPasswordInput
        } else {
            this.inputError = ''
        }
    }

    @Watch('emailState')
    onPropertyChanged(value: string) {
        if (value === 'verified') {
            setTimeout(() => {
                this.emailState = 'saved'
            }, 1000)
        }
    }

    goToForgotPassword() {
        this.$router.push('/recover-password')
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
    }
}
