import AWS from 'aws-sdk'

AWS.config.update({
    region: String(process.env.VUE_APP_AWS_REGION),
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: String(process.env.VUE_APP_AWS_IDENTITY_POOL),
    }),
})
const s3 = new AWS.S3()

const checkIfFileExists = async(fileUrl: string): Promise<boolean> => {
    try {
        const url = new URL(fileUrl)
        const urlLink = url.hostname + decodeURIComponent(url.pathname)

        const pathParts = urlLink.split('/')
        pathParts.shift()

        await s3.headObject({
            Key: pathParts.join('/'),
            Bucket: process.env.VUE_APP_AWS_BUCKET_NAME || '',
        }).promise()
        return true
    } catch (e) {
        return false
    }
}

export default checkIfFileExists
