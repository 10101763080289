
import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import TopMenu from '@/components/Organization/TopMenu.vue'
import Footer from '@/components/Organization/Footer.vue'
import Brief from './Brief.vue'
import { remainingTime, reviewDaysLeft } from '@/utils/project'
import checkIfFileExists from '@/utils/aws'
import img from '@/utils/imgDimensions'
import Assets from './Assets.vue'
import ConfirmSendToProduction from '@/components/Organization/Modals/ConfirmSendToProduction.vue'
import ConfirmApproveModal from '@/components/Organization/Modals/ConfirmApproveModal.vue'
import DownloadFinalModal from '@/components/Organization/Modals/DownloadFinalModal.vue'
import NoMedia from '@/components/Organization/Modals/NoMedia.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import PendingUploads from './PendingUploads.vue'

interface ProjectInfo {
    name: string,
    status: string,
    organizationId: number,
    userId: number,
    timer: string,
    backgroundType: 'background1' | 'background2'
}

@Component({
    components: {
        TopMenu,
        Brief,
        Assets,
        ConfirmSendToProduction,
        ConfirmApproveModal,
        DownloadFinalModal,
        Footer,
        PendingUploads,
        NoMedia,
        VideoPlayer,
    },
})
export default class Project extends Vue {
    authorName = ''
    isCheckingFields = false
    isRequestChanges = false
    isTimerInDays = false
    hasBriefErrors: undefined | boolean = undefined
    hasMediaErrors: undefined | boolean = undefined
    checkingCount = 0

    collabLink = ''
    collabLinkCopied = false
    reviewLink = ''
    reviewLinkCopied = false

    presentationVideo: string | undefined = ''
    presentationVideoThumbnail: string | undefined = ''
    isDownloadable = false
    downloadLink = ''
    finalVideoPreview = ''
    finalVideoThumbnail = ''
    videoSource = ''
    videoSourceWidth = 0
    videoSourceHeight = 0
    playerWidth = 0
    playerHeight = 0
    posterSource = ''

    videoNotReady = false
    retrySetVideoTimeout: NodeJS.Timeout | null = null

    isDownloadModalShown = false
    showNoMediaModal = false
    isApproveModalShow = false

    showSendToEditingModal = false
    sendToEditingWarning = ''

    info: ProjectInfo = {
        name: '',
        status: '',
        organizationId: 0,
        userId: 0,
        timer: '',
        backgroundType: 'background1',
    }

    updateTimerTimeout: NodeJS.Timeout | null = null

    toggleModalDownload() {
        this.isDownloadModalShown = !this.isDownloadModalShown
    }

    setTimerInfo(pausedAt: string, reviewPeriod: number) {
        if (this.info.status === 'in_review') {
            if (reviewPeriod === 0) {
                this.info.timer = ''
            } else {
                const daysLeft = reviewDaysLeft(pausedAt, reviewPeriod)
                this.info.timer = this.$tc('pages.projectOrganization.daysLeft',
                    daysLeft).toString()
            }
            this.isTimerInDays = true
        } else {
            this.isTimerInDays = false
        }
    }

    async setVideoInfo() {
        if (this.info.status === 'waiting_for_editor' || this.info.status === 'in_editing') {
            this.videoSource = this.presentationVideo || ''
            this.posterSource = this.presentationVideoThumbnail || ''
        } else if (this.info.status === 'in_review' || this.info.status === 'approved'
            || this.info.status === 'archived') {
            this.videoSource = this.finalVideoPreview
            this.posterSource = this.finalVideoThumbnail

            const isReady = await checkIfFileExists(this.videoSource)
            this.videoNotReady = !isReady
        }

        if (this.videoSource) {
            const dimensions = await img.calculatePreviewVideoDimensions(this.posterSource)
            this.videoSourceWidth = dimensions.width
            this.videoSourceHeight = dimensions.height

            this.adjustPlayerDimension()
        }

        if (this.videoNotReady) {
            this.retrySetVideoTimeout = setTimeout(() => { this.setVideoInfo() }, 5000)
        }
    }

    adjustPlayerDimension() {
        const parentWidthRatio = 1
        const parentHeightRatio = 0.8

        const srcWidth = this.videoSourceWidth || 884
        const srcHeight = this.videoSourceHeight || 494
        const ar = srcWidth / srcHeight

        const parent = (this.$refs.videoPlayerWrapper as HTMLDivElement).getBoundingClientRect()
        let targetWidth = parentWidthRatio * parent.width
        let targetHeight = parentHeightRatio * parent.height
        const maxHeight = 720
        const maxWidth = maxHeight * ar
        const minHeight = 320
        const minWidth = minHeight * ar

        if (srcHeight > srcWidth) {
            if (targetHeight > maxHeight) targetHeight = maxHeight
            else if (targetHeight < minHeight) targetHeight = minHeight
            targetWidth = targetHeight * ar

            if (targetWidth > parentWidthRatio * parent.width) {
                targetWidth = parentWidthRatio * parent.width
                targetHeight = targetWidth / ar
            }
        } else {
            if (targetWidth > maxWidth) targetWidth = maxWidth
            else if (targetWidth < minWidth) targetWidth = minWidth
            targetHeight = targetWidth / ar

            if (targetHeight > parentHeightRatio * parent.height) {
                targetHeight = parentHeightRatio * parent.height
                targetWidth = targetHeight * ar
            }
        }

        this.playerWidth = targetWidth
        this.playerHeight = targetHeight
    }

    async getProjectInfo() {
        try {
            const response = await API.GetProject(parseInt(this.$route.params.projectId, 10))
            const info = response.data
            this.info = {
                name: info.name,
                status: info.status.toLowerCase(),
                organizationId: info.organization.id,
                userId: info.user.id,
                timer: '00:00',
                backgroundType: this.setBackground(info.status.toLowerCase()),
            }
            this.calculateTime(info.status, info.pausedAt,
                info.latestSubmittedAt || info.createdAt, info.totalPausedMinutes, true)
            this.downloadLink = response.data.finalVideoPath
            this.isDownloadable = !!response.data.finalVideoPath
            this.finalVideoPreview = response.data.finalVideoPreview
            this.finalVideoThumbnail = response.data.finalVideoThumbnail
            this.reviewLink = response.data.reviewLink
            this.collabLink = response.data.collaborationLink

            this.setTimerInfo(response.data.pausedAt, response.data.reviewPeriod)
            await this.setVideoInfo()
        } catch (error) {
            console.error(error)
            Sentry.captureException(error)
        }
    }

    checkFieldsForErrors() {
        this.isCheckingFields = true
        setTimeout(() => {
            this.isCheckingFields = false
        }, 2000)
    }

    closeNoMediaModal() {
        this.showNoMediaModal = false
    }

    checkMedia(errors: boolean, warning: string) {
        this.hasMediaErrors = errors
        this.checkingCount = this.checkingCount + 1
        if (this.hasMediaErrors !== undefined && this.hasBriefErrors !== undefined
            && this.checkingCount === 2) {
            this.sendToEditingWarning = warning
            this.sendToEditing()
        }
    }

    calculateTime(status: string, pausedAt: string, latestDate: string,
        pausedMinutes: number, areSecondsShown: boolean) {
        this.info.timer = remainingTime({
            status,
            pausedAt,
            latestDate,
            pausedMinutes,
            areSecondsShown,
        })
        if (status === 'WAITING_FOR_EDITOR' || status === 'IN_EDITING') {
            this.updateTimerTimeout = setTimeout(() => {
                this.calculateTime(status, pausedAt, latestDate, pausedMinutes, areSecondsShown)
            }, 1000)
        }
    }

    checkBrief(hasBriefErrors: boolean) {
        this.hasBriefErrors = hasBriefErrors
        this.checkingCount = this.checkingCount + 1
        if (this.hasMediaErrors !== undefined && this.hasBriefErrors !== undefined
            && this.checkingCount === 2) {
            this.sendToEditing()
        }
    }

    async copyReviewLink() {
        this.reviewLinkCopied = true
        await navigator.clipboard.writeText(this.reviewLink)
        setTimeout(() => this.reviewLinkCopied = false, 3000)
    }

    async copyCollabLink() {
        this.collabLinkCopied = true
        await navigator.clipboard.writeText(this.collabLink)
        setTimeout(() => this.collabLinkCopied = false, 3000)
    }

    sendToEditing() {
        this.checkingCount = 0
        if (!this.hasMediaErrors && !this.hasBriefErrors) {
            this.showSendToEditingModal = true
        }
    }

    actionChanges() {
        if (!this.isRequestChanges) {
            this.isRequestChanges = true
        } else {
            this.submitProject()
        }
    }

    async submitProject() {
        try {
            await API.SubmitProject(Number(this.$route.params.projectId))
            this.getProjectInfo()
            this.closeModalConfirmation()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    approveProjectConfirm() {
        this.isApproveModalShow = true
    }

    async approveProject() {
        try {
            await API.ApproveProject(Number(this.$route.params.projectId))
            this.getProjectInfo()
            this.isApproveModalShow = false
        } catch (error) {
            Sentry.captureException(error)
            this.isApproveModalShow = false
        }
    }

    closeModalConfirmation() {
        this.showSendToEditingModal = false
        this.isApproveModalShow = false
        this.isCheckingFields = false
    }

    toggleConfirmationModal() {
        this.showSendToEditingModal = !this.showSendToEditingModal
    }

    resizeHandler() {
        this.adjustPlayerDimension()
    }

    dbResizeHandler = _.debounce(this.resizeHandler, 100)

    mounted() {
        this.getProjectInfo()
        this.presentationVideo = process.env.VUE_APP_PRESENTATION_VIDEO
        this.presentationVideoThumbnail = process.env.VUE_APP_COVER_PRESENTATION_VIDEO
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        window.addEventListener('resize', this.dbResizeHandler)
    }

    destroyed() {
        window.removeEventListener('resize', this.dbResizeHandler)
        if (this.retrySetVideoTimeout) clearTimeout(this.retrySetVideoTimeout)
        if (this.updateTimerTimeout) clearTimeout(this.updateTimerTimeout)
    }

    setBackground(infoStatus: string) {
        let background: 'background1' | 'background2' | '' = ''
        if (infoStatus === 'opened' || infoStatus === 'waiting_for_editor' || infoStatus === 'in_editing') {
            background = 'background1'
        } else {
            background = 'background2'
        }
        return background
    }
}
