
import { Component, Vue } from 'vue-property-decorator'
import footerSubmenu from '@/utils/footerSubmenu'

interface FooterElement {
    name: string,
    path: string,
}

@Component
export default class Footer extends Vue {
    submenu: Array<FooterElement> = []
    isSocialShow: boolean = false

    mounted() {
        this.submenu = footerSubmenu
    }
}

