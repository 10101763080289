
import { Component, Vue, Watch } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

interface UploadingFiles {
    name: 'Interview Clips' | 'B-Roll Clips' | 'Assets',
    type: 'InterviewClip' | 'BRollClip' | 'Asset',
    files: Array<FileInfo>,
}

interface FileInfo {
    name: string,
    fileType: string,
    type?: string,
    status: 'uploading' | 'done' | 'queued' | 'incomplete',
}

interface UploadingInfo {
    InterviewClip: UploadingFiles,
    BRollClip: UploadingFiles,
    Asset: UploadingFiles,
}

@Component({
    name: 'PendingUploads',
})
export default class PendingUploads extends Vue {
    numberOfFiles: number = 0
    remainingFiles: number = 0
    isExpanded: boolean = false
    lastPendingUid: number = 0
    loadingValue: number = 0
    totalParts: number = 0
    remainingParts: number = 0
    isPendingPopupShow: boolean = false
    uploadTitle: TranslateResult = ''
    uploadInfo: UploadingInfo = {
        InterviewClip: {
            name: 'Interview Clips',
            type: 'InterviewClip',
            files: [],
        },
        BRollClip: {
            name: 'B-Roll Clips',
            type: 'BRollClip',
            files: [],
        },
        Asset: {
            name: 'Assets',
            type: 'Asset',
            files: [],
        },
    }

    toggleInfo() {
        this.isExpanded = !this.isExpanded
    }

    changeProjectStatus(status: string) {
        const thisTmp = this
        const uploadingFile = this.uploadInfo[this.$store.getters.uploadingFileType].files.find(
            (file) => {
                return file.uid === thisTmp.$store.getters.uploadingFileUid
            },
        )
        uploadingFile.status = status
        if (status === 'done') {
            this.remainingFiles++
        }
    }

    getIncompleteFiles() {
        const files = this.$store.getters.getProjects(Number(this.$route.params.projectId))
        if (files.length > 0) {
            this.isPendingPopupShow = true
            this.uploadTitle = this.$t('pages.projectOrganization.pending.waitingForUpload')
        }
        files.forEach((file) => {
            this.uploadInfo[file.type].files.push({
                name: file.initialName,
                uid: 0,
                type: file.type,
                status: 'incomplete',
            })
        })
    }

    checkIfFileExists(name: string, type: string, uid: number) {
        const file = this.uploadInfo[type].files.find((elem) => {
            return elem.name === name && elem.status === 'incomplete'
        })
        if (file) {
            file.uid = uid
            file.status = 'queued'
            return true
        }
        return false
    }

    @Watch('$store.state.files.projects')
    onProjectChange(projects: any) {
        this.uploadInfo.InterviewClip.files = this.uploadInfo.InterviewClip
            .files.filter(elem => elem.status !== 'incomplete'
            || projects.find(item => item.name === elem.name && item.type === elem.type
            && item.projectId === Number(this.$route.params.projectId)))
        this.uploadInfo.BRollClip.files = this.uploadInfo.BRollClip
            .files.filter(elem => elem.status !== 'incomplete'
            || projects.find(item => item.name === elem.name && item.type === elem.type
            && item.projectId === Number(this.$route.params.projectId)))
        this.uploadInfo.Asset.files = this.uploadInfo.Asset
            .files.filter(elem => elem.status !== 'incomplete'
            || projects.find(item => item.name === elem.name && item.type === elem.type
            && item.projectId === Number(this.$route.params.projectId)))
    }

    @Watch('$store.getters.totalParts')
    onPropertyChanged(totalParts: number) {
        this.totalParts = totalParts
    }

    @Watch('$store.getters.remainingParts')
    onPropertyChange(remainingParts: number) {
        this.loadingValue = (this.totalParts - remainingParts) / this.totalParts * 100
    }

    @Watch('$store.getters.getQueue')
    onQueueChange(files: any) {
        if (files.length > 0) {
            this.isPendingPopupShow = true
            const filesFromLastPendingUid = files.filter(
                file => file.file.uid > this.lastPendingUid
                    && file.projectId === Number(this.$route.params.projectId),
            )
            let categoryType: 'InterviewClip' | 'BRollClip' | 'Asset' | '' = ''
            if (filesFromLastPendingUid.length > 0) {
                categoryType = filesFromLastPendingUid[0].type
            } else {
                categoryType = files[0].type
            }
            const temporaryFilesInfo: Object[] = []
            filesFromLastPendingUid.forEach((info) => {
                this.lastPendingUid = info.file.uid
                this.numberOfFiles += 1
                const fileInfo = {
                    name: info.file.name,
                    uid: info.file.uid,
                    type: info.type,
                    status: 'queued',
                }
                if (!this.checkIfFileExists(info.file.name, categoryType, info.file.uid)) {
                    temporaryFilesInfo.push(fileInfo)
                }
            })
            if (this.numberOfFiles - this.remainingFiles === 1) {
                this.uploadTitle = this.$t('pages.projectOrganization.pending.uploadFile')
            } else if (this.numberOfFiles - this.remainingFiles > 1) {
                this.uploadTitle = this.$t('pages.projectOrganization.pending.uploadFiles',
                    { numberOfFiles: this.numberOfFiles - this.remainingFiles })
            }
            this.uploadInfo[categoryType].files = [
                ...this.uploadInfo[categoryType].files, ...temporaryFilesInfo,
            ]
        }
    }

    @Watch('remainingFiles')
    onNumberOfFilesChange(remainingFiles: number) {
        if (this.numberOfFiles - remainingFiles === 1) {
            this.uploadTitle = this.$t('pages.projectOrganization.pending.uploadFile')
        } else if (this.numberOfFiles - remainingFiles > 1) {
            this.uploadTitle = this.$t('pages.projectOrganization.pending.uploadFiles', { numberOfFiles: this.numberOfFiles - remainingFiles })
        } else {
            this.uploadTitle = this.$t('pages.projectOrganization.pending.uploadFinished')
        }
    }

    @Watch('$store.getters.isUploading')
    onValueChanged(value: boolean) {
        this.changeProjectStatus(value ? 'uploading' : 'done')
    }

    mounted() {
        this.getIncompleteFiles()
    }
}

