import i18n from '../language/en'

interface FooterElement {
    name: string;
    path: string;
}

const projects: FooterElement = {
    name: i18n.pages.footer.projects,
    path: `${window.location.origin}/user/projects`,
}

const pricing: FooterElement = {
    name: i18n.pages.footer.pricing,
    path: 'https://vidloft.com/pricing/',
}

const help: FooterElement = {
    name: i18n.pages.footer.help,
    path: 'https://vidloft.com/help/',
}

const privacy: FooterElement = {
    name: i18n.pages.footer.privacy,
    path: 'https://vidloft.com/privacy-policy/',
}

const footerSubmenu = [
    projects,
    pricing,
    help,
    privacy,
]

export default footerSubmenu
