
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

@Component
export default class DownloadFinalModal extends Vue {
    @Prop() readonly isDownloadModalShown!: boolean
    @Prop() readonly downloadLink!: string

    async deleteRawFiles() {
        try {
            await API.DeleteAssets(this.$route.params.projectId)
            this.toggleModalDownload()
            this.$emit('getProjectInfo')
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    toggleModalDownload() {
        this.$emit('toggleModalDownload')
    }
}
