
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import FormatBytesAsset from '@/utils/formatBytesAsset'

interface ProjectInfo {
    id: number,
    name: string,
    reviewLink: string,
    finalVideoLength: number,
    finalVideoSize: number,
    hdUrl?: string,
    lowResUrl?: string,
    finalVideoThumbnail?: string,
}

@Component
export default class Project extends Vue {
    error: TranslateResult = ''
    message: TranslateResult = ''
    thumbnail: string = ''

    @Prop() readonly projectInfo!: ProjectInfo
    @Prop() readonly isMenuShow!: boolean
    @Prop() readonly index!: number

    toggleProjectOptions() {
        this.$emit('toggleProjectOptions', this.index)
    }

    sizeFormat(size: number) {
        return FormatBytesAsset(size)
    }

    openActionModal(actionModal) {
        const savedProject = {
            id: this.projectInfo.id,
            name: this.projectInfo.name,
        }
        this.toggleProjectOptions()
        this.$emit('openActionModal', savedProject, actionModal)
    }

    mounted() {
        if (this.projectInfo.finalVideoThumbnail) {
            this.thumbnail = encodeURI(this.projectInfo.finalVideoThumbnail)
        }
    }
}

