
import { Component, Vue } from 'vue-property-decorator'
import { retry } from 'async'
import * as Sentry from '@sentry/browser'
import AWS from 'aws-sdk'
import API from '@/api'

AWS.config.update({
    region: String(process.env.VUE_APP_AWS_REGION),
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: String(process.env.VUE_APP_AWS_IDENTITY_POOL),
    }),
})
const s3 = new AWS.S3()
const maximumConcurrentUploads: number = Number(process.env.VUE_APP_CONCURENT_UPLOADS)

@Component
export default class FileUpload extends Vue {
    file: File | null = null
    sliceSize: number = Number(process.env.VUE_APP_SMALL_CHUNK_SIZE)
    multiPartName: string = ''
    parts: Array<any> = []
    uploadedCount: number = 0
    availablePromises: number = 0
    partsCount: number = 0
    nextSlice: number = 0
    fileName: string = ''
    initialName: string = ''
    remainingParts: Array<any> = []
    failedParts: Array<any> = []
    isReupload: boolean = false
    remainingPartsCount: number = 0
    progress: number = 0
    store: any = null
    type: string = ''
    projectId: number = 0
    bucketName: string = String(process.env.VUE_APP_AWS_BUCKET_NAME)

    resetValues() {
        this.file = null
        this.multiPartName = ''
        this.parts = []
        this.uploadedCount = 0
        this.availablePromises = 0
        this.partsCount = 0
        this.nextSlice = 0
        this.progress = 0
        this.remainingParts = []
    }

    async dummyUpload() {
        const file = new File(['dummy'], 'dummy.txt', {
            type: 'text/plain',
        })
        await s3.upload({
            Bucket: this.bucketName,
            Key: 'dummy.txt',
            Body: file,
        }).promise()
    }

    splitFile() {
        if (this.file) {
            this.partsCount = Math.ceil(this.file.size / this.sliceSize)
            this.store.commit('setNumberOfProjectParts', this.partsCount)
            this.store.commit('setNumberOfRemainingParts', this.partsCount)
            const promisesCount = Math.min(this.partsCount, maximumConcurrentUploads)
            const info = {
                initialName: this.initialName,
                name: this.fileName,
                size: this.file.size,
                lastDate: this.file.lastModified,
                projectId: this.projectId,
                type: this.type,
                multiPartName: this.multiPartName,
                parts: Array(this.partsCount).fill(0).map((e, i) => i + 1),
                uploadedParts: [],
            }
            this.store.commit('addNewProject', info)
            this.nextSlice = promisesCount + 1
            for (let i = 1; i <= promisesCount; i += 1) {
                this.uploadPart(i)
            }
        }
    }

    async uploadFile(localFile: File, store: any, type: string, projectId: number,
        organizationId: number = -1, uploadingFileUid: number) {
        this.store = store
        // added infoUpload to really know when a new upload is starting
        // by the uploadingFileUid
        const infoUpload = {
            isUploading: true,
            uploadingFileUid,
            uploadingFileType: type,
        }
        const dummyProjectInfo = {
            file: localFile,
            type,
            projectId,
        }
        this.store.commit('removeDummyProject', dummyProjectInfo)
        await new Promise(r => setTimeout(r, 1000))
        this.store.commit('setIsUploading', infoUpload)
        this.store.commit('setNumberOfProjectParts', 1)
        this.store.commit('setNumberOfRemainingParts', 1)
        this.type = type
        this.projectId = Number(projectId)
        const orgId = organizationId > -1 ? organizationId : this.store.getters.organizationId
        this.resetValues()
        this.file = localFile
        this.initialName = this.file.name
        this.fileName = this.file.name.replace(/[^a-zA-Z0-9-_.]/g, '')
        try {
            const response = await API.GetFileName(projectId, type, this.fileName)
            this.fileName = response.data.name
            this.bucketName += response.data.location
        } catch (err) {
            Sentry.captureException(err)
            return false
        }
        let existingProject: any = null
        if (this.store.getters.checkProject(this.fileName, this.file.size,
            this.file.lastModified, this.projectId, this.type)) {
            existingProject = JSON.parse(JSON.stringify(this.store.getters
                .checkProject(this.fileName, this.file.size, this.file.lastModified,
                    this.projectId, this.type)))
            this.multiPartName = existingProject.multiPartName
            this.remainingParts = existingProject.parts
            this.uploadRemainingParts()
        } else if (this.file.size < this.sliceSize) {
            const thisTmp = this
            s3.upload({
                Bucket: this.bucketName,
                Key: this.fileName,
                Body: this.file,
            }, async (err, data) => {
                if (err) {
                    Sentry.captureException(err)
                } else {
                    thisTmp.progress = 100
                    try {
                        if (this.type === 'FINAL') {
                            await API.AddFinalVideo(this.projectId, String(data.Location),
                                this.fileName)
                        } else {
                            await API.AddAssetToProject({
                                name: this.fileName,
                                path: String(data.Location),
                                previewPath: String(data.Location),
                                type: this.type,
                            }, this.projectId)
                        }
                    } catch (e) {
                        Sentry.captureException(e)
                    }
                    await new Promise(r => setTimeout(r, 2000))
                    this.store.commit('setNumberOfRemainingParts', 0)
                    this.store.commit('setIsUploading', { isUploading: false })
                }
            })
        } else {
            s3.createMultipartUpload({ Bucket: this.bucketName, Key: this.fileName },
                (err, multipart) => {
                    if (err) {
                        Sentry.captureException(err)
                        return
                    }
                    this.multiPartName = multipart.UploadId || ''
                    this.splitFile()
                })
        }
        return true
    }

    uploadPart(partNumber) {
        if (this.file) {
            this.availablePromises = Math.max(0, this.availablePromises - 1)
            const blob = partNumber < this.partsCount
                ? this.file.slice(this.sliceSize * (partNumber - 1), this.sliceSize * partNumber)
                : this.file.slice(this.sliceSize * (partNumber - 1), this.file.size)
            const thisTmp = this
            retry({ times: 20, interval: 200 }, () => {
                s3.uploadPart({
                    Body: blob,
                    Bucket: this.bucketName,
                    Key: thisTmp.fileName,
                    PartNumber: partNumber,
                    UploadId: thisTmp.multiPartName,
                }, (err, data) => {
                    if (err) {
                        thisTmp.failedParts.push(partNumber)
                    } else {
                        thisTmp.store.commit('addUploadedPart', {
                            ETag: data.ETag,
                            PartNumber: Number(partNumber),
                            name: thisTmp.fileName,
                            type: thisTmp.type,
                            projectId: thisTmp.projectId,
                        })
                        thisTmp.store.commit('removePartFromProject', {
                            part: partNumber,
                            name: thisTmp.fileName,
                            type: thisTmp.type,
                            projectId: thisTmp.projectId,
                        })
                        thisTmp.progress = (100 / thisTmp.partsCount) * thisTmp.store.getters
                            .projectInfo(thisTmp.fileName, thisTmp.type, thisTmp.projectId)
                            .uploadedParts.filter(String).length
                        thisTmp.uploadNext()
                    }
                })
            }, (err, result) => {
                if (err) {
                    Sentry.captureException(err)
                }
            })
        }
    }

    uploadNext() {
        this.uploadedCount += 1
        this.availablePromises += 1
        if (this.isReupload) {
            if (this.remainingPartsCount > 0 && this.nextSlice <= this.partsCount) {
                const tmp = this.remainingPartsCount
                this.remainingPartsCount -= 1
                this.uploadPart(this.remainingParts[tmp - 1])
            }
        } else if (this.nextSlice <= this.partsCount) {
            const tmp = this.nextSlice
            this.nextSlice += 1
            this.uploadPart(tmp)
        }
        if (this.isReupload) {
            if (this.uploadedCount >= this.remainingParts.length) {
                if (this.failedParts.length > 0) {
                    this.uploadRemainingParts()
                } else {
                    this.completeTransfer()
                }
            }
        } else if (this.uploadedCount + this.failedParts.length >= this.partsCount) {
            if (this.failedParts.length > 0) {
                this.uploadRemainingParts()
            } else {
                this.completeTransfer()
            }
        }
    }

    uploadRemainingParts() {
        if (this.file) {
            this.uploadedCount = 0
            this.isReupload = true
            this.partsCount = Math.ceil(this.file.size / this.sliceSize)
            this.progress = (100 / this.partsCount) * this.store.getters
                .projectInfo(this.fileName, this.type, this.projectId)
                .uploadedParts.filter(String).length
            this.remainingParts = this.remainingParts.concat(this.failedParts)
            this.store.commit('setNumberOfProjectParts', this.partsCount)
            this.store.commit('setNumberOfRemainingParts', this.remainingParts.length)
            this.failedParts = []
            this.remainingPartsCount = this.remainingParts.length
            this.availablePromises = 0
            const promisesCount = Math.min(this.remainingPartsCount, maximumConcurrentUploads)
            for (let i = 0; i < promisesCount; i += 1) {
                const tmp = this.remainingPartsCount
                this.remainingPartsCount -= 1
                this.uploadPart(this.remainingParts[tmp - 1])
            }
        }
    }

    completeTransfer() {
        const finalData = {
            Bucket: this.bucketName,
            Key: this.fileName,
            MultipartUpload: {
                Parts: this.store.getters.projectInfo(this.fileName, this.type, this.projectId)
                    .uploadedParts,
            },
            UploadId: this.multiPartName,
        }
        const thisTmp = this
        s3.completeMultipartUpload(finalData, async (finalErr, data) => {
            if (finalErr) {
                Sentry.captureException(finalErr)
            } else {
                thisTmp.store.commit('removeProject', {
                    name: thisTmp.fileName,
                    type: thisTmp.type,
                    projectId: thisTmp.projectId,
                })
                thisTmp.progress = 100
                try {
                    if (this.type === 'FINAL') {
                        await API.AddFinalVideo(this.projectId, String(data.Location),
                            this.fileName)
                    } else {
                        await API.AddAssetToProject({
                            name: this.fileName,
                            path: String(data.Location),
                            previewPath: String(data.Location),
                            type: this.type,
                        }, this.projectId)
                    }
                } catch (e) {
                    Sentry.captureException(e)
                }
                await new Promise(r => setTimeout(r, 2000))
                thisTmp.store.commit('setNumberOfRemainingParts', 0)
                thisTmp.store.commit('setIsUploading', { isUploading: false })
            }
        })
    }
}
