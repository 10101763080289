
import { Component, Vue, Watch } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import JSPdf from 'jspdf'
import API from '@/api/index'
import FileUpload from '@/components/FileUpload.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { remainingTime, reviewDaysLeft, workDaysSince } from '@/utils/project'
import Assets from './Assets.vue'
import rules from '@/utils/rules'

type ProjectLinkType = 'assetsLink' | 'reviewLink' | 'collaborationLink' | 'editorAssetsLink'

interface LinkInfo {
    type: ProjectLinkType,
    title: string,
    link: string,
    error: string,
    saving: boolean,
    copied: boolean,
}

interface BriefInfo {
    label: TranslateResult,
    info?: string,
}

interface ProjectInfo {
    name: string,
    createdBy: string,
    status: string,
    noReview: number,
    reviewPeriod: number,
    briefInfo: string[],
    projectLinks: Record<ProjectLinkType, string>,
    finalVideoPath: string,
    finalVideoPreview: string,
    finalVideoThumbnail: string,
}

interface TimeInfo {
    latestDate: string,
    pausedAt: string,
    pausedMinutes: number,
}

@Component({
    components: {
        FileUpload,
        Assets,
        VideoPlayer,
    },
    directives: {
        focus: {
            inserted: (el) => {
                el.focus()
            },
        },
    },
})
export default class Project extends Vue {
    isTimerOn = false

    linksInfo: LinkInfo[] = []
    linksTitle: Record<ProjectLinkType, string> = {
        assetsLink: '',
        reviewLink: '',
        collaborationLink: '',
        editorAssetsLink: '',
    }

    briefsTitle: TranslateResult[] = []
    briefsInfo: BriefInfo[] = []
    projectStatus = 'In Progress'
    statusesList: Record<string, string> = {}
    projectId = 0
    isAllowed = false
    isActive = false
    inReview = false
    canRestoreToReview = false
    hasTimer = false
    canUpload = false
    organizationId = 0
    countDown = 0

    timer = ''
    reviewTimer = ''
    calculateTimerTimeout: NodeJS.Timeout | null = null;
    editingReviewPeriod = false
    reviewPeriodInput = ''
    reviewPeriodError = ''

    rawUrl = ''
    proxyUrl = ''
    actualPart = 0
    totalParts = 0
    fileName = ''
    isEditor = false
    isProxyDownloadReady = false
    isRawDownloadReady = false
    isFileAvailable = false
    checkVideoTimeout: NodeJS.Timeout | null = null
    timeInfo: TimeInfo = {
        latestDate: '',
        pausedAt: '',
        pausedMinutes: 0,
    }

    projectInfo: ProjectInfo = {
        name: '',
        status: '',
        createdBy: '',
        noReview: 0,
        reviewPeriod: 0,
        briefInfo: [],
        projectLinks: {
            assetsLink: '',
            reviewLink: '',
            collaborationLink: '',
            editorAssetsLink: '',
        },
        finalVideoPath: '',
        finalVideoPreview: '',
        finalVideoThumbnail: '',
    }

    linkRules = rules.isLink

    async changeTimeStatus() {
        try {
            const response = this.isTimerOn ? await API.PauseProject(this.projectId)
                : await API.ResumeProject(this.projectId)
            this.isTimerOn = !this.isTimerOn
            if (response.data.status) {
                this.projectInfo.status = this.statusesList[response.data.status.name]
            }
            if (this.isTimerOn) {
                this.timeInfo.pausedMinutes = response.data.totalPausedMinutes
                this.calculateTime()
            } else {
                this.timeInfo.pausedAt = response.data.pausedAt
                window.clearInterval(undefined)
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    async sendToReview() {
        try {
            const response = await API.SubmitProject(this.projectId)
            if (response.data.status) {
                this.projectInfo.status = this.statusesList[response.data.status.name]
            }
            if (response.data.latestSubmittedAt) {
                this.timeInfo.latestDate = response.data.latestSubmittedAt
            }
            if (response.data.pausedAt) {
                this.timeInfo.pausedAt = response.data.pausedAt
            }
            if (response.data.totalPausedMinutes) {
                this.timeInfo.pausedMinutes = response.data.totalPausedMinutes
            }

            this.timeInfo = {
                latestDate: response.data.latestSubmittedAt || response.data.createdAt,
                pausedAt: response.data.pausedAt,
                pausedMinutes: response.data.totalPausedMinutes,
            }
            this.projectInfo.reviewPeriod = response.data.reviewPeriod
            this.calculateTime()

            this.canRestoreToReview = false
            this.isActive = false
            this.inReview = true
            this.hasTimer = false
        } catch (err) {
            console.error('failed to submit project', err)
            Sentry.captureException(err)
        }
    }

    async restoreToReview() {
        try {
            const response = await API.RestoreToReview(this.projectId)
            if (response.data.status) {
                this.projectInfo.status = this.statusesList[response.data.status.name]
            }
            if (response.data.latestSubmittedAt) {
                this.timeInfo.latestDate = response.data.latestSubmittedAt
            }
            if (response.data.pausedAt) {
                this.timeInfo.pausedAt = response.data.pausedAt
            }
            if (response.data.totalPausedMinutes) {
                this.timeInfo.pausedMinutes = response.data.totalPausedMinutes
            }

            this.timeInfo = {
                latestDate: response.data.latestSubmittedAt || response.data.createdAt,
                pausedAt: response.data.pausedAt,
                pausedMinutes: response.data.totalPausedMinutes,
            }
            this.projectInfo.reviewPeriod = response.data.reviewPeriod
            this.calculateTime()

            this.canRestoreToReview = false
            this.isActive = false
            this.inReview = true
            this.hasTimer = false
        } catch (err) {
            console.error('failed to submit project', err)
            Sentry.captureException(err)
        }
    }

    checkProjectName(value: string): string | null {
        if (value.length < 1) {
            return this.$t('errors.rules.required.projectName').toString()
        }
        if (value.length > 43) {
            return this.$t('errors.rules.validated.projectNameMax').toString()
        }
        return null
    }

    async changeProjectName(value: string): Promise<string | null> {
        const err = this.checkProjectName(value)
        if (err !== null) return err

        try {
            const res = await API.UpdateProject(this.projectId, { name: value })
            this.projectInfo.name = res.data.name
            this.$store.commit('saveInfoTopBar', this.projectInfo.name)
            this.briefsInfo[0].info = this.projectInfo.name
        } catch (e) {
            console.error('failed to update project name', e)
            Sentry.captureException(e)
            return String(e)
        }
        return null
    }

    async updateLink(index: number, type: ProjectLinkType, link: string) {
        this.linksInfo[index].error = ''

        const checkLinkInput = this.linkRules(link)
        if (checkLinkInput === true || link === null || link === '') {
            const changed = link !== this.projectInfo.projectLinks[type]
            if (!changed) {
                return
            }

            this.linksInfo[index].saving = true
            try {
                const info = {}
                info[type] = link || null
                await API.UpdateProject(this.projectId, info)
                this.projectInfo.projectLinks[type] = link
            } catch (err) {
                console.error(`failed to update '${type}'`, err)
                Sentry.captureException(err)
                this.linksInfo[index].error = String(err)
            } finally {
                setTimeout(() => {
                    this.linksInfo[index].saving = false
                }, 1000)
            }
        } else {
            this.linksInfo[index].error = checkLinkInput as string
        }
    }

    async copyLink(index: number) {
        this.linksInfo[index].copied = true
        await navigator.clipboard.writeText(this.linksInfo[index].link)
        setTimeout(() => this.linksInfo[index].copied = false, 1000)
    }

    calculateTime() {
        this.timer = remainingTime({
            status: this.projectInfo.status,
            pausedAt: this.timeInfo.pausedAt,
            latestDate: this.timeInfo.latestDate,
            pausedMinutes: this.timeInfo.pausedMinutes,
        })

        // TODO: include hours?
        if (this.projectInfo.reviewPeriod !== 0) {
            this.reviewTimer = reviewDaysLeft(this.timeInfo.pausedAt,
                this.projectInfo.reviewPeriod).toString()
        } else {
            this.reviewTimer = ''
        }
        this.reviewPeriodInput = this.reviewTimer

        if (this.isTimerOn) {
            this.calculateTimerTimeout = setTimeout(() => {
                this.calculateTime()
            }, 1000)
        }
    }

    async submitReviewPeriod() {
        let input = this.reviewPeriodInput
        if (input === '') {
            input = '0'
        }

        const isInt = /^\d+$/.test(input)
        const period = _.parseInt(input)
        if (!isInt || !_.isFinite(period) || period < 0) {
            this.reviewPeriodError = this.$t('errors.rules.validated.projectReviewPeriod')
                .toString()
            return
        }

        let newPeriod = 0
        if (period > 0) {
            const elapsed = workDaysSince(this.timeInfo.pausedAt)
            newPeriod = elapsed + period
        }

        try {
            await API.UpdateProjectReviewPeriod(this.projectId, newPeriod)

            this.editingReviewPeriod = false
            this.projectInfo.reviewPeriod = newPeriod
            if (this.calculateTimerTimeout) clearTimeout(this.calculateTimerTimeout)
            this.calculateTime()
        } catch (error) {
            console.error('failed to update project \'reviewPeriod\'', error)
            Sentry.captureException(error)
            this.reviewPeriodError = String(error)
        }
    }

    cancelEditingReviewPeriod() {
        this.editingReviewPeriod = false
        this.reviewPeriodError = ''
        this.reviewPeriodInput = this.reviewTimer
    }

    async checkFile() {
        if (this.projectInfo.finalVideoPreview) {
            try {
                await API.CheckFile(this.projectInfo.finalVideoPreview)
                this.isFileAvailable = true
            } catch (e) {
                this.isFileAvailable = false
                this.checkVideoTimeout = setTimeout(() => {
                    this.checkFile()
                }, 5000)
            }
        }
    }

    async uploadVideo(e) {
        e.preventDefault()
        const file: File = e.target.files[0] || e.dataTransfer.files[0]
        this.fileName = file.name
        this.isActive = false
        const uploader = new FileUpload()
        // added 1 because you will upload only one file here
        await uploader.uploadFile(file, this.$store, 'FINAL', this.projectId, this.organizationId, 1)
    }

    async getProjectInfo() {
        try {
            const response = await API.GetProject(this.projectId)
            this.isAllowed = true
            const info = response.data
            this.organizationId = info.organization.id
            this.isTimerOn = info.status === 'IN_EDITING' || info.status === 'WAITING_FOR_EDITOR'
            this.projectInfo = {
                name: info.name,
                status: this.statusesList[info.status],
                noReview: info.noReviews !== null ? info.noReviews : 0,
                createdBy: `${info.user.firstName} ${info.user.lastName}`,
                reviewPeriod: info.reviewPeriod,
                briefInfo: [
                    info.name || '',
                    info.audience || '',
                    info.goal || '',
                    info.finalVideoLength || '',
                    info.brief || '',
                ],
                projectLinks: {
                    assetsLink: info.assetsLink,
                    reviewLink: info.reviewLink,
                    collaborationLink: info.collaborationLink,
                    editorAssetsLink: info.editorAssetsLink,
                },
                finalVideoPath: info.finalVideoPath || '',
                finalVideoPreview: info.finalVideoPreview || '',
                finalVideoThumbnail: info.finalVideoThumbnail || '',
            }
            this.checkFile()
            this.timeInfo = {
                latestDate: info.latestSubmittedAt || info.createdAt,
                pausedAt: info.pausedAt,
                pausedMinutes: info.totalPausedMinutes,
            }
            this.rawUrl = info.hdUrl
            this.proxyUrl = info.lowResUrl
            this.isRawDownloadReady = info.hasHdArchive === 1
            this.isProxyDownloadReady = info.hasLowResArchive === 1
            this.calculateTime()
            this.linksInfo = Object.keys(this.linksTitle).map(type => ({
                type: type as ProjectLinkType,
                title: this.linksTitle[type],
                link: this.projectInfo.projectLinks[type],
                error: '',
                saving: false,
                copied: false,
            }))
            this.briefsInfo = this.briefsTitle.map((briefLabel, index) => ({
                label: briefLabel,
                info: this.projectInfo.briefInfo[index],
            }))
            this.isActive = (info.status === 'IN_EDITING' || info.status === 'PAUSED') && info.finalVideoPreview
            this.inReview = info.status === 'IN_REVIEW'
            this.canRestoreToReview = info.status === 'APPROVED'
            this.hasTimer = (info.status === 'IN_EDITING' || info.status === 'PAUSED') && !this.isEditor
            this.canUpload = info.status !== 'OPENED' && info.status !== 'WAITING_FOR_EDITOR'
                && info.status !== 'DELETED'
            this.$store.commit('saveInfoTopBar', this.projectInfo.name)
            this.$store.commit('setTopBarEditCallback', (value: string) => this.changeProjectName(value))
        } catch (error) {
            console.error(error)
            Sentry.captureException(error)
        }
    }

    generatePdf () {
        const doc = new JSPdf('p', 'pt', 'letter')
        doc.addImage(process.env.VUE_APP_LOGO_BRIEF || '', 'PNG', 50, 50, 0, 0)

        doc.html(
            `
            <div style="overflow-wrap: break-word;">
                <p>
                    <span style="color:#57BF9A; font-weight: bold;">${this.briefsInfo[0].label}:</span>
                    <br />
                    <div>${this.briefsInfo[0].info}</div>
                </p>
                <p>
                    <span style="color:#57BF9A; font-weight: bold;">${this.briefsInfo[1].label}:</span>
                    <br />
                    <div>${this.briefsInfo[1].info}</div>
                </p>
                <p>
                    <span style="color:#57BF9A; font-weight: bold;">${this.briefsInfo[2].label}:</span>
                    <br />
                    <div>${this.briefsInfo[2].info}</div>
                </p>
                <p>
                    <span style="color:#57BF9A; font-weight: bold;">${this.briefsInfo[3].label}:</span>
                    <br />
                    <div>${this.briefsInfo[3].info}</div>
                </p>
                <p>
                    <span style="color:#57BF9A; font-weight: bold;">${this.briefsInfo[4].label}:</span>
                    <br />
                    ${(this.briefsInfo[4].info || '').replace(/\n/g, '<p></p>')}
                </p>
            </div>
            `,
            {
                x: 0,
                y: 50,
                margin: 50,
                width: 520,
                windowWidth: 600,
                autoPaging: 'text',
                callback: (_doc) => {
                    _doc.save('brief.pdf')
                },
            },
        )
    }

    @Watch('$store.getters.remainingParts')
    onPropertyChange(value: number) {
        this.actualPart = this.$store.getters.totalParts - value
        if (value === 0) {
            this.getProjectInfo()
        }
    }

    @Watch('$store.getters.totalParts')
    onPropertyChanged(value: number) {
        this.totalParts = value
    }

    mounted() {
        this.projectId = Number(this.$route.params.projectId)
        this.isEditor = this.$store.getters.userRole === 'EDITOR'
        this.getProjectInfo()
        this.linksTitle = {
            assetsLink: this.$t('pages.project.links.organizationAsset').toString(),
            reviewLink: this.$t('pages.project.links.feedbackLink').toString(),
            collaborationLink: this.$t('pages.project.links.collaborationLink').toString(),
            editorAssetsLink: this.$t('pages.project.links.editorFiles').toString(),
        }
        this.briefsTitle = [
            this.$t('pages.project.projectBrief.name'),
            this.$t('pages.project.projectBrief.audience'),
            this.$t('pages.project.projectBrief.goal'),
            this.$t('pages.project.projectBrief.length'),
            this.$t('pages.project.projectBrief.brief'),
        ]
        this.statusesList = {
            OPENED: this.$t('pages.project.status.opened').toString(),
            WAITING_FOR_EDITOR: this.$t('pages.project.status.waitingForEditor').toString(),
            IN_EDITING: this.$t('pages.project.status.inEditing').toString(),
            IN_REVIEW: this.$t('pages.project.status.inReview').toString(),
            PAUSED: this.$t('pages.project.status.paused').toString(),
            DELETED: this.$t('pages.project.status.deleted').toString(),
            APPROVED: this.$t('pages.project.status.approved').toString(),
            ARCHIVED: this.$t('pages.project.status.archived').toString(),
            HIDDEN: this.$t('pages.project.status.hidden').toString(),
        }
    }

    destroyed() {
        if (this.checkVideoTimeout) clearTimeout(this.checkVideoTimeout)
        if (this.calculateTimerTimeout) clearTimeout(this.calculateTimerTimeout)
    }
}
