import Vue from 'vue'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue'

import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'

import { VueReCaptcha } from 'vue-recaptcha-v3'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import i18n from './language'

import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'

import PersistentStore from '@/utils/persistentStore'

const sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueI18n)
Vue.use(VueReCaptcha, {
    siteKey: sitekey,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
})

Vue.config.productionTip = false

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE

export default Sentry.init({
    environment: process.env.VUE_APP_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new SentryIntegrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true,
    })],
})

const initApp = async () => {
    await PersistentStore(store)
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App),
    }).$mount('#app')
}

initApp()
