
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import TopMenu from '@/components/Organization/TopMenu.vue'
import Project from './Project.vue'
import API from '@/api/index'
import DuplicateProjectModal from '@/components/Organization/Modals/DuplicateProjectModal.vue'
import Footer from '@/components/Organization/Footer.vue'

interface ProjectInfo {
    id: number,
    name: string,
    reviewLink: string,
    finalVideoLength: number,
    hdUrl?: string,
    lowResUrl?: string,
    finalVideoThumbnail?: string,
}

interface SavedProject {
    id: number,
    name: string
}

@Component({
    components: {
        TopMenu,
        Project,
        DuplicateProjectModal,
        Footer,
    },
})
export default class ContentLibrary extends Vue {
    projectsNumber: number = 0
    projectsInfo: Array<ProjectInfo> = []
    isActionModalShow: boolean = false
    savedAction: string = ''
    selectedProjectIndex: number | null = null
    oldSelectedProjectIndex: number | null = null
    savedProject: SavedProject = {
        id: 0,
        name: '',
    }

    toggleProjectOptions(index) {
        this.selectedProjectIndex = this.oldSelectedProjectIndex === index ? null : index
    }

    @Watch('selectedProjectIndex')
    onPropertyChanged(value: number | null, oldValue: number | null) {
        this.oldSelectedProjectIndex = value
    }

    async getOrganizationProjectsArchived() {
        try {
            const response = await API.GetOrganizationProjects(parseInt(this.$store.state.organizationId, 10), 'Archived')
            this.projectsInfo = response.data.projects
            await this.getOrganizationProjectsApproved()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async getOrganizationProjectsApproved() {
        try {
            const response = await API.GetOrganizationProjects(parseInt(this.$store.state.organizationId, 10), 'Approved')
            this.projectsInfo = this.projectsInfo.concat(response.data.projects)
            this.projectsNumber = this.projectsInfo.length
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    hideOptions() {
        if (this.selectedProjectIndex !== null) {
            this.toggleProjectOptions(this.selectedProjectIndex)
        }
    }

    openActionModal(savedProject?, action?) {
        this.isActionModalShow = true
        this.savedProject = savedProject
        this.savedAction = action
    }

    closeActionModal() {
        this.isActionModalShow = false
    }

    mounted() {
        this.getOrganizationProjectsArchived()
    }
}
