import i18n from '../language/en'

interface TableHead {
    name: string;
    element?: string;
    sortingDirection?: string;
}

const timer: TableHead = {
    name: i18n.pages.dashboard.tableHead[0],
    element: 'timer',
    sortingDirection: 'up',
}

const projectTitle: TableHead = {
    name: i18n.pages.dashboard.tableHead[1],
    element: 'projectTitle',
    sortingDirection: 'up',
}

const organization: TableHead = {
    name: i18n.pages.dashboard.tableHead[2],
    element: 'organization',
    sortingDirection: 'up',
}

const status: TableHead = {
    name: i18n.pages.dashboard.tableHead[3],
    element: 'status',
    sortingDirection: 'up',
}

const creationDate: TableHead = {
    name: i18n.pages.dashboard.tableHead[4],
    element: 'creationDate',
    sortingDirection: 'up',
}

const version: TableHead = {
    name: i18n.pages.dashboard.tableHead[5],
    element: 'version',
    sortingDirection: 'up',
}

const feedbackLink: TableHead = {
    name: i18n.pages.dashboard.tableHead[6],
}

const driveLink: TableHead = {
    name: i18n.pages.dashboard.tableHead[7],
}

const editor: TableHead = {
    name: i18n.pages.dashboard.tableHead[8],
}

const name: TableHead = {
    name: i18n.pages.customers.tableHead[0],
    element: 'name',
    sortingDirection: 'up',
}

const expiry: TableHead = {
    name: i18n.pages.customers.tableHead[2],
    element: 'expiry',
    sortingDirection: 'up',
}

const email: TableHead = {
    name: i18n.pages.customers.tableHead[3],
    element: 'email',
    sortingDirection: 'up',
}

const phone: TableHead = {
    name: i18n.pages.customers.tableHead[5],
    element: 'phone',
    sortingDirection: 'up',
}

const action: TableHead = {
    name: i18n.pages.customers.tableHead[6],
}


const tableHeads = {
    dashboard: [
        timer,
        projectTitle,
        organization,
        status,
        creationDate,
        version,
        feedbackLink,
        driveLink,
        editor,
    ],
    customers: [
        name,
        organization,
        expiry,
        email,
        creationDate,
        phone,
        action,
    ],
}

export default tableHeads
