const localState = {
    projects: [],
    totalParts: null,
    remainingParts: null,
    projectLoadingId: null,
    remainingMemory: null,
    isUploading: null,
    queue: [],
    isFirstUpload: true,
    uploadingFileUid: null,
    uploadingFileType: null,
}

const getters = {
    projectInfo: state => (name: string, type: string, projectId: number) => {
        const project = state.projects.find(elem => elem.name === name && elem.type === type
            && elem.projectId === projectId)
        return project
    },
    checkProject: state => (name: string, size: string, date: string, projectId: number,
        type: string) => {
        const project = state.projects.find(elem => elem.name === name
            && elem.size === size && elem.lastDate === date && elem.projectId === projectId
            && elem.type === type && elem.multiPartName !== null)
        return project || null
    },
    projectsInCategory: state => (type: string, projectId: number) => {
        const projects = state.projects.filter(elem => elem.projectId === projectId
            && elem.type === type)
        return projects || null
    },
    totalParts: state => state.totalParts,
    getProjects: state => (projectId: number) => {
        const projects = state.projects.filter(elem => elem.projectId === projectId)
        return projects || null
    },
    remainingParts: state => state.remainingParts,
    projectLoadingId: state => state.projectLoadingId,
    remainingMemory: state => state.remainingMemory,
    isUploading: state => state.isUploading,
    getFromQueue: state => (state.queue.length > 0 ? state.queue[0] : null),
    getQueue: state => state.queue,
    getProjectQueue: state => (projectId: number) => {
        const projects = state.queue.filter(elem => elem.projectId === projectId)
        return projects
    },
    isFirstUpload: state => state.isFirstUpload,
    uploadingFileUid: state => state.uploadingFileUid,
    uploadingFileType: state => state.uploadingFileType,
}

const actions = {}

const mutations = {
    setProjects (state, projects) {
        state.projects = projects
    },
    addNewProject (state, info) {
        state.projects.push(info)
        localStorage.setItem('projects', JSON.stringify(state.projects))
    },
    addProjectToQueue (state, info) {
        state.queue.push(info)
        const projectId = state.projects.findIndex(project => project.name === info.file.name
            && project.type === info.type && project.projectId === info.projectId)
        if (projectId === -1) {
            const elem = {
                name: info.file.name,
                initialName: info.file.name,
                size: info.file.size,
                lastDate: info.file.lastModified,
                projectId: info.projectId,
                uid: info.file.uid,
                type: info.type,
                multiPartName: null,
                parts: null,
                uploadedParts: null,
            }
            state.projects.push(elem)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        }
    },
    removeProjectFromQueue (state) {
        const elem = state.queue.shift()
        if (elem && elem.file && elem.file.uid) {
            const projectId = state.projects.findIndex(project => project.uid === elem.file.uid
                && project.name === elem.file.name && project.size === elem.file.size
                && project.lastDate === elem.file.lastModified)
            if (projectId >= 0) {
                state.projects.splice(projectId, 1)
                localStorage.setItem('projects', JSON.stringify(state.projects))
            }
        }
    },
    setIsUploading (state, info) {
        if (info.uploadingFileUid) state.uploadingFileUid = info.uploadingFileUid
        if (info.uploadingFileType) {
            state.uploadingFileType = info.uploadingFileType
        }
        state.isUploading = info.isUploading
    },
    setIsFirstUpload(state, isFirstUpload) {
        state.isFirstUpload = isFirstUpload
    },
    removeProject (state, info) {
        const projectId = state.projects.findIndex(project => project.name === info.name
            && project.type === info.type && project.projectId === info.projectId)
        if (projectId >= 0) {
            state.projects.splice(projectId, 1)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        }
    },
    removeDummyProject(state, info) {
        const projectId = state.projects.findIndex(project => project.name === info.file.name
            && project.size === info.file.size && project.lastDate === info.file.lastModified
            && project.projectId === info.projectId && project.type === info.type
            && project.multiPartName === null && project.uid !== null)
        if (projectId >= 0) {
            state.projects.splice(projectId, 1)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        }
    },
    removePartFromProject (state, info) {
        const projectId = state.projects.findIndex(project => project.name === info.name
            && project.type === info.type && project.projectId === info.projectId)
        if (projectId >= 0) {
            state.projects[projectId].parts.splice(state.projects[projectId]
                .parts.indexOf(info.part), 1)
            if (state.projects[projectId].parts.length !== 0) {
                mutations.setNumberOfRemainingParts(state, state.projects[projectId].parts.length)
            }
            localStorage.setItem('projects', JSON.stringify(state.projects))
        }
    },
    addUploadedPart (state, info) {
        const projectId = state.projects.findIndex(project => project.name === info.name
            && project.type === info.type && project.projectId === info.projectId)
        state.projects[projectId].uploadedParts[info.PartNumber - 1] = {
            ETag: info.ETag,
            PartNumber: info.PartNumber,
        }
    },
    setNumberOfProjectParts (state, totalParts) {
        state.totalParts = totalParts
    },
    setNumberOfRemainingParts (state, remainingParts) {
        state.remainingParts = remainingParts
    },
    removeProjectPartsInfo (state) {
        state.remainingParts = null
        state.totalParts = null
    },
    setProjectLoadingId (state, id) {
        state.projectLoadingId = id
    },
    removeProjectLoadingId (state) {
        state.projectLoadingId = null
    },
    setRemainingMemory (state, remainingMemory) {
        state.remainingMemory = remainingMemory
    },
}

export default {
    state: localState,
    getters,
    actions,
    mutations,
}
