
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'

interface EditorInfo {
    id: number,
    profilePicture: string,
    name: string,
    editorDrive: string,
    email: string,
    createdAt: string,
    phone: string,
    isEnabled: 0 | 1,
    deletedAt: string | null,
}

@Component
export default class Editor extends Vue {
    @Prop() readonly info!: EditorInfo
    @Prop() readonly isSecondMenuOpen!: boolean
    @Prop() readonly index!: number

    editorNextAction: 'enable' | 'disable' = 'disable'
    linkRules = rules.linkRules

    async saveEditorDrive(userId, editorDrive) {
        try {
            await API.UpdateEditorAssetPath(userId, editorDrive)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    toggleActions() {
        this.$emit('toggleSecondMenu', this.index)
    }

    openModal(info, action) {
        this.toggleActions()
        this.$emit('openActionModal', { info, action })
    }

    openEditModal(info) {
        this.toggleActions()
        this.$emit('toggleEditorModal', { info })
    }

    @Watch('info.isEnabled')
    onPropertyChanged(value: number) {
        if (value === 1) {
            this.editorNextAction = 'disable'
        } else {
            this.editorNextAction = 'enable'
        }
    }

    mounted() {
        if (this.info.isEnabled) {
            this.editorNextAction = 'disable'
        } else {
            this.editorNextAction = 'enable'
        }
    }
}
