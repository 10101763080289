
import { Component, Vue } from 'vue-property-decorator'
import { Slider } from 'vue-color'

@Component({
    components: {
        Slider,
    },
})
export default class Page404 extends Vue {
    colors: Object = {
        hex: '#93D35A',
    }

    color: string = '#93D35A'

    updateColors(event) {
        this.color = event.hex
    }

    goBack() {
        this.$router.go(-1)
    }
}
