
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

@Component
export default class Brief extends Vue {
    name: string = ''
    nameError: TranslateResult | string | boolean = ''
    audienceError: TranslateResult | string | boolean = ''
    audience: string = ''
    goal: string = ''
    goalError: TranslateResult | string | boolean = ''
    videoLength: string = ''
    videoLengthError: TranslateResult | string | boolean = ''
    description: string = ''
    descriptionError: TranslateResult | string | boolean = ''
    isExpanded: boolean = false
    toggleAction: 'expand' | 'collapse' = 'expand'
    hasError: boolean = false
    status: string = ''
    nameInputDisabled: boolean = false
    isSavingBrief: boolean = false
    isLoadingShown: boolean = true
    videoLengthValues: Array<string> = []
    isSelectOpen: boolean = false

    @Prop() readonly isCheckingFields!: boolean

    toggleSelect() {
        if (this.status === 'opened') {
            this.isSelectOpen = !this.isSelectOpen
        }
    }

    selectVideoLength(value: string) {
        this.toggleSelect()
        this.videoLength = value
    }

    checkNameInput() {
        if (this.name.length < 1) {
            this.nameError = this.$t('general.inputs.required')
            this.hasError = true
        } else if (this.name.length > 43) {
            this.nameError = this.$t('errors.rules.validated.projectNameMax')
            this.hasError = true
        } else {
            this.nameError = ''
        }
    }

    checkAudienceInput() {
        if (this.audience.length < 1) {
            this.audienceError = this.$t('general.inputs.required')
            this.hasError = true
        } else if (this.audience.length > 50) {
            this.audienceError = this.$t('errors.rules.validated.audienceMax')
            this.hasError = true
        } else {
            this.audienceError = ''
        }
    }

    checkGoalInput() {
        if (this.goal.length < 1) {
            this.goalError = this.$t('general.inputs.required')
            this.hasError = true
        } else {
            this.goalError = ''
        }
    }

    checkVideoLengthInput() {
        if (this.videoLength.length < 1) {
            this.videoLengthError = this.$t('general.inputs.required')
            this.hasError = true
        } else {
            this.videoLengthError = ''
        }
    }

    checkDescriptionInput() {
        if (this.description.length < 1) {
            this.descriptionError = this.$t('general.inputs.required')
            this.hasError = true
        } else {
            this.descriptionError = ''
        }
    }

    toggleShowBrief() {
        this.isExpanded = !this.isExpanded
        this.toggleAction = this.isExpanded ? 'collapse' : 'expand'
    }

    async getProjectInfo() {
        try {
            const response = await API.GetProject(parseInt(this.$route.params.projectId, 10))
            this.name = response.data.name
            this.description = response.data.brief
            this.videoLength = response.data.finalVideoLength
            this.audience = response.data.audience
            this.goal = response.data.goal
            this.status = response.data.status.toLowerCase()
            if (this.status === 'opened') this.toggleShowBrief()
            this.nameInputDisabled = [
                'archived',
                'deleted',
                'approved',
                'hidden',
            ].includes(this.status)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    checkFields() {
        this.hasError = false
        this.checkNameInput()
        this.checkAudienceInput()
        this.checkGoalInput()
        this.checkVideoLengthInput()
        this.checkDescriptionInput()
    }

    @Watch('isCheckingFields')
    onPropertyChanged(value: boolean) {
        if (value === true) {
            this.checkFields()
            if (this.hasError) {
                const briefElement = this.$refs.brief as HTMLDivElement
                briefElement.scrollIntoView()
                this.isExpanded = true
            } else {
                this.toggleShowBrief()
            }
            this.$emit('sendToEditing', this.hasError)
            this.hasError = false
        }
    }

    mounted() {
        this.videoLengthValues = this.$store.state.constants.FINAL_VIDEO_LENGTHS
        this.getProjectInfo()
    }

    async saveChanges() {
        const info = {
            name: this.name,
            brief: this.description,
            audience: this.audience,
            goal: this.goal,
            finalVideoLength: this.videoLength,
        }
        this.checkFields()
        if (!this.hasError) {
            this.isSavingBrief = true
            this.isLoadingShown = true
            try {
                const res = await API.UpdateProject(Number(this.$route.params.projectId), info)
                this.$emit('projectNameChanged', res.data.name)
            } catch (error) {
                console.error('failed to update project', error)
                Sentry.captureException(error)
            } finally {
                setTimeout(() => {
                    this.isLoadingShown = false
                    setTimeout(() => {
                        this.isLoadingShown = true
                        this.isSavingBrief = false
                    }, 1000)
                }, 800)
            }
        }
    }
}
