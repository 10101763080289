
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import rules from '@/utils/rules'
import API from '@/api/index'

@Component
export default class RecoverPassword extends Vue {
    email: string = ''
    authorName: string = ''
    isConfirmError: TranslateResult = ''
    emailSent: boolean = false
    emailError: TranslateResult | string | boolean = ''
    emailRules = rules.isEmail

    checkEmailInput() {
        if (this.email.length < 1) {
            this.emailError = this.$t('errors.rules.required.email')
        } else if (this.emailRules(this.email) !== true) {
            this.emailError = this.emailRules(this.email)
        } else {
            this.emailError = ''
        }
    }

    async recoverPassword() {
        this.checkEmailInput()
        setTimeout(async () => {
            try {
                if (this.emailError === '') {
                    this.email = this.email.trim()
                    const response = await API.RecoverPassword(this.email)
                    if (response.status === 204) {
                        this.emailSent = true
                    }
                }
            } catch (error) {
                Sentry.captureException(error)
                this.isConfirmError = this.$t('general.errors.server')
            }
        }, 500)
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
    }
}
