
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ConfirmActionModal extends Vue {
    @Prop() readonly isActionModalShow!: boolean
    @Prop() readonly warning?: string

    submit() {
        this.$emit('submitProject')
    }

    closeActionModal() {
        this.$emit('closeModalConfirmation')
    }
}
