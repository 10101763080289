
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

@Component
export default class ConfirmApproveModal extends Vue {
    @Prop() readonly isApproveModalShow!: boolean
    approveProject() {
        this.$emit('approveProject')
    }

    closeActionModal() {
        this.$emit('closeModalConfirmation')
    }
}
