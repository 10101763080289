
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ProjectInfo extends Vue {
    authorName: string = ''
    audienceError: TranslateResult | string | boolean = ''
    audience: string = ''
    goal: string = ''
    goalError: TranslateResult | string | boolean = ''
    videoLength: string = ''
    videoLengthError: TranslateResult | string | boolean = ''
    hasError: boolean = false
    isTitleShown: boolean = false
    isFormShown: boolean = false
    areInputsGrown: boolean = false
    videoLengthValues: Array<string> = []
    isSelectOpen: boolean = false

    checkAudienceInput() {
        if (this.audience.length < 1) {
            this.audienceError = this.$t('errors.rules.required.audience')
        } else if (this.audience.length > 50) {
            this.audienceError = this.$t('errors.rules.validated.audienceMax')
        } else {
            this.audienceError = ''
        }
    }

    checkGoalInput() {
        if (this.goal.length < 1) {
            this.goalError = this.$t('errors.rules.required.goal')
        } else {
            this.goalError = ''
        }
    }

    checkVideoLengthInput() {
        if (this.videoLength.length < 1) {
            this.videoLengthError = this.$t('errors.rules.required.videoLength')
        } else {
            this.videoLengthError = ''
        }
    }

    cancel() {
        this.$emit('cancel')
    }

    goNextStep() {
        this.checkAudienceInput()
        this.checkGoalInput()
        this.checkVideoLengthInput()
        if (this.audienceError || this.goalError || this.videoLengthError) return
        const info = {
            audience: this.audience,
            goal: this.goal,
            videoLength: this.videoLength,
        }
        setTimeout(() => {
            this.areInputsGrown = false
        }, 400)
        setTimeout(() => {
            this.isFormShown = false
        }, 800)
        setTimeout(() => {
            this.isTitleShown = false
            this.$emit('saveProjectProfile', info)
        }, 1200)
    }

    toggleSelect() {
        this.isSelectOpen = !this.isSelectOpen
    }

    selectVideoLength(value) {
        this.toggleSelect()
        this.videoLength = value
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        this.videoLengthValues = this.$store.state.constants.FINAL_VIDEO_LENGTHS
        setTimeout(() => {
            this.isTitleShown = true
        }, 500)
        setTimeout(() => {
            this.isFormShown = true
        }, 1000)
        setTimeout(() => {
            this.areInputsGrown = true
        }, 1500)
    }
}
