import store from '@/store'
import Customers from '@/components/AdminPortal/Customers/Customers.vue'
import Dashboard from '@/components/AdminPortal/Dashboard/Dashboard.vue'
import Editors from '@/components/AdminPortal/Editors/Editors.vue'
import Onboarding from '@/components/AdminPortal/Onboarding/Onboarding.vue'
import Project from '@/components/AdminPortal/Project/Project.vue'
import Organization from '@/components/AdminPortal/Organization/Organization.vue'

export default {
    path: '/',
    component: {
        template: '<router-view />',
    },
    beforeEnter: (to: any, from: any, next: { (arg0: string): void; (): void }) => {
        if ((store.getters.userRole !== 'SUPERADMIN' && store.getters.userRole !== 'ADMIN' && store.getters.userRole !== 'EDITOR')) {
            next('/404')
            return
        }
        if (window.innerWidth > 768) {
            next()
        } else {
            next('/not-mobile')
        }
    },
    children: [
        {
            path: 'customers',
            name: 'customers',
            component: Customers,
        },
        {
            path: 'editors',
            name: 'editors',
            component: Editors,
        },
        {
            path: 'dashboard',
            name: 'dashboard',
            component: Dashboard,
        },
        {
            path: 'onboarding',
            name: 'onboarding',
            component: Onboarding,
        },
        {
            path: 'project/:projectId',
            name: 'project',
            component: Project,
        },
        {
            path: 'organization/:organizationId',
            name: 'organization',
            component: Organization,
        },
    ],
}
