
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import rules from '@/utils/rules'

@Component
export default class Register2 extends Vue {
    company: string = ''
    authorName: string = ''
    isServerError: TranslateResult = ''
    companyError: TranslateResult | string = ''
    isTitleBigger: boolean = false
    isFormShown: boolean = false
    isFormGrown: boolean = false
    areInputsGrown: boolean = false
    isButtonDisabled: boolean = false

    validateCompanyName(name: string): boolean | string {
        for (let i = 0; i < rules.organizationNameRules.length; i++) {
            const rule = rules.organizationNameRules[i] as (v: string)=> boolean | string
            const result = rule(name)
            if (result !== true) {
                return result
            }
        }
        return true
    }

    checkCompanyInput() {
        if (this.company.length < 1) {
            this.companyError = this.$t('errors.rules.required.company')
        } else if (this.company.length > 99) {
            this.companyError = this.$t('errors.rules.validated.companyNameMax')
        } else {
            const res = this.validateCompanyName(this.company)
            if (res !== true) {
                this.companyError = res as string
            } else {
                this.companyError = ''
            }
        }
    }

    checkInput(evt: KeyboardEvent) {
        this.companyError = ''

        const el = evt.target as HTMLInputElement
        const pos = el ? el.selectionStart || 0 : 0

        const name = this.company.slice(0, pos) + evt.key + this.company.slice(pos)
        const valid = this.validateCompanyName(name)
        if (valid !== true) {
            evt.preventDefault()
            this.companyError = valid as string
            return false
        }

        return true
    }

    async login() {
        this.checkCompanyInput()
        if (!this.companyError) {
            this.isButtonDisabled = true
            try {
                const response = await API.CreateOrganization(this.company)
                const responseMe = await API.PersonalInfo()
                const info = {
                    role: responseMe.data.role.name,
                    name: `${responseMe.data.firstName} ${responseMe.data.lastName}`,
                    orgName: responseMe.data.organizationName,
                    id: responseMe.data.id,
                }
                this.$store.commit('setUserInfo', info)
                this.$store.commit('organizationId', response.data.id)
                this.$router.push('/user/projects')
            } catch (error) {
                Sentry.captureException(error)
                console.error('failed to create organization', error)
                this.isServerError = String(error)
            } finally {
                this.isButtonDisabled = false
            }
        }
    }

    mounted() {
        this.authorName = this.$store.state.constants.LOGIN_AUTHOR_NAME
        setTimeout(() => {
            this.isTitleBigger = true
        }, 500)
        setTimeout(() => {
            this.isFormGrown = true
        }, 1000)
        setTimeout(() => {
            this.isFormShown = true
        }, 1500)
        setTimeout(() => {
            this.areInputsGrown = true
        }, 1800)
    }
}
