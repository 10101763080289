
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'

interface UserInfo {
    name: string,
    id: number,
}

@Component
export default class ConfirmActionModal extends Vue {
    @Prop() readonly savedUser!: UserInfo
    @Prop() readonly savedAction!: 'disable' | 'enable' | 'delete this user' | 'resend invite link' | 'delete all Interview Clips' | 'delete all Assets' | 'delete all B Roll Clips' | 'cancel'
    @Prop() readonly isActionModalShow!: boolean
    @Prop() readonly savedDeleteType!: string

    ownerModalAction() {
        if (this.savedAction === 'disable' || this.savedAction === 'enable') {
            this.toggleUserStatus()
        } else if (this.savedAction === 'delete this user') {
            this.deleteUser()
        } else if (this.savedAction === 'resend invite link') {
            this.resendLink()
        } else if (this.savedAction === 'delete all Interview Clips' || this.savedAction === 'delete all Assets' || this.savedAction === 'delete all B Roll Clips') {
            this.$emit('deleteMedia', this.savedDeleteType)
        } else if (this.savedAction === 'cancel') {
            this.$emit('cancelPlan')
        }
    }

    async deleteUser() {
        try {
            await API.DeleteUserById(this.savedUser.id)
            this.$emit('getUsers')
            this.closeActionModal()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async toggleUserStatus() {
        let info = {}
        info = this.savedAction === 'disable' ? { isEnabled: 0 } : { isEnabled: 1 }
        try {
            await API.EditUserInfo(this.savedUser.id, info)
            this.$emit('getUsers')
            this.closeActionModal()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async resendLink() {
        try {
            await API.ResendInviteLink(this.savedUser.id)
            this.$emit('getUsers')
            this.closeActionModal()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    closeActionModal() {
        this.$emit('closeActionModal')
    }
}
