
import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import API from '@/api/index'
import Project from './Project.vue'
import FormatBytes from '@/utils/formatBytes'
import { remainingTime } from '@/utils/project'
import { getDate, getRenewDate, getUSDate } from '@/utils/date'
import rules from '@/utils/rules'

interface Info {
    label: any,
    value: string | number,
}

interface UserInfo {
    avatar: string,
    name: string,
    email: string,
    phone: string,
}

interface ProjectInfo {
    projectId: number,
    timer: string,
    projectTitle: string,
    organization: string,
    status: string,
    creationDate: string,
    feedbackLink: string,
    hasEditor: boolean,
    randomId: number,
}

interface EditorInfo {
    name: String,
    profilePicture: String,
    id: number,
}

interface CreditInfo {
    id?: number,
    status: string,
    startDate: string | null,
    endDate: string | null,
    renewDate: string | null,
    renewable: boolean,
    interval: number,
    creditsPerInterval: number,
    total: number,
    storage: number
}

@Component({
    components: {
        Project,
    },
    directives: {
        focus: {
            inserted: (el) => {
                el.focus()
            },
        },
    },
})
export default class Organization extends Vue {
    projectsReport: Info[] = []

    projectReviewPeriod = 0;
    editingReviewPeriod = false
    reviewPeriodInput = ''
    reviewPeriodError = ''

    creditsInfo : CreditInfo | null = null
    usersInfo: UserInfo[] = []
    updateCreditsFlag: boolean = false
    organizationId: number = 0

    projectsInfo: ProjectInfo[] = []
    editorsInfo: EditorInfo[] = []
    projectTimers: NodeJS.Timer[] = []

    completedProjects: number = 0
    activeProjects: number = 0
    storage: number = 0
    usedSpace: number = 0
    assetsCount: number = 0
    paginationLength: number = 4
    paginationStart: number = 0
    hasLeftUsers: boolean = false
    hasRightUsers: boolean = false
    selectedEditorIndex: number | null = null
    oldSelectedEditorIndex: number | null = null
    statusesList: Object = {}
    renewDate: Date | null = null

    oldAssetsLink: string = ''
    assetsLink: string = ''
    assetsLinkError: string = ''
    assetsLinkSaving: boolean = false
    assetsLinkCopied: boolean = false

    toggleSecondMenu(index: number) {
        this.selectedEditorIndex = this.oldSelectedEditorIndex === index ? null : index
    }

    @Watch('selectedEditorIndex')
    onPropertyChanged(value: number | null) {
        this.oldSelectedEditorIndex = value
    }

    @Watch('creditsInfo.startDate')
    onDateChanged(value: string) {
        if (this.creditsInfo) {
            this.renewDate = getRenewDate(this.creditsInfo.interval, value)
        }
    }

    @Watch('creditsInfo.interval')
    onIntervalChange(value: number) {
        if (this.creditsInfo) {
            this.renewDate = getRenewDate(value, this.creditsInfo.startDate)
        }
    }

    getRenewDate() {
        return getUSDate(getDate(this.renewDate).split('T')[0])
    }

    async getOrganizationInfo() {
        this.getEditors()
        this.getSubscriptionInfo()
        this.getOrganization()
        this.getUsers()
        try {
            const response = await API.GetOrganizationProjects(this.organizationId)
            const archivedProjects = response.data.projects
                .filter(info => info.status === 'ARCHIVED')
            this.completedProjects = archivedProjects.length
            this.projectsInfo = response.data.projects
                .map(info => ({
                    projectId: info.id,
                    timer: remainingTime({
                        status: info.status,
                        pausedAt: info.pausedAt,
                        latestDate: info.latestSubmittedAt || info.createdAt,
                        pausedMinutes: info.totalPausedMinutes,
                    }),
                    projectTitle: info.name,
                    organization: info.organization.name,
                    status: this.statusesList[info.status],
                    creationDate: info.createdAt.split('T')[0],
                    feedbackLink: info.reviewLink,
                    editor: info.editor !== null ? [{
                        name: `${info.editor.firstName} ${info.editor.lastName[0]}`,
                        profilePicture: info.editor.profilePictureUrl === null
                            ? null
                            : info.editor.profilePictureUrl,
                        id: info.editor.id,
                    }, ...this.createEditorList(info.editor.id)] : this.editorsInfo,
                    hasEditor: info.editor !== null,
                    randomId: Math.random(),
                }))

            response.data.projects.forEach((item, index) => {
                this.calculateTime(index, item.status, item.pausedAt,
                    item.latestSubmittedAt || item.createdAt, item.totalPausedMinutes)
            })

            this.projectsReport = [
                { label: this.$t('pages.organization.projects.projectsCompleted'), value: this.completedProjects },
                { label: this.$t('pages.organization.projects.projectsProgress'), value: this.activeProjects },
                {
                    label: this.$t('pages.organization.projects.remainingStorage'),
                    value: this.storage ? `${FormatBytes(this.usedSpace)}/${this.storage}` : 0,
                },
                { label: this.$t('pages.organization.projects.assets'), value: this.assetsCount },
            ]
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async getOrganization() {
        try {
            const response = await API.GetOrganization(this.organizationId)

            this.$store.commit('saveInfoTopBar', response.data.name)
            this.assetsLink = response.data.assetsLink
            this.oldAssetsLink = response.data.assetsLink
            this.activeProjects = response.data.activeProjects
            this.storage = response.data.storage
            this.usedSpace = response.data.usedSpace !== null ? response.data.usedSpace : 0
            this.assetsCount = response.data.assetsCount
            this.projectReviewPeriod = response.data.projectReviewPeriod
            this.reviewPeriodInput = String(this.projectReviewPeriod)
        } catch (error) {
            console.error('failed to retrieve organization', String(error))
            Sentry.captureException(error)
        }
    }

    calculateTime(index: number, status: string, pausedAt: string, latestDate: string,
        pausedMinutes: number) {
        this.projectsInfo[index].timer = remainingTime({
            status,
            pausedAt,
            latestDate,
            pausedMinutes,
        })
        if (status === 'WAITING_FOR_EDITOR' || status === 'IN_EDITING') {
            this.projectTimers[index] = setTimeout(() => {
                this.calculateTime(index, status, pausedAt, latestDate, pausedMinutes)
            }, 10000)
        }
    }

    async updateCredits() {
        this.updateCreditsFlag = false
        if (this.creditsInfo) {
            const info = {
                startDate: this.creditsInfo.startDate,
                endDate: this.creditsInfo.endDate,
                renewable: this.creditsInfo.renewable || false,
                credits: this.creditsInfo.total,
                interval: this.creditsInfo.interval,
                creditsPerInterval: this.creditsInfo.creditsPerInterval,
                storage: this.creditsInfo.storage,
            }
            const resp = await API.UpdateOrganizationCredits(this.organizationId, info)
            if (resp.status === 200 && info.startDate && info.endDate) {
                this.creditsInfo.status = getDate(info.startDate)
                    <= getDate(null) && getDate(info.endDate) >= getDate(null)
                    ? 'ACTIVE' : 'INACTIVE'
            }
            this.updateCreditsFlag = true
        }
    }

    async getEditors() {
        try {
            const response = await API.GetSpecifiedUsers('editor')
            this.editorsInfo = response.data.map(info => ({
                id: info.id,
                name: `${info.firstName} ${info.lastName[0]}.`,
                profilePicture: info.profilePictureUrl,
            }))
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async updateAssetsLink() {
        this.assetsLinkError = ''
        const url = this.assetsLink

        const checkLinkInput = rules.isLink(url)
        if (checkLinkInput === true || url === null || url === '') {
            const changed = url !== this.oldAssetsLink
            if (!changed) {
                return
            }

            this.assetsLinkSaving = true
            try {
                await API.AddOrganizationInfo(this.organizationId, 'assetsLink', url)
                this.oldAssetsLink = url
            } catch (error) {
                console.error('failed to update \'assetsLink\'', error)
                Sentry.captureException(error)
                this.assetsLinkError = String(error)
            } finally {
                setTimeout(() => {
                    this.assetsLinkSaving = false
                }, 600)
            }
        } else {
            this.assetsLinkError = checkLinkInput as string
        }
    }

    async copyAssetsLink() {
        this.assetsLinkCopied = true
        await navigator.clipboard.writeText(this.assetsLink)
        setTimeout(() => this.assetsLinkCopied = false, 1000)
    }


    async getSubscriptionInfo() {
        try {
            const response = await API.GetOrganizationCredits(this.organizationId)
            const credits = response.data.credits
            const creditsStatus = credits && credits.endDate && credits.startDate
                && getDate(credits.endDate) >= getDate(null)
                && getDate(credits.startDate) <= getDate(null)
                ? 'ACTIVE' : 'INACTIVE'
            this.creditsInfo = {
                id: credits && credits.id,
                status: creditsStatus,
                startDate: credits && credits.startDate
                    ? getDate(credits.startDate).split('T')[0] : null,
                endDate: credits && credits.endDate
                    ? getDate(credits.endDate).split('T')[0] : null,
                renewDate: credits && credits.renewDate,
                renewable: credits && credits.renewable,
                interval: credits && credits.interval,
                creditsPerInterval: credits && credits.creditsPerInterval,
                total: credits && credits.activeCredits,
                storage: response.data.storage,
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    async getUsers(action?) {
        let paginationStatus
        if (action) {
            paginationStatus = action
        } else {
            paginationStatus = this.paginationStart
        }
        try {
            const response = await API.GetOrganizationUsers(
                this.organizationId,
                paginationStatus,
                this.paginationLength,
            )
            this.usersInfo = response.data
                .map(user => ({
                    avatar: user.profilePictureURL,
                    name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName[0]}.` : '',
                    email: user.email,
                    phone: user.phone,
                }))
            this.hasRightUsers = this.usersInfo.length >= this.paginationLength
            if (paginationStatus === 0) {
                this.hasLeftUsers = false
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    nextUsers() {
        if (this.usersInfo.length === this.paginationLength) {
            this.getUsers(this.paginationStart = this.paginationStart + this.paginationLength)
            this.hasLeftUsers = true
        }
    }

    prevUsers() {
        if (this.hasLeftUsers) {
            this.getUsers(this.paginationStart = this.paginationStart - this.paginationLength)
            this.hasRightUsers = true
        }
    }

    createEditorList(editorId: number) {
        const modifiedEditorList: Array<EditorInfo> = []
        this.editorsInfo.forEach((info) => {
            if (editorId !== info.id) {
                modifiedEditorList.push({
                    name: info.name,
                    profilePicture: info.profilePicture,
                    id: info.id,
                })
            }
        })
        return modifiedEditorList
    }

    async submitReviewPeriod() {
        let input = this.reviewPeriodInput
        if (input === '') {
            input = '0'
        }

        const isInt = /^\d+$/.test(input)
        const period = _.parseInt(input)
        if (!isInt || !_.isFinite(period) || period < 0) {
            this.reviewPeriodError = this.$t('errors.rules.validated.projectReviewPeriod')
                .toString()
            return
        }

        try {
            await API.AddOrganizationInfo(this.organizationId, 'projectReviewPeriod', period)
            this.editingReviewPeriod = false
            this.projectReviewPeriod = period
        } catch (error) {
            console.error('failed to update organization \'reviewPeriod\'', error)
            Sentry.captureException(error)
            this.reviewPeriodError = String(error)
        }
    }

    cancelEditingReviewPeriod() {
        this.editingReviewPeriod = false
        this.reviewPeriodError = ''
        this.reviewPeriodInput = String(this.projectReviewPeriod)
    }

    mounted() {
        this.statusesList = {
            OPENED: this.$t('pages.project.status.opened'),
            WAITING_FOR_EDITOR: this.$t('pages.project.status.waitingForEditor'),
            IN_EDITING: this.$t('pages.project.status.inEditing'),
            IN_REVIEW: this.$t('pages.project.status.inReview'),
            PAUSED: this.$t('pages.project.status.paused'),
            DELETED: this.$t('pages.project.status.deleted'),
            APPROVED: this.$t('pages.project.status.approved'),
            ARCHIVED: this.$t('pages.project.status.archived'),
        }
        this.organizationId = Number(this.$route.params.organizationId)
        this.getOrganizationInfo()
    }

    destroyed() {
        this.projectTimers.forEach((timer) => {
            if (timer) clearTimeout(timer)
        })
    }
}
