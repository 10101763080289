
import { Component, Vue } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import CustomerInfo from './CustomerInfo.vue'
import API from '@/api'
import TableHeads from '@/utils/tableHeads'
import moment from 'moment'

interface Customer {
    name: string,
    organization: string,
    expiry: string,
    email: string,
    creationDate: string,
    phoneNumber: string,
    subscriptionStatus: string,
    subscriptionType: string,
    billingInformation: string,
    brandAsset: string,
    projectAdded: string,
    vidloftKit: string,
    remainingDays: number,
    organizationId: number,
}

@Component({
    components: {
        CustomerInfo,
    },
})
export default class Customers extends Vue {
    tab: string = ''
    items: Customer[] = []
    customersTableHead = TableHeads.customers
    canGetCustomers: boolean = false
    limit: number = 50
    offset: number = 0
    loading: boolean = false
    error = ''
    savedId: number = 0

    getSubscriptionStatus(renewableAt: string, isCancelled: boolean) {
        if (renewableAt) {
            return isCancelled ? 'canceled' : 'active'
        }
        return 'inactive'
    }

    getMoreCustomers() {
        this.loading = true
        this.offset += this.limit
        this.getCustomers()
            .finally(() => this.loading = false)
    }

    async getCustomers() {
        try {
            const response = await API.GetOrganizations(
                this.offset, this.limit,
            )
            const customers = response.data.map(info => ({
                name: info.owner ? `${info.owner.firstName} ${info.owner.lastName}` : '-',
                organization: info.name,
                organizationId: info.id,
                email: info.owner ? info.owner.email : '-',
                creationDate: info.createdAt.split('T')[0],
                phoneNumber: info.owner && info.owner.phone ? info.owner.phone : '-',
                brandAsset: info.assetsLink ? info.assetsLink : '-',
                projectAdded: info.activeProjects ? info.activeProjects : '-',
                credits: info.activeCredits || '-',
                creditsPerInterval: info.creditsPerInterval || '-',
                creditsEnd: info.endDate ? info.endDate.split('T')[0] : '-',
                creditsRenew: info.renewDate ? info.renewDate.split('T')[0] : '-',
            }))
            this.items = [...this.items, ...customers]
            this.canGetCustomers = customers.length === this.limit
        } catch (error) {
            console.error('failed to retrieve customers', error)
            Sentry.captureException(error)
        }
    }

    calculateRemainingDays(renewableAt) {
        const actualDate = moment.utc()
        const renewAt = moment.utc(renewableAt)

        return Math.floor(
            (Date.UTC(renewAt.year(), renewAt.month(), renewAt.day())
                - Date.UTC(
                    actualDate.year(), actualDate.month(), actualDate.date(),
                )) / (1000 * 60 * 60 * 24),
        )
    }

    sortTable(object: Array<Customer>, element: string, index: number) {
        this.$nextTick(() => {
            if (this.customersTableHead[index].sortingDirection === 'up') {
                this.items = object.sort((first: any, second: any) => (
                    first[element].toString().toLowerCase()
                        > second[element].toString().toLowerCase() ? 1 : -1))
                this.customersTableHead[index].sortingDirection = 'down'
            } else {
                this.items = object.sort((first: any, second: any) => (
                    first[element].toString().toLowerCase()
                        < second[element].toString().toLowerCase() ? 1 : -1))
                this.customersTableHead[index].sortingDirection = 'up'
            }
        })
    }

    async deleteOrganization() {
        try {
            const res = await API.DeleteOrganization(this.savedId)
            if (res.status === 200) {
                const idx = this.items
                    .findIndex(({ organizationId }) => organizationId === this.savedId)
                if (idx > -1) {
                    const tmp = [...this.items]
                    tmp.splice(idx, 1)
                    this.items = tmp
                }
            }
            this.hideModal()
        } catch (error) {
            console.error('failed to delete organization', error)
            this.error = this.$t('general.errors.server').toString()
            Sentry.captureException(error)
        }
    }

    showDeleteModal(id: number) {
        this.$root.$emit('bv::show::modal', 'confirm-modal', '#btnShow')
        this.error = ''
        this.savedId = id
    }

    hideModal() {
        this.$root.$emit('bv::hide::modal', 'confirm-modal', '#btnHide')
    }

    mounted() {
        this.getCustomers()
    }
}
